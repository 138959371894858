export const formatCurrency = (value) => {
  const formattedValue = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);

  return formattedValue;
};

export const handleDataTranslate = (term) => {
  switch (term) {
    case "PAID":
      return "PAGO";
    case "GENERATED":
      return "GERADO";
    case "FAILED":
      return "FALHA";
    case "REFUNDED":
      return "ESTORNADO";
    case "EXPIRED":
      return "EXPIRADO";
    case "CASH-IN":
      return "DEPÓSITO";
    case "CASH-OUT":
      return "SAQUE";
    case "CHARGED":
      return "COBRADA";
    default:
      return term;
  }
};

export const formatDate = (value, type, toBrazilianTime = false) => {
  const date = new Date(value);

  // Ajusta para o fuso horário brasileiro, se necessário
  if (toBrazilianTime) {
    // Converte a hora UTC para o fuso horário brasileiro (UTC-3)
    date.setHours(date.getHours() - 3);
  }

  // Extrai partes da data e hora
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = String(date.getUTCFullYear()); // Converte o ano para string
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  // Formata a saída com base no tipo
  switch (type) {
    case "day":
      return `${day}/${month}/${year}`;
    case "hour":
      return `${hours}:${minutes}`;
    case "complete":
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    case "complete-at":
      return `${day}/${month}/${year} às ${hours}:${minutes}`;
    default:
      return '';
  }
};