import CryptoJS from "crypto-js";

const algorithm = "aes-256-cbc"; // AES encryption algorithm
const keyLength = 32; // Key length for AES-256 (32 bytes)
const ivLength = 16; // IV length for AES (16 bytes)

// Function to normalize the key to 32 bytes
const normalizeKey = (keyString) => {
  const keyBuffer = CryptoJS.enc.Utf8.parse(keyString);
  if (keyBuffer.sigBytes > keyLength) {
    return CryptoJS.lib.WordArray.create(
      keyBuffer.words.slice(0, keyLength / 4),
      keyLength
    );
  } else if (keyBuffer.sigBytes < keyLength) {
    const padding = CryptoJS.lib.WordArray.create(
      [],
      keyLength - keyBuffer.sigBytes
    );
    return keyBuffer.concat(padding);
  } else {
    return keyBuffer;
  }
};

// Function to generate a random initialization vector (IV)
const generateIV = () => CryptoJS.lib.WordArray.random(ivLength);

// Function to encrypt data
export const httpEncrypt = (text) => {
  const key = normalizeKey(import.meta.env.VITE_SECRET_KEY_FIRST);
  const iv = generateIV();
  const encrypted = CryptoJS.AES.encrypt(text, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return `${iv.toString(CryptoJS.enc.Hex)}:${encrypted.ciphertext.toString(
    CryptoJS.enc.Hex
  )}`;
};
export const httpEncryptSecond = (text) => {
  const key = normalizeKey(import.meta.env.VITE_SECRET_KEY_SECOND);
  const iv = generateIV();
  const encrypted = CryptoJS.AES.encrypt(text, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return `${iv.toString(CryptoJS.enc.Hex)}:${encrypted.ciphertext.toString(
    CryptoJS.enc.Hex
  )}`;
};

export const httpDecrypt = (
  encryptedString,
  keyString = import.meta.env.VITE_SECRET_KEY_FIRST
) => {
  console.log("Key String", keyString);
  const [ivHex, ciphertextHex] = encryptedString.split(":");

  // Convert IV and ciphertext from Hex to WordArray
  const iv = CryptoJS.enc.Hex.parse(ivHex);
  const ciphertext = CryptoJS.enc.Hex.parse(ciphertextHex);

  // Create a WordArray from the ciphertext
  const encrypted = CryptoJS.lib.CipherParams.create({
    ciphertext: ciphertext,
  });

  // Normalize the key
  const key = normalizeKey(keyString);

  // Decrypt the data
  const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  // Convert the decrypted data to a string
  return decrypted.toString(CryptoJS.enc.Utf8);
};
