import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import "./termsOfUseModal.scss";

const TermsOfUseModal = ({ show, handleClose }) => {
  const { t } = useTranslation();
  const pdfUrl =
    "https://img-izipay-logo.s3.us-east-1.amazonaws.com/2024-06-03_TermosDeUso_Izipay_assinado.pdf";

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box className="modal">
        <div className="icon-container">
          <HighlightOffIcon
            sx={{ color: "#283650", fontSize: 30 }}
            onClick={handleClose}
          />
        </div>
        <div className="title-container">
          <Typography id="modal-title" variant="h6" component="h2">
            {t("Termos de Uso")}
          </Typography>
        </div>
        <div className="img-container">
          <iframe
            src={pdfUrl}
            width="100%"
            height="600px"
            title="PDF Viewer"
            style={{ border: "none" }}
          />
        </div>
      </Box>
    </Modal>
  );
};

export default TermsOfUseModal;
