import { Refresh } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, InputAdornment } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QueryClient } from "react-query";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext.jsx"; // Import the DarkModeContext
import { UserContext } from "../../context/loggedUserContext.jsx";
import {
  getUsers,
  updateClientInfo,
} from "../../services/TransactionsService.jsx";
import "./userTable.scss";

const queryClient = new QueryClient();
const labelFontSize = "1.05rem";
const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: labelFontSize,
          fontWeight: 700,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& > fieldset > legend": {
            fontSize: `calc(0.75 * ${labelFontSize})`,
          },
        },
      },
    },
  },
});

export default function UserTable({ filterData }) {
  const { darkMode } = useContext(DarkModeContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [loading, setLoading] = useState(true);
  const [usersRows, setusersRows] = useState([]);
  const [totalElements, setTotalElements] = React.useState(1);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [nameEdit, setNameEdit] = useState("");
  const [clientId, setClientId] = useState("");
  const [nameEditAlert, setNameEditAlert] = useState(null);

  const fetchStatement = async () => {
    try {
      setLoading(true);
      setusersRows([]);
      const response = await getUsers(page + 1, rowsPerPage, filterData);
      setTotalElements(response?.totals?.users_qty);
      const users = response?.users.map((t) => ({
        ...t,
        user_id: t.user_id,
        is_parent: t?.is_parent,
        user_parent_id: t?.user_parent_id,
        description: t?.description,
        email: t?.email,
        external_token: t?.external_token,
        pix_key: t?.pix_key,
        balance: t?.balance,
        total_transactions: t?.total_transactions,
        virtual_account: t?.virtual_account,
        status: t?.status,
        subcontas_relacionadas: t?.subcontas_relacionadas,
        recent_transaction: t?.recent_transaction,
      }));
      setusersRows(users);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setusersRows([]);
      console.error(error);
      return;
    }
  };

  useEffect(() => {
    fetchStatement();
  }, [filterData, page, rowsPerPage]);

  useEffect(() => {
    setPage(0);
  }, [filterData.orderBy]);

  const clearSuccessMessages = () => {
    setTimeout(() => {
      setNameEditAlert(null);
    }, 5000);
  };

  const formatarData = (dataString) => {
    const data = new Date(dataString);
    const dia = data.getDate().toString().padStart(2, "0");
    const mes = (data.getMonth() + 1).toString().padStart(2, "0");
    const ano = data.getFullYear();

    return `${dia}/${mes}/${ano}`;
  };

  const handleStatusName = (value) => {
    switch (value) {
      case 0:
        return "Pendente";
      case 1:
        return "Aprovada";
      case 2:
        return "Suspensa";
      case 3:
        return "Bloqueada";
      default:
        break;
    }
  };

  const handleStatusDot = (value) => {
    switch (value) {
      case 0:
        return "aprovar";
      case 1:
        return "ativa";
      default:
        return "bloqueada";
    }
  };

  const handleFormatNum = (valor) => {
    const numero = parseFloat(valor);

    if (isNaN(numero)) {
      return valor;
    }

    return numero.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 15));
    setPage(0);
  };
  const handleSearch = () => {
    fetchStatement();
  };
  const handleMouseEnter = (index) => {
    setHoveredRow(index);
  };
  const handleMouseLeave = () => {
    setHoveredRow(null);
  };
  const handleOpenModal = (name, clientId) => {
    setNameEdit(name);
    setClientId(clientId);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleModalConfirm = async () => {
    const updatedClientInfo = {
      account_name: nameEdit,
      user_id: clientId,
    };
    try {
      const response = await updateClientInfo(updatedClientInfo);
      console.log("editClientName success", response);
      setNameEdit("");
      setShowModal(false);
      setNameEditAlert({
        type: "success",
        text: t("Nome atualizado com sucesso"),
      });
      clearSuccessMessages();
      fetchStatement();
    } catch (error) {
      console.error("An error occurred:", error);
      setNameEditAlert({ type: "error", text: "Erro ao atualizar nome" });
    }
  };

  if (loading) {
    return (
      <div className="text-center mt-24">
        <CircularProgress color="inherit" />
      </div>
    ); // Render a loading state while data is being fetched
  }
  return (
    <>
      <div>
        <div className="flex items-center">
          <IconButton onClick={() => handleSearch()}>
            <Refresh />
          </IconButton>
          <TablePagination
            rowsPerPageOptions={[15]}
            component="div"
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className="table"
            labelDisplayedRows={({ from, to, count }) =>
              t("Mostrando") +
              ` ${from} ` +
              t("a") +
              ` ${to} ` +
              t("de um total de") +
              ` ${count} ` +
              t("usuários")
            }
          />
        </div>
        {!loading && (
          <div className="table-container">
            {usersRows.map((row) => (
              <div className="table-row" key={row.user_id}>
                <div className="table-content" style={{ width: "50px" }}>
                  <div
                    className={`user-dot ${handleStatusDot(row.status)}`}
                    style={{ width: "15px", height: "15px" }}
                  ></div>
                </div>
                <div className="table-content" style={{ width: "15%" }}>
                  <div
                    className="content-text"
                    style={{
                      fontWeight: "bold",
                      fontSize: "13px",
                      color: "#183350",
                    }}
                  >
                    {row.description}
                  </div>
                </div>
                <div className="table-content" style={{ width: "32%" }}>
                  <div className="content-title">Virtual Account</div>
                  <div className="content-text">{row.virtual_account}</div>
                </div>
                <div className="table-content" style={{ width: "32%" }}>
                  <div className="content-title">Email 2fa</div>
                  <div className="content-text">{row.email}</div>
                </div>
                {/* <div className="table-content" style={{width:'10%'}}>
                    <div className="content-title">
                      Qtd Transações
                    </div> 
                    <div className="content-text">
                      {row.total_transactions ?? 0}
                    </div>
                  </div> */}
                <div className="table-content" style={{ width: "10%" }}>
                  <div className="content-title">Saldo</div>
                  <div className="content-text">
                    R$ {handleFormatNum(row.balance) ?? "0,00"}
                  </div>
                </div>
                <div className="table-content" style={{ width: "25px" }}>
                  <Link
                    to={`/userprofile/${row.user_id}/${row.external_token}`}
                  >
                    <AddCircleOutlineIcon
                      className={
                        darkMode
                          ? "text-izired text-2xl "
                          : "text-iziblue text-2xl "
                      }
                    />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        )}
        <TablePagination
          rowsPerPageOptions={[15]}
          component="div"
          count={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="table"
          labelDisplayedRows={({ from, to, count }) =>
            t("Mostrando") +
            ` ${from} ` +
            t("a") +
            ` ${to} ` +
            t("de um total de") +
            ` ${count} ` +
            t("usuários")
          }
        />
      </div>
      <div className="flex flex-col self-end">
        <div
          className={
            nameEditAlert
              ? "alertanimate absolute alertbox right-0 p-2 z-50"
              : " absolute alertbox right-0 p-2 z-50"
          }
        >
          {nameEditAlert && (
            <div>
              <Alert severity={nameEditAlert.type}>{nameEditAlert.text}</Alert>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <div className="modalContainer">
          <div className="modalContent ">
            <div className="flex flex-row-reverse justify-between">
              <div className="flex text-center justify-end">
                <div>
                  <IconButton
                    sx={{ padding: 0 }}
                    onClick={handleCloseModal}
                    aria-label="delete"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
              <div>
                <Typography variant="h5" component="h2" className="title">
                  {t("Editar nome")}
                </Typography>
              </div>
            </div>

            <TextField
              inputProps={{
                style: {
                  padding: "16px 12px",
                  borderColor: "#283650",
                },
              }}
              sx={{ borderColor: "green" }}
              label={t("Nome")}
              placeholder="Digite o nome atualizado"
              variant="outlined"
              value={nameEdit}
              onChange={(e) => setNameEdit(e.target.value)}
              className="w-full"
              InputProps={{
                endAdornment: (
                  <InputAdornment sx={{ borderColor: "green" }} position="end">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#283650",
                      }}
                      onClick={handleModalConfirm}
                      className="modalButton"
                    >
                      {t("Confirmar")}
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
