import DownloadIcon from "@mui/icons-material/Download";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton, Modal } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { DarkModeContext } from "../../context/darkModeContext.jsx";
import SubTokenContext from "../../context/subTokenContext";
import {
  getStatement,
  getStatementSub,
} from "../../services/TransactionsService";
import { generateNewReceipt } from "../receipt/NewReceipt.jsx";
import { TransactionModal } from "../modal/TransactionModal.jsx";
import BasicMenu from "../menu/Menu.jsx";
import { RefundModal } from "../modal/RefundModal.jsx";
import TooltipTranslation from "../tooltipTranslation/TooltipTranslation.jsx";
const formatCurrency = (value) => {
  const formattedValue = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);

  return formattedValue;
};

const formatDate = (value) => {
  const dateParts = value.split("T")[0].split("-");
  const timeParts = value.split("T")[1].split(":");

  const day = dateParts[2];
  const month = dateParts[1];
  const year = dateParts[0];

  const hours = timeParts[0];
  const minutes = timeParts[1];

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "last_updated_at",
    numeric: false,
    disablePadding: false,
    label: "Horário",
    class: "w-table-12",
    valueFormatter: ({ value }) => formatDate(value),
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: (
      <div>
        <TooltipTranslation
          title="status_title"
          TransitionComponent={Zoom}
          placement="bottom"
          arrow
        >
          <div className="flex gap-1 items-center justify-center">
            <span>Status</span>
            <InfoOutlinedIcon
              style={{ fontSize: "medium", verticalAlign: "center" }}
            />
          </div>
        </TooltipTranslation>
      </div>
    ),
    class: "w-table-9",
    cellClassName: "cellWithStatus",
  },
  {
    id: "movement_type",
    numeric: false,
    disablePadding: false,
    label: "Descrição",
    class: "w-table-9",
    width: 100,
  },

  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "Valor (R$)",
    class: "w-table-8",
    cellClassName: "cellWithStatus",
  },
  {
    id: "fee",
    numeric: false,
    disablePadding: false,
    label: "Tarifa (R$)",
    class: "w-table-8",
    cellClassName: "cellWithStatus",
  },
  {
    id: "account_owner_name",
    numeric: false,
    disablePadding: false,
    label: "Proprietário da Conta",
    class: "w-table-15",
    cellClassName: "cellWithStatus",
  },
  {
    id: "order_id",
    numeric: false,
    disablePadding: false,
    label: "ID Ordem",
    class: "w-table-20",
  },
  {
    id: "download_receipt",
    numeric: false,
    disablePadding: false,
    label: "Gerar Comprovante",
    class: "w-table-5",
    /* width: 100, */
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const { t } = useTranslation();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={headCell.class + " cursor-default"}
          >
            {headCell.id != "status" ? t(headCell.label) : headCell.label}
          </TableCell>
        ))}
        <TableCell
          align={"center"}
          padding="none"
          className="w-table-2"
          sx={{ padding: "0px" }}
        ></TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function EnhancedTable({ filterData }) {
  const { t } = useTranslation();
  const [statementRows, setStatementRows] = useState([]);
  const [enterPage, setEnterPage] = useState(null);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("last_updated_at");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [totalElements, setTotalElements] = React.useState(1);
  const [loading, setLoading] = useState(true); // Introduce loading state
  const { darkMode } = useContext(DarkModeContext); // Get the darkMode value from the context
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [refundModalOpen, setRefundModalOpen] = useState(false);
  const { token } = useContext(SubTokenContext);
  useEffect(() => {
    function statusMessage(status) {
      if (status == "PAID") {
        return "Transação confirmada com sucesso.";
      } else if (status == "FAILED") {
        return "Falha na confirmação da transação.";
      } else if (status == "GENERATED") {
        return "Transação criada, aguardando confirmação.";
      } else if (status == "REFUNDED") {
        return "Transação estornada ao beneficiário.";
      } else if (status == "EXPIRED") {
        return "Transação não concluída devido à expiração. Tente novamente mais tarde.";
      }
    }
    const fetchStatement = async () => {
      try {
        setLoading(true);
        if (sessionStorage.getItem("subToken")) {
          const response = await getStatementSub({
            subToken: sessionStorage.getItem("subToken"),
            filter: { page: page + 1, limit: rowsPerPage, ...filterData },
          });

          /* response.totals.transactions_qty == 0 ? response.totals.transactions_qty = 60 : ''; */
          setTotalElements(response.totals.transactions_qty);
          const transactions = response?.transactions?.map((t) => ({
            ...t,
            key: t.transaction_id,
            last_updated_at: t?.last_updated_at,
            end_to_end_id: t?.end_to_end_id,
            order_id: t?.order_id,
            status: t?.status,
            movement_type: t?.movement_type,
            amount: t?.amount,
            fee_charged: t?.fee_charged,
            account_owner_name: t?.account_owner_name,
            document_number: t?.document_number,
            statusMessage: statusMessage(t?.status),
          }));
          setStatementRows(transactions);
          setLoading(false);
        } else {
          const response = await getStatement(
            page + 1,
            rowsPerPage,
            filterData
          );
          /* response.totals.transactions_qty == 0 ? response.totals.transactions_qty = 60 : ''; */
          setTotalElements(response.totals.transactions_qty);

          const transactions = response?.transactions?.map((t) => ({
            ...t,
            key: t.transaction_id,
            last_updated_at: t?.last_updated_at,
            end_to_end_id: t?.end_to_end_id,
            order_id: t?.order_id,
            status: t?.status,
            movement_type: t?.movement_type,
            amount: t?.amount,
            fee_charged: t?.fee_charged,
            account_owner_name: t?.account_owner_name,
            document_number: t?.document_number,
            statusMessage: statusMessage(t?.status),
          }));
          setStatementRows(transactions);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setStatementRows([]);
        console.error(error);
        return;
      }
    };
    fetchStatement();
  }, [filterData, page, rowsPerPage, token, enterPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 15));
    setPage(0);
  };

  const downloadPDF = (transaction) => {
    generateNewReceipt(transaction);
  };

  if (loading) {
    return (
      <div className="text-center mt-24">
        <CircularProgress color="inherit" />
      </div>
    ); // Render a loading state while data is being fetched
  }
  const handleOpenDetails = (row) => {
    setSelectedRow(row);
    setDetailsModalOpen(true);
  };
  const handleCloseDetails = () => {
    setDetailsModalOpen(false);
    setSelectedRow(null);
  };

  const handleOpenRefund = (row) => {
    setSelectedRow(row);
    setRefundModalOpen(true);
  };
  const handleCloseRefund = () => {
    setSelectedRow(null);
    setRefundModalOpen(false);
  };
  const getOrderLabel = (orderId) => {
    switch (orderId) {
      case "TEF-received":
        return "Recebimento TEF";
      case "manual TEF through dasboard":
        return "Envio TEF";
      case "manual withdraw through dasboard":
        return "Saque pela dashboard";
      default:
        return orderId;
    }
  };

  const handleDataTranslate = (term) => {
    switch (term) {
      case "PAID":
        return t("PAGO");
      case "GENERATED":
        return t("GERADO");
      case "FAILED":
        return t("FALHA");
      case "REFUNDED":
        return t("ESTORNADO");
      case "EXPIRED":
        return t("EXPIRADO");
      case "CASH-IN":
        return t("DEPÓSITO");
      case "CASH-OUT":
        return t("SAQUE");
      case "CHARGED":
        return t("COBRADA");
      default:
        return term;
    }
  };
  return (
    <div>
      {detailsModalOpen && (
        <div>
          <Modal
            open={detailsModalOpen}
            onClose={handleCloseDetails}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <TransactionModal row={selectedRow} />
          </Modal>
        </div>
      )}
      {refundModalOpen && (
        <div>
          <Modal
            open={refundModalOpen}
            onClose={handleCloseRefund}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <RefundModal row={selectedRow} onClose={handleCloseRefund} />
          </Modal>
        </div>
      )}
      <Paper sx={{ width: "100%", mb: 2 }} className="table">
        <TableContainer className="table">
          <Table
            sx={{ minWidth: 0 }}
            aria-labelledby="tableTitle"
            size="medium"
            className="table"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              className="table"
            />
            <TableBody>
              {statementRows.map((row, index) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  selected={false}
                  sx={{ cursor: "pointer" }}
                  className="table" // Apply dark mode class to each row
                  style={
                    darkMode
                      ? index % 2
                        ? { background: "rgba(148, 148, 148, 0.3)" }
                        : { background: "rgba(148, 148, 148, 0.1)" }
                      : index % 2
                      ? { background: "rgba(148, 148, 148, 0.3)" }
                      : { background: "white" }
                  }
                >
                  <TableCell
                    sx={{ padding: "5px" }}
                    className="tableCell "
                    align="center"
                  >
                    {formatDate(row.last_updated_at)}
                  </TableCell>
                  <TableCell
                    sx={{ padding: "5px" }}
                    className="tableCell"
                    align="center"
                  >
                    <Tooltip
                      title={
                        <Typography fontSize={15}>
                          {t(row.statusMessage)}
                        </Typography>
                      }
                      placement="bottom"
                      arrow
                    >
                      <span className={`status ${row.status}`}>
                        {handleDataTranslate(row.status)}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    sx={{ padding: "5px" }}
                    className="tableCell "
                    align="center"
                  >
                    <span className={`movement_type ${row.movement_type}`}>
                      {handleDataTranslate(row.movement_type)}
                    </span>
                  </TableCell>
                  <TableCell
                    sx={{ padding: "5px" }}
                    className="tableCell "
                    align="center"
                  >
                    {formatCurrency(row.amount)}
                  </TableCell>
                  <TableCell
                    sx={{ padding: "5px" }}
                    className="tableCell "
                    align="center"
                  >
                    {formatCurrency(row.fee_charged)}
                  </TableCell>
                  <TableCell
                    sx={{ padding: "5px" }}
                    className="tableCell "
                    align="center"
                  >
                    {row?.account_owner_name?.length > 30
                      ? `${row?.account_owner_name.slice(0, 30)}...`
                      : row?.account_owner_name}
                  </TableCell>
                  <TableCell
                    sx={{ padding: "5px" }}
                    className="tableCell"
                    align="center"
                  >
                    {getOrderLabel(row?.order_id)}
                  </TableCell>
                  <TableCell
                    sx={{ padding: "5px" }}
                    className="tableCell "
                    align="center"
                  >
                    <div>
                      <IconButton
                        sx={{
                          ":hover": {
                            bgcolor: "rgba(0, 0, 0, 0.15)", // theme.palette.primary.main
                          },
                          padding: "0px",
                        }}
                        size="large"
                        disabled={row?.status === "PAID" ? false : true}
                        onClick={() => downloadPDF(row)}
                        className={
                          row?.status === "PAID"
                            ? "text-green-500"
                            : "cursor-default text-zinc-400"
                        }
                      >
                        <DownloadIcon />
                      </IconButton>
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0px" }}
                    className="tableCell "
                    align="center"
                  >
                    <BasicMenu
                      type={row.movement_type}
                      status={row.status}
                      onOpenDetails={() => handleOpenDetails(row)}
                      onOpenRefund={() => handleOpenRefund(row)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15]}
          component="div"
          count={totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="table"
          labelDisplayedRows={({ from, to, count }) =>
            t("Mostrando") +
            ` ${from} ` +
            t("a") +
            ` ${to} ` +
            t("de um total de") +
            ` ${count} ` +
            t("transações")
          }
        />
      </Paper>
    </div>
  );
}
