import { Autocomplete, Box, Button, Chip, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import i18n from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import BRFlag from "../../../public/flags/br.svg";
import USFlag from "../../../public/flags/us.svg";
import { DarkModeContext } from "../../context/darkModeContext.jsx";
import { FilterDataContext } from "../../context/filterDataContext";
import { getBalance, getUsers } from "../../services/TransactionsService";
import "./navbar.scss";

import AccountIcon from "@mui/icons-material/AccountCircleOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import FilterOffIcon from "@mui/icons-material/FilterAltOffOutlined";
import FilterIcon from "@mui/icons-material/FilterAltOutlined";
import GroupIcon from "@mui/icons-material/Group";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import MoneyIcon from "@mui/icons-material/PaidOutlined";

import { UserContext } from "../../context/loggedUserContext";
import SubTokenContext from "../../context/subTokenContext.jsx";
import FilterMetrics from "../filterMetrics/FilterMetrics.jsx";
import Flag from "../flags/Flags";

import { v4 as uuidv4 } from "uuid";

const Navbar = () => {
  const [balanceText, setBalanceText] = useState("*****");
  const [balanceAggregateText, setBalanceAggregateText] = useState("*****");
  const [haveAggregate, setHaveAggregate] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(true);
  const { darkMode } = useContext(DarkModeContext);
  const { t } = useTranslation();
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [users, setUsers] = useState([]);
  const [resetKeyAutocomplete, setResetKeyAutocomplete] = useState(uuidv4());
  const [accountAutocomplete, setAccountAutocomplete] = useState(false);
  const { updateToken } = useContext(SubTokenContext);
  const { user } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSub, setAnchorElSub] = React.useState(null);
  const [openAutocompleteList, setOpenAutocompleteList] = useState(false);
  const [subToken, setSubToken] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const open = Boolean(anchorEl);
  const openSub = Boolean(anchorElSub);
  const { isFiltered, setIsFiltered } = useContext(FilterDataContext);
  const classNames = `width-animate ${
    accountAutocomplete ? "flex w-72 h-20 items-center" : "w-0"
  } ${subToken ? "" : "overflow-hidden"}`;

  const { data: metrics } = useQuery({
    queryKey: ["balance"],
    queryFn: () => {
      setLoadingBalance(true);
      return getBalance();
    },
    onSuccess: () => setLoadingBalance(false),
    refetchOnWindowFocus: true,
    refetchInterval: 1000 * 60 * 1,
    refetchIntervalInBackground: false,
  });

  const changeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage, (err) => {
      if (err)
        return console.log("Something went wrong loading language:", err);
      console.log("Language changed to:", newLanguage);
    });
  };

  const handleAnchorEL = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    try {
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const isHome = location.pathname === "/";

  const formatToBRLCurrency = (rawValue) => {
    const value = parseFloat(rawValue);
    return value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  const handleClickSubs = (event) => {
    if (!openSub) {
      setAnchorElSub(event.currentTarget);
      setAccountAutocomplete(true);
    } else {
      setAccountAutocomplete(false);
      setAnchorElSub(null);
    }
  };

  const handleShowMaster = () => {
    sessionStorage.removeItem("subToken");
    sessionStorage.removeItem("sub_account_name");
    setSubToken(null);
    setResetKeyAutocomplete(uuidv4());
    updateToken(undefined);
    setAccountAutocomplete(false);
  };
  const handleSubSelect = (user) => {
    setAnchorElSub(null);
    setOpenAutocompleteList(false);
    updateToken(user.external_token);
    sessionStorage.setItem("subToken", user.external_token);
    sessionStorage.setItem("sub_account_name", user.description);
    setSubToken(user.external_token);
    /* navigate(0); */
  };
  useEffect(() => {
    if (metrics) {
      setLoadingBalance(false);
    }
    setHaveAggregate(metrics?.accounts[0]?.have_aggregate);

    if (balanceText === "*****") {
      return;
    }

    if (metrics) {
      setBalanceText(formatToBRLCurrency(metrics?.accounts[0]?.balance));
      setBalanceAggregateText(
        formatToBRLCurrency(metrics?.accounts[0]?.aggregate_balance)
      );
    }
  }, [metrics]);

  const handleClick = () => {
    const newText =
      balanceText === "*****"
        ? formatToBRLCurrency(metrics?.accounts[0]?.balance)
        : "*****";
    setBalanceText(newText);

    const newAggregateText =
      balanceAggregateText === "*****"
        ? formatToBRLCurrency(metrics?.accounts[0]?.aggregate_balance)
        : "*****";
    setBalanceAggregateText(newAggregateText);
  };

  const handleClearChip = () => {
    setIsFiltered(false);
  };

  useEffect(() => {
    const fetchStatement = async () => {
      try {
        console.log("Fetching users");
        const response = await getUsers(1, 1000, {});
        setUsers(response.users);
      } catch (error) {
        return;
      }
    };
    fetchStatement();
  }, []);
  return (
    <div className="w-full flex flex-col">
      <div className="h-16 sm:h-28 w-full border-b-2 border-zinc-300 flex items-center">
        <div className=" justify-between flex items-center px-1 sm:px-10 w-full content-between">
          <div
            style={{ position: "relative" }}
            className={
              user?.access_level === 4
                ? "hidden"
                : "text-left flex flex-col items-start shadow-none sm:shadow-[0px_0px_15px_0px_rgba(0,0,0,0.36)] border-t-0 border-l-0 sm:border-t-2 sm:border-l-2 p-2 rounded-lg w-4/12 sm:1/2"
            }
          >
            <div className={loadingBalance ? "hidden" : ""}>
              <div className="flex w-full gap-2 self-center">
                <span className="font-medium text-base sm:text-xl">
                  {t("Saldo")}
                </span>
                <div className="block sm:hidden p-1" onClick={handleClick}>
                  <div
                    className="block sm:hidden  self-center"
                    onClick={() => setIsActive(!isActive)}
                  >
                    {isActive ? (
                      <MdVisibility
                        color="#183350"
                        className="cursor-pointer"
                        size={20}
                      />
                    ) : (
                      <MdVisibilityOff
                        color="#183350"
                        className="cursor-pointer"
                        size={20}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex w-full">
                <div
                  className="hidden sm:block self-center"
                  onClick={handleClick}
                >
                  <div
                    className="hidden self-center sm:block"
                    onClick={() => setIsActive(!isActive)}
                  >
                    {isActive ? (
                      <MdVisibility
                        color="#183350"
                        className="cursor-pointer"
                        size={20}
                      />
                    ) : (
                      <MdVisibilityOff
                        color="#183350"
                        className="cursor-pointer"
                        size={20}
                      />
                    )}
                  </div>
                </div>

                <div
                  className={
                    loadingBalance
                      ? "hidden font-bold text-base sm:text-xl text-nowrap"
                      : "font-bold text-base sm:text-xl text-nowrap ml-2"
                  }
                >
                  {balanceText}
                </div>
              </div>
            </div>
            {haveAggregate ? (
              <>
                <div
                  className={`floating-bar ${isExpanded ? "expanded" : ""}`}
                  onMouseEnter={() => setIsExpanded(true)}
                  onMouseLeave={() => setIsExpanded(false)}
                >
                  <div className="bar-content">
                    {isExpanded ? (
                      <div className={loadingBalance ? "hidden" : ""}>
                        <div className="flex w-full gap-2 self-center">
                          <span className="font-medium text-base sm:text-xl">
                            {t("Saldo Agregado")}
                          </span>
                          <div
                            className="block sm:hidden p-1"
                            onClick={handleClick}
                          >
                            <div
                              className="block sm:hidden  self-center"
                              onClick={() => setIsActive(!isActive)}
                            >
                              {isActive ? (
                                <MdVisibility
                                  color="#white"
                                  className="cursor-pointer"
                                  size={20}
                                />
                              ) : (
                                <MdVisibilityOff
                                  color="#white"
                                  className="cursor-pointer"
                                  size={20}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flex w-full">
                          <div
                            className="hidden sm:block self-center"
                            onClick={handleClick}
                          >
                            <div
                              className="hidden self-center sm:block"
                              onClick={() => setIsActive(!isActive)}
                            >
                              {isActive ? (
                                <MdVisibility
                                  color="#white"
                                  className="cursor-pointer"
                                  size={20}
                                />
                              ) : (
                                <MdVisibilityOff
                                  color="#white"
                                  className="cursor-pointer"
                                  size={20}
                                />
                              )}
                            </div>
                          </div>

                          <div
                            className={
                              loadingBalance
                                ? "hidden font-bold text-base sm:text-xl text-nowrap"
                                : "font-bold text-base sm:text-xl text-nowrap ml-2"
                            }
                          >
                            {balanceAggregateText}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <p>Expandir saldo agregado</p>
                    )}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <div className={loadingBalance ? "text-center w-full" : "hidden"}>
              <CircularProgress color="inherit" />
            </div>
          </div>

          <div
            className={
              user?.access_level === 4
                ? "flex items-center justify-between w-full sm:1/2"
                : "flex items-center justify-between w-8/12 sm:1/2"
            }
          >
            <div className="hidden sm:flex ml-8 w-2/12 flex-col xl:flex-row">
              <div className="mr-2">
                <Button
                  endIcon={Flag(USFlag)}
                  onClick={() => changeLanguage("en")}
                  className="language-button"
                />
              </div>
              <div className="mr-2">
                <Button
                  endIcon={Flag(BRFlag)}
                  onClick={() => changeLanguage("pt-BR")}
                  className="language-button"
                />
              </div>
            </div>

            <div className="flex w-10/12 justify-end items-center h-20">
              {user?.is_admin || user?.is_parent ? (
                <>
                  <div className={classNames}>
                    <Autocomplete
                      disablePortal
                      blurOnSelect
                      disableClearable
                      clearOnEscape
                      autoComplete="off"
                      key={resetKeyAutocomplete}
                      getOptionLabel={(option) => option.description || option}
                      open={openAutocompleteList}
                      onOpen={() => setOpenAutocompleteList(true)}
                      onChange={(event, value) => {
                        handleSubSelect(value);
                      }}
                      onClose={() => setOpenAutocompleteList(false)}
                      options={users}
                      size="small"
                      className="w-full"
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          {...props}
                          key={option.external_token}
                        >
                          {option.description}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} label={t("Contas")} />
                      )}
                    />
                  </div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={openSub ? "long-menu" : undefined}
                    aria-expanded={openSub ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClickSubs}
                  >
                    <GroupIcon />
                  </IconButton>
                </>
              ) : (
                ""
              )}
              <div className=" max-w-full whitespace-nowrap overflow-hidden text-ellipsis text-base">
                {t("Olá")},{" "}
                <span className="font-bold">
                  {/* {user?.account_name} */}
                  {sessionStorage.getItem("sub_account_name")
                    ? sessionStorage.getItem("sub_account_name")
                    : user?.account_name}
                </span>
              </div>
              {sessionStorage.getItem("sub_account_name") && (
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleShowMaster}
                  >
                    <KeyboardReturnIcon />
                  </IconButton>
                </div>
              )}
              {isFiltered && (
                <div>
                  <Chip
                    size="small"
                    className="border-zinc-400"
                    label={t("Filtro Ativo")}
                    variant="outlined"
                    sx={{
                      marginLeft: "9px",
                      border: "2px solid rgb(161 161 170)",
                      "& .MuiChip-label": {
                        fontWeight: "500",
                      },
                    }}
                  />
                </div>
              )}
              {isHome && (
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    onClick={handleAnchorEL}
                  >
                    {isFiltered ? (
                      <FilterOffIcon
                        className={
                          darkMode
                            ? "text-izired text-2xl "
                            : "text-iziblue text-2xl "
                        }
                      />
                    ) : (
                      <FilterIcon
                        className={
                          darkMode
                            ? "text-izired text-2xl "
                            : "text-iziblue text-2xl "
                        }
                      />
                    )}
                  </IconButton>
                  <FilterMetrics
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    isFiltered={isFiltered}
                    onClearFilter={handleClearChip}
                  />
                </div>
              )}
              <div>
                <IconButton component={Link} to="/payments">
                  <MoneyIcon
                    className={
                      darkMode
                        ? "text-izired text-2xl"
                        : "text-iziblue text-2xl "
                    }
                  />
                </IconButton>
              </div>
              <div>
                <IconButton component={Link} to="/profile">
                  <AccountIcon
                    className={
                      darkMode
                        ? "text-izired text-2xl "
                        : "text-iziblue text-2xl "
                    }
                  />
                </IconButton>
              </div>
              <div>
                <IconButton onClick={handleSignOut}>
                  <ExitToAppIcon
                    className={
                      darkMode
                        ? "text-izired text-2xl "
                        : "text-iziblue text-2xl "
                    }
                  />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
