import CloseIcon from "@mui/icons-material/Close";
import {
  Alert, Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Modal,
  ThemeProvider
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import logo from "../../../public/zcolorido.png";
import { UserContext } from "../../context/loggedUserContext";
import { updateUserInfo } from "../../services/TransactionsService";
import TermsOfUseModal from "../termsOfUseModal/TermsOfUseModal";
import "./firstLoginModal.scss";
const labelFontSize = "2rem";
const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
    h2: {
      fontSize: labelFontSize,
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: labelFontSize,
          fontWeight: 700,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& > fieldset > legend": {
            fontSize: labelFontSize,
          },
        },
      },
    },
  },
});

const FirstLoginModal = ({
  openLoginModal,
  clearPasswordInput,
  setModalState,
}) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [checkboxTerm, setCheckboxTerm] = useState(false);
  const [showTermsOfUseModal, setShowTermsOfUseModal] = useState(false);
  const [isFocusedNewPassword, setIsFocusedNewPassword] = useState(false);
  const [isFocusedConfirmPassword, setIsFocusedConfirmPassword] =
    useState(false);
  const [newPasswordError, setNewPasswordError] = useState([]);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const { user, setUser } = useContext(UserContext);
  const [alertOpen, setAlertOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleFocusNewPassword = () => {
    setTimeout(() => {
      setIsFocusedNewPassword(true);
    }, 301);
  };
  const handleFocusConfirmPassword = () => {
    setTimeout(() => {
      setIsFocusedConfirmPassword(true);
    }, 301);
  };
  const handleBlurNewPassword = (event) => {
    event.preventDefault();

    if (newPassword.trim().length !== 0) {
      // console.log('input value is NOT empty');
    } else {
      setIsFocusedNewPassword(false);
    }
  };
  const handleBlurConfirmPassword = (event) => {
    event.preventDefault();

    if (confirmPassword.trim().length !== 0) {
      // console.log('input value is NOT empty');
    } else {
      setIsFocusedConfirmPassword(false);
    }
  };
  const validateNewPassword = (newPassword) => {
    const errors = [];

    if (newPassword.length < 8) {
      errors.push({
        message: t("A senha deve ter pelo menos 8 caracteres;"),
        valid: false,
      });
    } else {
      errors.push({
        message: t("A senha deve ter pelo menos 8 caracteres;"),
        valid: true,
      });
    }
    if (!/[A-Z]/.test(newPassword)) {
      errors.push({
        message: t("A senha deve conter pelo menos uma letra maiúscula;"),
        valid: false,
      });
    } else {
      errors.push({
        message: t("A senha deve conter pelo menos uma letra maiúscula;"),
        valid: true,
      });
    }
    if (!/[a-z]/.test(newPassword)) {
      errors.push({
        message: t("A senha deve conter pelo menos uma letra minúscula;"),
        valid: false,
      });
    } else {
      errors.push({
        message: t("A senha deve conter pelo menos uma letra minúscula;"),
        valid: true,
      });
    }
    if (!/\d/.test(newPassword)) {
      errors.push({
        message: t("A senha deve conter pelo menos um número;"),
        valid: false,
      });
    } else {
      errors.push({
        message: t("A senha deve conter pelo menos um número;"),
        valid: true,
      });
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
      errors.push({
        message: t("A senha deve conter pelo menos um caractere especial."),
        valid: false,
      });
    } else {
      errors.push({
        message: t("A senha deve conter pelo menos um caractere especial."),
        valid: true,
      });
    }

    setNewPasswordError(errors);
  };

  const handleNewPasswordChange = (e) => {
    const value = e.target.value;
    setNewPassword(value);
    validateNewPassword(value);
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (value !== newPassword) {
      setConfirmPasswordError(t("As senhas precisam ser iguais."));
    } else {
      setConfirmPasswordError("");
    }
  };
  const handleCloseModal = () => {
    setModalState(false);
  };
  const handleCleanLoginPassword = () => {
    clearPasswordInput();
    //limpar o session storage
  };
  const handleOpenTermsModal = () => {
    setShowTermsOfUseModal(true);
  };

  const handleCloseTermsModal = () => {
    setShowTermsOfUseModal(false);
  };

  if (!openLoginModal) return null;

  const handleKeyDown = (e) => {
    if (e.ctrlKey && e.key === "v") {
      e.preventDefault();
    }
    if (e.metaKey && e.key === "v") {
      // Para Mac (Cmd + V)
      e.preventDefault();
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const currentTimestamp = new Date().toISOString();
    const updatePassword = {
      dashboard_password: newPassword,
      first_login: 0,
      accepted_term: currentTimestamp,
    };
    console.log("ENTROU NO HANDLE SAVE");
    try {
      await updateUserInfo(updatePassword);
      console.log(user);
      setUser({
        ...user,
        accepted_term: currentTimestamp,
      });
      setAlertOpen(true);
      setTimeout(() => {
        switch (user.access_level) {
          case 2:
            navigate("/transactions", { replace: true });
            break;
          case 3:
          case 4:
            navigate("/payments", { replace: true });
            break;
          default:
            navigate("/", { replace: true });
            break;
        }
      }, 2000);

    } catch (error) {
      console.error("Error updating password:", error);
    }
  };

  return (
    <Modal open={openLoginModal} onClose={handleCloseModal}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bgcolor="white"
        p={4}
        boxShadow={3}
        borderRadius={4}
        width={"70%"}
        height={"90%"}
        position="absolute"
        top="50%"
        left="50%"
        sx={{
          transform: "translate(-50%, -50%)",
        }}
      >
        <ThemeProvider theme={theme}>
          <div className="div-container">
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <div className="absolute top-8 left-8">
              <img src={logo} alt="izipay Logo" className="w-2/5" />
            </div>
            <form className="form" onSubmit={handleSave}>
              <div className="header">
                <h2>{t("Seja Bem Vindo!")}</h2>
                <h2>
                  {t("Esse é seu primeiro acesso. Por favor, troque sua senha")}
                </h2>
              </div>
              <div className="div-container-2">
                <div className="password">
                  <input
                    className={
                      isFocusedNewPassword
                        ? "animatekeep cursor-text animatetest pt-6 pb-2 pr-2 pl-3 w-full border border-gray-main rounded-md outline-none"
                        : "cursor-text animatetest pt-6 pb-2 pr-2 pl-3 w-full border border-gray-main rounded-md outline-none"
                    }
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    onFocus={handleFocusNewPassword}
                    onBlur={handleBlurNewPassword}
                  />
                  {newPasswordError.map((error, index) => (
                    <div
                      key={index}
                      className={error.valid ? "valid-login" : "error-login"}
                    >
                      {error.message}
                    </div>
                  ))}
                  <div className="password-label">
                    <span>{t("Nova Senha")}</span>
                  </div>
                </div>
                <div className="password">
                  <input
                    className={
                      isFocusedConfirmPassword
                        ? "animatekeep cursor-text animatetest pt-6 pb-2 pr-2 pl-3 w-full border border-gray-main rounded-md outline-none"
                        : "cursor-text animatetest pt-6 pb-2 pr-2 pl-3 w-full border border-gray-main rounded-md outline-none"
                    }
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    onFocus={handleFocusConfirmPassword}
                    onBlur={handleBlurConfirmPassword}
                    onKeyDown={handleKeyDown}
                  />
                  {confirmPasswordError && (
                    <div className="error-login">{confirmPasswordError}</div>
                  )}
                  <div className="password-label">
                    <span>{t("Redigite a Nova Senha")}</span>
                  </div>
                </div>
                <div className="mt-6">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkboxTerm}
                        onChange={(e) => setCheckboxTerm(e.target.checked)}
                      />
                    }
                    label={
                      <span>
                        {t("Aceitar os")}{" "}
                        <a
                          href="#"
                          onClick={handleOpenTermsModal}
                          className="link-termos-de-adesao"
                        >
                          {t("Termos de Uso")}
                        </a>
                      </span>
                    }
                  />
                </div>
                <div className="text-center mt-6">
                  <button
                    className="btn-primary-large"
                    type="submit"
                    disabled={!checkboxTerm || newPassword !== confirmPassword}
                  >
                    {t("Confirmar")}
                  </button>
                </div>
              </div>
            </form>
            <TermsOfUseModal
              show={showTermsOfUseModal}
              handleClose={handleCloseTermsModal}
            />
          </div>
        </ThemeProvider>
        {alertOpen && (
          <Alert
            onClose={handleAlertClose}
            severity="success"
            sx={{
              position: "fixed",
              top: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 1300,
              width: "90%",
              maxWidth: "600px",
            }}
          >
            {t("Senha trocada com sucesso!")}
          </Alert>
        )}

      </Box>
    </Modal>
  );
};

export default FirstLoginModal;
