import SearchIcon from "@mui/icons-material/Search.js";
import IconButton from "@mui/material/IconButton";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { useMediaQuery } from "react-responsive";
import UserTable from "../../components/metricCharts/userTable.jsx";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { DarkModeContext } from "../../context/darkModeContext.jsx"; // Import the DarkModeContext
const queryClient = new QueryClient();
const labelFontSize = "1.05rem";
const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: labelFontSize,
          fontWeight: 700,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& > fieldset > legend": {
            fontSize: `calc(0.75 * ${labelFontSize})`,
          },
        },
      },
    },
  },
});
const SearchUser = ({ onFilterData }) => {
  const removeValueFromArray = (array, value) => {
    return array.filter((item) => item !== value);
  };
  const { t } = useTranslation();
  const [checkedVal, setCheckedVal] = useState([]);
  const [filterText, setFilterText] = React.useState("");
  const [searchVal, setSearchVal] = useState("");
  const [selectStatus, setStatusVal] = useState(null);
  const [selectOrderBy, setSelectOrderBy] = useState(null);
  const [selectSegment, setSegment] = useState(null);
  const [activeSearch, setActiveSearch] = useState(false);
  const [filterType, setfilterType] = useState("");
  const [inputDisabled, setInputdisabled] = useState(true);
  const [chips, setChips] = useState([]);
  const [downloading, setDownloading] = useState(true);
  const handleCheckboxChange = (event) => {
    event.target.checked
      ? setCheckedVal([...checkedVal, event.target.value])
      : setCheckedVal(removeValueFromArray(checkedVal, event.target.value));
  };
  const handleStatus = (value) => {
    if (value == "*") {
      setStatusVal("");
    } else {
      setStatusVal(value);
    }
  };

  const handleStatusSelected = () => {
    const items = document.querySelectorAll(".filter-status");

    items.forEach((item) => {
      item.addEventListener("click", () => {
        items.forEach((i) => i.classList.remove("filter-selected"));
        item.classList.add("filter-selected");
      });
    });
  };

  const handleOrderByChange = (event) => {
    setSelectOrderBy(event.target.value);
  };
  
  const handleSegmentChange = (event) => {
    setSegment(event.target.value);
  };
  const handleSearch = () => {
    setActiveSearch(!activeSearch);
  };
  const handlefilterTypeChange = (event) => {
    event.target.value == ""
      ? (setInputdisabled(true), clearSearch())
      : setInputdisabled(false);
    setfilterType(event.target.value);
    setFilterText("");
    /* setStatus(undefined);
    setMovementType(undefined);
    setDate(new Date()); */
  };
  const handleKeyPress = (event) => {
    // Check if the pressed key is Enter (key code 13)
    if (event.key === "Enter") {
      const chipval = filterType.replaceAll("_", " ") + ": " + filterText;
      setChips([...chips, chipval]);
      setDownloading(false);
      // Call your function here
      handleSearch();
    }
  };

  const clearSearch = () => {
    setfilterType("");
    setFilterText("");
    setStatusVal("*");
    setSelectOrderBy("recent_transaction");

    handleSearch();
  };

  useEffect(() => {
    if (searchVal == "") setActiveSearch(!activeSearch);
  }, [searchVal]);

  useEffect(() => {
    const filters = {};
    if ([0, 1, 2, 3].includes(selectStatus)) filters["status"] = selectStatus;
    if (searchVal != "") filters["virtual_account"] = searchVal;
    if (filterType != "") filters["filter_type"] = filterType;
    if (filterText != "") filters["filter_text"] = filterText;
    if (selectSegment != "" && selectSegment != "*") filters["filter_segment"] = selectSegment;
    if (selectOrderBy != "" && selectOrderBy != null) {
      filters["orderBy"] = selectOrderBy;
    } else {
      filters["orderBy"] = "recent_transaction";
    }
    onFilterData(filters);
  }, [checkedVal, activeSearch, selectStatus, selectOrderBy, searchVal, selectSegment]);
  return (
    <div className="flex gap-6 mx-6 mt-6 flex-col">
      <div className="flex mx-6 flex-col">
        <div className="flex items-center gap-6 mx-1 users-filter">
          {handleStatusSelected()}
          <div className="flex items-center gap-1 filter-status filter-selected">
            <Button
              style={{ color: "#183350" }}
              onClick={() => handleStatus("*")}
            >
              Todas Contas
            </Button>
          </div>
          <div className="flex items-center gap-1 filter-status">
            <Button
              style={{ color: "#183350" }}
              onClick={() => handleStatus(1)}
            >
              Contas Ativas
            </Button>
          </div>
          <div className="flex items-center gap-1 filter-status">
            <Button
              style={{ color: "#183350" }}
              onClick={() => handleStatus(0)}
            >
              Aprovar Contas
            </Button>
          </div>
          <div className="flex items-center gap-1 filter-status">
            <Button
              style={{ color: "#183350" }}
              onClick={() => handleStatus(2)}
            >
              Contas Inativas
            </Button>
          </div>
        </div>
        <div className="flex items-center gap-6 mx-1 mt-2">
          <div
            className="flex items-center"
            style={{ fontSize: "12px", fontWeight: "bold", color: "#817c7c" }}
          >
            <div className="user-dot ativa"></div>Ativa
          </div>
          <div
            className="flex items-center"
            style={{ fontSize: "12px", fontWeight: "bold", color: "#817c7c" }}
          >
            <div className="user-dot bloqueada"></div>Bloqueada
          </div>
          <div
            className="flex items-center"
            style={{ fontSize: "12px", fontWeight: "bold", color: "#817c7c" }}
          >
            <div className="user-dot aprovar"></div>Aprovar
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center">
        
        <div className="flex gap-6 mx-6 mb-6 justify-end w-full">
          <div className="w-5/12">
            <div className="w-full">
              <TextField
                sx={{
                  width: "100%",
                }}
                className="textfield"
                id="search_value_input"
                label={<SearchIcon />}
                variant="outlined"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                onKeyDown={handleKeyPress}
                disabled={inputDisabled}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ marginRight: "-14px" }}
                    >
                      <Select
                        size="small"
                        sx={{
                          "&:hover": {
                            "&& fieldset": {
                              border: "1px solid #283650",
                            },
                          },
                          "&:active": {
                            "&& fieldset": {
                              border: "1px solid transparent",
                            },
                          },
                        }}
                        value={filterType}
                        onChange={handlefilterTypeChange}
                        displayEmpty
                        style={{ padding: 0, minWidth: "auto" }} // Reduce padding and minWidth
                      >
                        <MenuItem value="">{t("Sem Filtro")}</MenuItem>
                        <MenuItem
                          className="border-2 border-transparent hover:border-iziblue"
                          value={"description"}
                        >
                          Nome da Conta
                        </MenuItem>
                        <MenuItem
                          className="border-2 border-transparent hover:border-iziblue"
                          value={"external_token"}
                        >
                          API Token
                        </MenuItem>
                        <MenuItem
                          className="border-2 border-transparent hover:border-iziblue"
                          value={"virtual_account"}
                        >
                          ID Virtual
                        </MenuItem>

                        <MenuItem
                          className="border-2 border-transparent hover:border-iziblue"
                          value={"user_cpf"}
                        >
                          CPF
                        </MenuItem>
                        <MenuItem
                          className="border-2 border-transparent hover:border-iziblue"
                          value={"user_cnpj"}
                        >
                          CNPJ
                        </MenuItem>
                        <MenuItem
                          className="border-2 border-transparent hover:border-iziblue"
                          value={"user_id"}
                        >
                          N da Conta
                        </MenuItem>
                      </Select>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="w-3/12">
            <FormControl fullWidth>
              <InputLabel id="order-label">{t("Segmentação")}</InputLabel>
              <Select
                size="small"
                labelId="order-label"
                id="orderby"
                onChange={handleSegmentChange}
                label={t("Segmentação")}
                selected={selectSegment}
                defaultValue={"*"}
              >
                <MenuItem value={"*"}>{t("Todas Contas")}</MenuItem>
                <MenuItem value={"0"}>{t("Master")}</MenuItem>
                <MenuItem value={"1"}>{t("Sub Contas")}</MenuItem>
                <MenuItem value={"2"}>{t("Sub-Sub Contas")}</MenuItem>
              </Select>
            </FormControl>
            </div>
          <div className=" w-3/12 ">
            <FormControl fullWidth>
              <InputLabel id="order-label">{t("Ordenar por")}</InputLabel>
              <Select
                size="small"
                labelId="order-label"
                id="orderby"
                onChange={handleOrderByChange}
                label={t("Ordenar por")}
                selected={selectOrderBy}
                defaultValue={"recent_transaction"}
              >
                <MenuItem value={"user_balance"}>{t("Conta Saldo")}</MenuItem>
                <MenuItem value={"recent_transaction"}>
                  {t("Conta Transações Recentes")}
                </MenuItem>
                {/* <MenuItem value={"count_transactions"}>{t("Conta Numero Transações")}</MenuItem> */}
                <MenuItem value={"user_created"}>
                  {t("Criação de Conta Recente")}
                </MenuItem>
                <MenuItem value={"inverse_user_created"}>
                  {t("Criação da Conta (crescente)")}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <Button style={{ color: "#183350" }} onClick={() => clearSearch()}>
              Limpar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
const UserList = () => {
  const { darkMode } = useContext(DarkModeContext);
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  if (isMobile) {
    import("./adminMobile.scss");
  } else {
    import("./userList.scss");
  }
  const [filterData, setFilterData] = useState({});
  const handleFilterData = (data) => {
    setFilterData(data);
  };
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <div className="profile">
          <Sidebar />
          <div className="w-10/12 sm:w-full">
            <Navbar />

            <div className="h-[calc(100%-112px)] flex flex-col">
              <SearchUser onFilterData={handleFilterData} />

              <UserTable filterData={filterData} />
            </div>
          </div>
        </div>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default UserList;
