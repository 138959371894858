/* import "./list.scss"; */
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useContext, useEffect, useState } from "react";
import Datatable from "../../components/datatable/Datatable.jsx";
import Navbar from "../../components/navbar/Navbar";
import "../../components/navbar/navbar.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import { DarkModeContext } from "../../context/darkModeContext.jsx"; // Import the DarkModeContext
// import { downloadCSV } from "../../services/TransactionsService";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { downloadCSV } from "../../services/TransactionsService";
// import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";
import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import "dayjs/locale/pt-br";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { QueryClient, QueryClientProvider } from "react-query";
import { useMediaQuery } from "react-responsive";
import("./list.scss");

const queryClient = new QueryClient();
const SearchBar = ({ onFilterData }) => {
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
  }));
  const { t } = useTranslation();
  const [filterText, setFilterText] = React.useState("");
  const [status, setStatus] = React.useState(t("Sem Filtro"));
  const [movementType, setMovementType] = React.useState(t("Sem Filtro"));
  const [date, setDate] = React.useState(null);
  const [downloading, setDownloading] = useState(true);
  const [searchType, setSearchType] = useState("");
  const [inputDisabled, setInputdisabled] = useState(true);
  const [chips, setChips] = useState([]);
  const handleKeyPress = (event) => {
    // Check if the pressed key is Enter (key code 13)
    if (event.key === "Enter") {
      const chipval = searchType.replaceAll("_", " ") + ": " + filterText;
      setChips([...chips, chipval]);
      setDownloading(false);
      // Call your function here
      handleSearch();
    }
  };
  const handleSearchTypeChange = (event) => {
    event.target.value == ""
      ? (setInputdisabled(true), clearSearch())
      : setInputdisabled(false);
    setSearchType(event.target.value);
    setFilterText("");
    /* setStatus(undefined);
      setMovementType(undefined);
      setDate(new Date()); */
    setDownloading(true);
  };
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    const updatedChips = [...chips];
    updatedChips.map((chip, index) => {
      if (
        chip === "PAID" ||
        chip === "GENERATED" ||
        chip === "FAILED" ||
        chip === "REFUNDED"
      ) {
        chips.splice(index, 1);
        setChips(updatedChips);
      }
    });
    setChips([...chips, event.target.value.trim()]);
    /* setAppend(""); */
  };

  const handleDeleteChip = (index, chip) => {
    const updatedChips = [...chips];
    updatedChips.splice(index, 1);
    setChips(updatedChips);
    chip === "PAID" ||
    chip === "GENERATED" ||
    chip === "FAILED" ||
    chip === "REFUNDED"
      ? setStatus(t("Sem Filtro"))
      : "";
    chip === "CASH-IN" || chip === "CASH-OUT" || chip === "TEF"
      ? setMovementType(t("Sem Filtro"))
      : "";
    const conditions = ["end to end id", "order id", "transaction id", "account_owner_name", "document_number"];
    conditions.some((el) => chip.includes(el))
      ? (setFilterText(""), setSearchType(""))
      : "";
    dayjs(chip, "DD/MM/YYYY", true).isValid() ? setDate(null) : "";
  };
  useEffect(() => {
    filterText == "" ? clearSearch() : "";
  }, [filterText]);
  useEffect(() => {
    status != t("Sem Filtro") || movementType != t("Sem Filtro")
      ? (handleSearch(), setDownloading(false))
      : clearSearch();
  }, [status, movementType]);
  /* useEffect(() => {
      movementType != t('Sem Filtro') ? (handleSearch(), setDownloading(false)) : clearSearch();
    }, [movementType]); */

  const handleMovementTypeChange = (event) => {
    setMovementType(event.target.value);
    const updatedChips = [...chips];
    updatedChips.map((chip, index) => {
      if (chip === "CASH-IN" || chip === "CASH-OUT" || chip === "TEF") {
        chips.splice(index, 1);
        setChips(updatedChips);
      }
    });
    setChips([...chips, event.target.value.trim()]);
    /* setAppend(""); */
  };

  useEffect(() => {
    movementType != t("Sem Filtro")
      ? (handleSearch(), setDownloading(false))
      : clearSearch();
  }, [movementType]);
  useEffect(() => {
    const stringDate = dayjs(date).format("DD/MM/YYYY");
    const updatedChips = [...chips];
    updatedChips.map((chip, index) => {
      if (dayjs(chip, "DD/MM/YYYY", true).isValid()) {
        chips.splice(index, 1);
        setChips(updatedChips);
      }
    });
    date != null
      ? (handleSearch(),
        setDownloading(false),
        setChips([...chips, stringDate]))
      : /* setAppend("") */ clearSearch();
  }, [date]);

  const clearSearch = () => {
    setSearchType("");
    setFilterText(filterText);
    setStatus(status);
    setMovementType(movementType);
    setDate(date);
    if (
      status == t("Sem Filtro") &&
      movementType == t("Sem Filtro") &&
      date == null &&
      filterText == ""
    ) {
      setDownloading(true);
    }
    onFilterData();
    handleSearch();
  };

  const handleDownloadCSV = async () => {
    try {
      setDownloading(true);
      const csvDate = `${dayjs(date).format("YYYY-MM-DD")}`;
      const csv = await downloadCSV(csvDate);
      const blob = new Blob([csv], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `transactions-${csvDate}.csv`; // Name the file as you'd like
      link.click();
      setDownloading(false);
    } catch (error) {
      console.error(error);
      setDownloading(false);
    }
  };
  const handleSearch = async () => {
    try {
      const filters = {};
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (searchType && filterText) filters[searchType] = filterText;
      if (filterText) return onFilterData(filters);
      if (movementType != t("Sem Filtro"))
        filters["movement_type"] = movementType;
      if (date) filters["date"] = `${dayjs(date).format("YYYY-MM-DD")}`;
      if (status != t("Sem Filtro")) filters["status"] = status;
      onFilterData(filters);
    } catch (error) {
      console.error(error);
    }
  };

  const fromDate = dayjs().set("year", 2020).set("month", 1).set("day", 1);

  return (
    <div className="w-full flex flex-col">
      <Grid container spacing={1} className="px-2">
        <Grid item xs={12} sm={4}>
          <div className="w-full">
            <TextField
              sx={{
                width: "100%",
              }}
              className="textfield"
              id="search_value_input"
              label={<SearchIcon />}
              variant="outlined"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              onKeyDown={handleKeyPress}
              disabled={inputDisabled}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ marginRight: "-14px" }}
                  >
                    <Select
                      size="small"
                      sx={{
                        "&:hover": {
                          "&& fieldset": {
                            border: "1px solid #283650",
                          },
                        },
                        "&:active": {
                          "&& fieldset": {
                            border: "1px solid transparent",
                          },
                        },
                      }}
                      value={searchType}
                      onChange={handleSearchTypeChange}
                      displayEmpty
                      style={{ padding: 0, minWidth: "auto" }} // Reduce padding and minWidth
                    >
                      <MenuItem value="">{t("Sem Filtro")}</MenuItem>
                      <MenuItem
                        className="border-2 border-transparent hover:border-iziblue"
                        value={"order_id"}
                      >
                        {t("ID Ordem")}
                      </MenuItem>
                      <MenuItem
                        className="border-2 border-transparent hover:border-iziblue"
                        value={"end_to_end_id"}
                      >
                        {t("ID End to End")}
                      </MenuItem>
                      <MenuItem
                        className="border-2 border-transparent hover:border-iziblue"
                        value={"transaction_id"}
                      >
                        {t("ID Transação")}
                      </MenuItem>
                      <MenuItem
                        className="border-2 border-transparent hover:border-iziblue"
                        value={"account_owner_name"}
                      >
                        {t("Proprietário da Conta")}
                      </MenuItem>
                      {/* comentado para ajustar filtro de números no documento */}
                      {/* <MenuItem
                        className="border-2 border-transparent hover:border-iziblue"
                        value={"document_number"}
                      >
                        {t("Número do Documento")}
                      </MenuItem> */}
                    </Select>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={3} className="flex gap-1">
          <div className="dropdown w-1/2">
            <Box sx={{}}>
              <FormControl fullWidth>
                <InputLabel id="status_label" style={{ fontWeight: "600" }}>
                  Status
                </InputLabel>
                <Select
                  size="small"
                  labelId="status"
                  id="status"
                  value={status ?? t("Sem Filtro")}
                  label="Status"
                  disabled={!!filterText}
                  onChange={handleStatusChange}
                >
                  <MenuItem value={t("Sem Filtro")} selected>
                    {t("Sem Filtro")}
                  </MenuItem>
                  <MenuItem value={"PAID"}>{t("PAGO")}</MenuItem>
                  <MenuItem value={"GENERATED"}>{t("GERADO")}</MenuItem>
                  <MenuItem value={"FAILED"}>{t("FALHA")}</MenuItem>
                  <MenuItem value={"REFUNDED"}>{t("ESTORNADO")}</MenuItem>
                  <MenuItem value={"EXPIRED"}>{t("EXPIRADO")}</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="dropdown w-1/2">
            <Box sx={{}}>
              <FormControl fullWidth>
                <InputLabel
                  id="movement_type_label"
                  style={{ fontWeight: "600" }}
                >
                  {t("Descrição")}
                </InputLabel>
                <Select
                  size="small"
                  labelId="movement_type"
                  id="movement_type"
                  value={movementType ?? t("Sem Filtro")}
                  label="movement_type"
                  disabled={!!filterText}
                  onChange={handleMovementTypeChange}
                >
                  <MenuItem value={t("Sem Filtro")} selected>
                    {t("Sem Filtro")}
                  </MenuItem>
                  <MenuItem value={"CASH-IN"}>{t("Depósito")}</MenuItem>
                  <MenuItem value={"CASH-OUT"}>{t("Saque")}</MenuItem>
                  <MenuItem value={"TEF"}>TEF</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        </Grid>
        <Grid item xs={7} sm={4} className="datepickers">
          <div className="flex w-full gap-1">
            <div className="w-full">
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="pt-br"
              >
                <DatePicker
                  minDate={fromDate}
                  maxDate={dayjs()}
                  slotProps={{
                    textField: {
                      error: false,
                      size: "small",
                      InputProps: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <Typography style={{ fontWeight: "500" }}>
                              {t("De: ")}
                            </Typography>
                          </InputAdornment>
                        ),
                      },
                    },
                  }}
                  sx={{
                    width: "100%",
                  }}
                  disabled={!!filterText}
                  value={date}
                  onChange={(newValue) => setDate(newValue)}
                />
              </LocalizationProvider>
            </div>
            <div className="w-full hidden sm:block">
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  slotProps={{
                    textField: {
                      error: false,
                      size: "small",
                      InputProps: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <Typography style={{ fontWeight: "500" }}>
                              {t("Até: ")}
                            </Typography>
                          </InputAdornment>
                        ),
                      },
                    },
                  }}
                  sx={{
                    width: "100%",
                  }}
                  disabled={!!filterText}
                  value={date}
                  readOnly
                />
              </LocalizationProvider>
            </div>
          </div>
        </Grid>
        <Grid item xs={5} sm={1} className="flex gap-1 justify-end">
          <div className="w-3/6 h-full flex gap-1">
            <Button
              variant="contained"
              onClick={handleDownloadCSV}
              disabled={!!downloading} // disable button when downloading
              style={{
                color: downloading ? "lightgray" : "white", // change color when disabled
                backgroundColor: downloading ? "#f0f0f0" : "green", // change backgroundColor when disabled
                borderColor: downloading ? "gray" : "green", // change borderColor when disabled
                minHeight: "1px",
                minWidth: "1px",
                marginRight: "1px",
                width: "100%",
                height: "100%",
              }}
            >
              <DownloadIcon />
            </Button>

            <LightTooltip
              title={t("Máximo de 1 dia. Em breve, disponível para 7 dias.")}
              TransitionComponent={Zoom}
              placement="left"
            >
              <InfoOutlinedIcon
                style={{ fontSize: "large", verticalAlign: "top" }}
              />
            </LightTooltip>
          </div>
        </Grid>
      </Grid>
      <div className="px-4 flex gap-3 w-full mt-4">
        {chips.map((chip, index) => (
          <Chip
            key={index}
            label={chip}
            className="border-zinc-400"
            onDelete={() => handleDeleteChip(index, chip)}
            variant="outlined"
            sx={{
              border: "2px solid rgb(161 161 170)",
              "& .MuiChip-label": {
                fontSize: 15,
                fontWeight: "500",
              },
            }}
          />
        ))}
      </div>
    </div>
  );
};

const List = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  if (isMobile) {
    import("./listmobile.scss");
  } else {
    import("./list.scss");
  }

  const { darkMode } = useContext(DarkModeContext); // Get the darkMode value from the context
  const [filterData, setFilterData] = useState({});

  const handleFilterData = (data) => {
    setFilterData(data);
  };

  return (
    <div className={`list ${darkMode ? "dark" : ""} w-full`}>
      <Sidebar />
      <div className="w-10/12 sm:w-full homeContainer">
        <QueryClientProvider client={queryClient}>
          <Navbar />
        </QueryClientProvider>
        <div className="w-full">
          <div className="flex items-center justify-between mt-6 mb-2">
            <SearchBar onFilterData={handleFilterData} />
          </div>
          <Datatable filterData={filterData} />
        </div>
      </div>
    </div>
  );
};

export default List;
