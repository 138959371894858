import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Alert, AlertTitle, InputAdornment, Slide } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { CheckCircleRounded } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import TextField from "@mui/material/TextField";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { FaCheck } from "react-icons/fa";
import { NumericFormat } from "react-number-format";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import {
  getBalance,
  simulateCashIn,
  simulateWithdraw,
} from "../../services/TransactionsService";
import "./simPayments.scss";
import * as yup from "yup";
import { object } from "yup";
import { cpf, cnpj } from "cpf-cnpj-validator";
import {
  formatPhoneNumber,
  mask,
  maskOff,
  maskPhone,
} from "../../utils/validations";
import { useQuery } from "react-query";

const SimPayments = () => {
  const [pixKey, setPixKey] = useState("");
  const [amount, setAmount] = useState("");
  const [cashoutAmount, setCashoutAmount] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState(null);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [cpfCashIn, setCpfCashIn] = useState("");
  const [nameCashIn, setNameCashIn] = useState("");
  const [emailCashIn, setEmailCashIn] = useState("");
  const [typePix, setTypePix] = useState("");
  const [statusCashout, setStatusCashout] = useState("");
  const [statusCashin, setStatusCashin] = useState("");
  const [maxLength, setMaxLength] = useState("");
  const [errorName, setErrorName] = useState(false);
  const [helperTextName, setHelperTextName] = useState("");
  const [errorCpfCnpj, setErrorCpfCnpj] = useState(false);
  const [helperTextCpfCnpj, sethelperTextCpfCnpj] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [helperTextEmail, setHelperTextEmail] = useState("");
  const [errorPixKey, setErrorPixKey] = useState(false);
  const [helperTextPixKey, setHelperTextPixKey] = useState("");
  const [errorAmount, setErrorAmount] = useState(false);
  const [helperTextAmount, setHelperTextAmount] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
  }));

  const theme = createTheme({
    typography: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
    },
    focusedTextField: {
      "&.Mui-focused": {
        borderColor: "yellow", // Change this to the desired color
      },
    },
  });

  const validateEmailCashin = () => {
    const validationCashInSchema = object({
      nameCashIn: yup
        .string()
        .required()
        .min(3, "Mínimo de 3 caracteres para o campo Nome"),
      emailCashIn: yup
        .string()
        .required("O campo e-mail é obrigatório")
        .email("Um e-mail válido é necessário"),
    });
    const data = {
      nameCashIn: nameCashIn,
      emailCashIn: emailCashIn,
    };
    validationCashInSchema
      .validate(data)
      .then(
        (validData) => console.log("yup valid", validData),
        setErrorEmail(false),
        setHelperTextEmail("")
      )
      .catch((error) => {
        setErrorEmail(true);
        setHelperTextEmail(t(error.errors));
        console.error("An error occurred (yup):", error);
        handleShowAlert("error", t("Invalid Field"), t(error.errors));
      });
  };

  const handleSimCashin = async () => {
    setSuccess(false);
    setOpen(true);
    try {
      const amountValue = parseFloat(amount);

      if (!cpf.isValid(cpfCashIn) && !cnpj.isValid(cpfCashIn)) {
        throw new Error("O documento CPF/CNPJ não é valido");
      }

      const cpfCashInNoMask = maskOff(cpfCashIn);
      const response = await simulateCashIn(
        amountValue,
        cpfCashInNoMask,
        nameCashIn,
        emailCashIn,
        statusCashin
      );
      console.log(response);
      if (response) {
        setStatusCashin("");
        setAmount("");
        setCpfCashIn("");
        setNameCashIn("");
        setEmailCashIn("");
        setSuccess(true);
        handleShowAlert(
          "success",
          t("Cash-in Simulation Completed"),
          t("Cash-in simulation completed successfully!")
        );
      }
    } catch (error) {
      setError(true);
      console.error("An error occurred:", error);
      setTimeout(() => {
        handleShowAlert(
          "error",
          t("Error simulating cash-in"),
          t("Error simulating deposit")
        );
        setOpen(false);
        setError(false);
      }, 1000);
    } finally {
      setTimeout(() => {
        setOpen(false);
      }, 1000);
    }
  };

  const { data: metrics, refetch } = useQuery({
    queryKey: ["balance"],
    queryFn: () => getBalance(),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const validateName = () => {
    if (nameCashIn.length < 3) {
      handleShowAlert(
        "error",
        t("Invalid Field"),
        t("The name must have at least 3 characters")
      );
      setErrorName(true);
      setHelperTextName(t("The name must have at least 3 characters"));
      setNameCashIn("");
    } else {
      setErrorName(false);
      setHelperTextName("");
    }
  };

  const validateAmount = () => {
    if (amount < 1) {
      handleShowAlert(
        "error",
        t("Invalid Field"),
        t("Total amount lower than R$ 1.00 is not allowed.")
      );
      setErrorAmount(true);
      setHelperTextAmount(t("The total amount must not be less than R$ 1.00."));
      setAmount("");
    } else {
      setErrorAmount(false);
      setHelperTextAmount("");
    }
  };

  const validateCpfCnpj = () => {
    if (!cpf.isValid(cpfCashIn) && !cnpj.isValid(cpfCashIn)) {
      handleShowAlert("error", t("Invalid Field"), t("CPF/CNPJ invalid."));
      setErrorCpfCnpj(true);
      sethelperTextCpfCnpj(t("CPF/CNPJ invalid."));
    } else {
      setErrorCpfCnpj(false);
      sethelperTextCpfCnpj("");
    }
  };

  const validatePixKey = () => {
    if (typePix === "cpf") {
      if (!cpf.isValid(pixKey)) {
        handleShowAlert("error", t("Invalid Field"), t("Invalid CPF."));
        setErrorPixKey(true);
        setHelperTextPixKey(t("Invalid CPF."));
      } else {
        setErrorPixKey(false);
        setHelperTextPixKey("");
      }
    }
    if (typePix === "cnpj") {
      if (!cnpj.isValid(pixKey)) {
        handleShowAlert("error", t("Invalid Field"), t("Invalid CNPJ."));
        setErrorPixKey(true);
        setHelperTextPixKey(t("Invalid CNPJ."));
      } else {
        setErrorPixKey(false);
        setHelperTextPixKey("");
      }
    }
    if (typePix === "email") {
      const validationCashOutSchema = object({
        pixKey: yup.string().required().email(t("A valid email is required")),
      });
      const data = { pixKey: pixKey };

      validationCashOutSchema
        .validate(data)
        .then(
          (validData) => {
            console.log("yup valid", validData), setPixKey(validData.pixKey);
          },
          setErrorPixKey(false),
          setHelperTextPixKey("")
        )
        .catch((error) => {
          setErrorPixKey(true);
          setHelperTextPixKey(t(error.errors));
          console.error("An error occurred (yup):", error);
          handleShowAlert(
            "error",
            t("Error simulating cash-out"),
            t(error.errors)
          );
        });
    }
  };

  const handleSimCashout = async () => {
    setSuccess(false);
    setOpen(true);
    refetch();
    try {
      let pixKeyNoMask;
      if (typePix === "cpf" || typePix === "cnpj" || typePix === "phone") {
        pixKeyNoMask = maskOff(pixKey);
        if (typePix === "phone") {
          const phoneFormated = formatPhoneNumber(pixKeyNoMask);
          pixKeyNoMask = phoneFormated;
        }
      }
      if (typePix === "email" || typePix === "evp") {
        pixKeyNoMask = pixKey;
      }
      const amountValue = parseFloat(cashoutAmount);

      if (parseFloat(metrics?.accounts[0]?.balance) - amountValue < 0) {
        handleShowAlert(
          "error",
          t("Error simulating cash-out"),
          t("Saldo Insuficiente.")
        );
        return;
      }

      const response = await simulateWithdraw(
        amountValue,
        pixKeyNoMask,
        typePix,
        statusCashout
      );
      if (response) {
        setCashoutAmount("");
        setStatusCashout("");
        setPixKey("");
        setTypePix("");
        setSuccess(true);
        handleShowAlert(
          "success",
          t("Cash-out Simulation Completed"),
          t("Cash-out simulation successful!")
        );
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setError(true);
      setTimeout(() => {
        handleShowAlert(
          "error",
          t("Error simulating cash-out"),
          t("An error occurred during simulate withdrawal.")
        );
        setOpen(false);
        setError(false);
      }, 1000);
    } finally {
      setTimeout(() => {
        setOpen(false);
      }, 1000);
    }
  };

  const handleChangeTypePix = (event) => {
    setTypePix(event.target.value);
    setPixKey("");
    setErrorPixKey(false);
    setHelperTextPixKey("");
  };

  useEffect(() => {
    switch (typePix) {
      case "cpf":
        setMaxLength(14);
        break;
      case "cnpj":
        setMaxLength(18);
        break;
      case "email":
        setMaxLength(50);
        break;
      case "phone":
        setMaxLength(15);
        break;
      case "evp":
        setMaxLength(40);
        break;
      default:
        break;
    }
  }, [typePix]);

  const handleShowAlert = (type, title, text) => {
    setAlertContent({
      type: type,
      title: title,
      text: text,
    });
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="receipts">
        <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2">
          <Slide direction="up" in={showAlert} mountOnEnter unmountOnExit>
            <Alert variant="filled" severity={alertContent?.type}>
              <AlertTitle>{alertContent?.title}</AlertTitle>
              {alertContent?.text}
            </Alert>
          </Slide>
        </div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          {success ? (
            <CheckCircleRounded sx={{ fontSize: 60, color: "#4caf50" }} />
          ) : error ? (
            <CancelIcon sx={{ fontSize: 60, color: "#fb0000" }} />
          ) : (
            <CircularProgress color="inherit" />
          )}
        </Backdrop>

        <Sidebar />
        <div className="w-full">
          <Navbar />
          {/* <Widget /> */}
          <div className="flex flex-col h-[calc(100%-112px)]">
            <div className="w-11/12 mx-auto gap-5 flex">
              <div className="flex mt-5 gap-5 w-full">
                <div className=" font-semibold mb-1 w-full flex justify-center items-center text-center text-xl">
                  {t("Ambiente de Simulação de Transações")}
                </div>
              </div>
            </div>
            <div className="flex h-full">
              <div className="h-full w-full flex">
                <div className="flex h-4/6 w-full flex-col justify-between">
                  {/* SIMULAÇÃO DE CASHIN*/}
                  <div
                    className={
                      "ordertransition w-11/12 flex justify-around gap-10 p-5 mx-auto m-5 rounded border-2 border-iziblue"
                    }
                  >
                    <Grid container spacing={2}>
                      <Grid item md={5} xs={12}>
                        <div className="flex items-center h-full gap-2 w-full">
                          <LightTooltip
                            title={t("Simula um depósito em sua conta")}
                            TransitionComponent={Zoom}
                            placement="left"
                          >
                            <InfoOutlinedIcon
                              style={{
                                fontSize: "large",
                                verticalAlign: "top",
                              }}
                            />
                          </LightTooltip>
                          <div>
                            <span className="text-2xl font-semibold">
                              {t("Simular Depósito")}
                            </span>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={5} xs={12}>
                        <div className="flex gap-5 w-full content-end">
                          <div
                            className={
                              "noblurred blurreddiv flex w-full p-2 justify-end"
                            }
                          >
                            <div className="flex flex-col gap-5 items-center w-full p-2">
                              <FormControl fullWidth>
                                <InputLabel
                                  className="hidden"
                                  id="status-cashin-select-label"
                                >
                                  Status da Transação
                                </InputLabel>
                                <Select
                                  labelId="status-cashin-select-label"
                                  id="status-cashin-select"
                                  value={statusCashin}
                                  label={t("Status")}
                                  onChange={(e) =>
                                    setStatusCashin(e.target.value)
                                  }
                                >
                                  <MenuItem value={"done"}>Realizada</MenuItem>
                                  <MenuItem value={"rejected"}>
                                    Rejeitada
                                  </MenuItem>
                                </Select>
                              </FormControl>
                              <TextField
                                error={errorName}
                                helperText={helperTextName}
                                required
                                label={t("Nome do responsável")}
                                variant="outlined"
                                value={nameCashIn}
                                onBlur={validateName}
                                onChange={(e) => setNameCashIn(e.target.value)}
                                className="paymentComponent w-full"
                              />
                              <TextField
                                error={errorCpfCnpj}
                                helperText={helperTextCpfCnpj}
                                required
                                label={t("CPF/CNPJ do responsável")}
                                variant="outlined"
                                value={cpfCashIn}
                                inputProps={{
                                  maxLength: 18,
                                }}
                                onBlur={validateCpfCnpj}
                                onChange={(e) =>
                                  setCpfCashIn(mask(e.target.value.trim()))
                                }
                                className="paymentComponent w-full"
                              />
                              <TextField
                                error={errorEmail}
                                helperText={helperTextEmail}
                                required
                                label={t("E-mail do responsável")}
                                variant="outlined"
                                type="email"
                                onBlur={validateEmailCashin}
                                value={emailCashIn}
                                onChange={(e) =>
                                  setEmailCashIn(e.target.value.trim())
                                }
                                className="paymentComponent w-full"
                              />
                              <NumericFormat
                                required
                                onBlur={validateAmount}
                                error={errorAmount}
                                helperText={helperTextAmount}
                                customInput={TextField}
                                label={t("Amount (R$)")}
                                variant="outlined"
                                value={amount}
                                onValueChange={(values) => {
                                  const { floatValue } = values;
                                  setAmount(
                                    floatValue ? floatValue.toFixed(2) : ""
                                  );
                                }}
                                thousandSeparator="."
                                decimalSeparator=","
                                fixedDecimalScale={true}
                                decimalScale={2}
                                prefix={"R$ "}
                                className="paymentComponent w-full"
                              />
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <div className="w-full h-full flex justify-center p-2">
                          <div className="h-full flex items-end">
                            <Button
                              variant="contained"
                              color="success"
                              disabled={
                                nameCashIn &&
                                cpfCashIn &&
                                emailCashIn &&
                                amount &&
                                !errorName &&
                                !errorCpfCnpj &&
                                !errorEmail
                                  ? false
                                  : true
                              }
                              onClick={handleSimCashin}
                              className="paymentComponent paymentButton h-14 bg-green-700 icon-containercheck"
                              style={{
                                fontSize: "25px",
                              }}
                            >
                              <FaCheck className="iconcheck" />
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>

                  <div
                    className={
                      "ordertransition w-11/12 flex justify-around gap-10 p-5 mx-auto m-5 rounded border-2 border-iziblue"
                    }
                  >
                    <Grid container spacing={2}>
                      <Grid item md={5} xs={12}>
                        <div className="flex items-center h-full gap-2 w-full">
                          <LightTooltip
                            title={t(
                              "Simula a retirada de fundos da sua conta"
                            )}
                            TransitionComponent={Zoom}
                            placement="left"
                          >
                            <InfoOutlinedIcon
                              style={{
                                fontSize: "large",
                                verticalAlign: "top",
                              }}
                            />
                          </LightTooltip>
                          <div>
                            <span className=" text-2xl font-semibold">
                              {t("Simular Saque")}
                            </span>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={5} xs={12}>
                        <div className="flex gap-5 w-full content-end">
                          <div
                            className={
                              "noblurred blurreddiv flex flex-col p-2 gap-3 w-full"
                            }
                          >
                            <div className="flex flex-col gap-5 items-center w-full p-2">
                              <FormControl fullWidth>
                                <InputLabel
                                  className="hidden"
                                  id="status-cashout-select-label"
                                >
                                  Status da Transação
                                </InputLabel>
                                <Select
                                  labelId="status-cashout-select-label"
                                  id="status-cashout-select"
                                  value={statusCashout}
                                  label={t("Status")}
                                  onChange={(e) =>
                                    setStatusCashout(e.target.value)
                                  }
                                >
                                  <MenuItem value={"done"}>Realizada</MenuItem>
                                  <MenuItem value={"rejected"}>
                                    Rejeitada
                                  </MenuItem>
                                </Select>
                              </FormControl>
                              <FormControl fullWidth>
                                <InputLabel
                                  className="hidden"
                                  id="demo-simple-select-label"
                                >
                                  Tipo Chave Pix
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={typePix}
                                  label={t("Chave Pix")}
                                  onChange={handleChangeTypePix}
                                >
                                  <MenuItem value={"cpf"}>CPF</MenuItem>
                                  <MenuItem value={"cnpj"}>CNPJ</MenuItem>
                                  <MenuItem value={"email"}>E-mail</MenuItem>
                                  <MenuItem value={"phone"}>Celular</MenuItem>
                                  <MenuItem value={"evp"}>
                                    Chave Aleatória
                                  </MenuItem>
                                </Select>
                              </FormControl>
                              <TextField
                                error={errorPixKey}
                                helperText={helperTextPixKey}
                                label={t("Pix Key")}
                                variant="outlined"
                                value={pixKey}
                                inputProps={{
                                  maxLength: maxLength,
                                }}
                                onChange={(e) => {
                                  if (typePix === "cpf" || typePix === "cnpj") {
                                    setPixKey(mask(e.target.value));
                                  } else if (typePix === "phone") {
                                    setPixKey(maskPhone(e.target.value));
                                  } else setPixKey(e.target.value.trim());
                                }}
                                onBlur={validatePixKey}
                                className="paymentComponent w-full"
                                placeholder={
                                  typePix === "phone"
                                    ? "DDD + número (sem espaços)"
                                    : ""
                                }
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {typePix === "phone" ? "+55" : ""}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <NumericFormat
                                customInput={TextField}
                                label={t("Amount (R$)")}
                                variant="outlined"
                                value={cashoutAmount}
                                onValueChange={(values) => {
                                  const { floatValue } = values;
                                  setCashoutAmount(
                                    floatValue ? floatValue.toFixed(2) : ""
                                  );
                                }}
                                thousandSeparator="."
                                decimalSeparator=","
                                fixedDecimalScale={true}
                                decimalScale={2}
                                prefix={"R$ "}
                                className="paymentComponent w-full"
                              />
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <div className="w-full h-full flex justify-center p-2 pb-3">
                          <div className="h-full flex items-end">
                            <Button
                              variant="contained"
                              color="success"
                              onClick={handleSimCashout}
                              disabled={
                                typePix &&
                                pixKey &&
                                cashoutAmount &&
                                !errorPixKey
                                  ? false
                                  : true
                              }
                              className="paymentComponent paymentButton h-14 bg-green-700 icon-containercheck"
                              style={{
                                fontSize: "25px",
                              }}
                            >
                              <FaCheck className="iconcheck" />
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default SimPayments;
