import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useContext, useState } from "react";
import { UserContext } from "../../context/loggedUserContext";

const formatCurrency = (value) => {
  const formattedValue = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value / 100);

  return formattedValue;
};

export default function AuthModal({
  open,
  onClose,
  status,
  handleShowAlert,
  transactionData,
  handleConfirm,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { user } = useContext(UserContext);

  const userPassword = user.password;

  const handlePassword = (event) => {
    const enteredPassword = event.target.value;
    setConfirmPassword(enteredPassword);
    setIsPasswordCorrect(enteredPassword === userPassword);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCancel = () => {
    onClose();
  };

  if (!transactionData) {
    return null;
  }

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {status === "APPROVED" ? "Aprovar Transação" : "Bloquear Transação"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {status === "APPROVED"
              ? `Confirmar aprovação da transação de ${formatCurrency(
                  transactionData.amount
                )} do Usuário ${transactionData.user_description}?`
              : `Bloquear aprovação da transação de ${formatCurrency(
                  transactionData.amount
                )} do Usuário ${transactionData.user_description}?`}
          </DialogContentText>
         <form autoComplete="off">
         <TextField
            autoFocus 
            margin="dense"
            id="password"
            label="Senha"
            type={showPassword ? "text" : "password"}
            fullWidth
            autoComplete="off"
            variant="outlined"
            autoComplete="off"
            value={confirmPassword}
            onChange={handlePassword}
            sx={{ marginTop: 6 }}
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleTogglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ),
            }}
          />
         </form>
        </DialogContent>
        <DialogActions sx={{ marginBottom: 2 }}>
          <Button
            variant="contained"
            size="small"
            sx={{
              backgroundColor: "#e57373",
              marginRight: 2,
              "&:hover": {
                backgroundColor: "#d32f2f",
              },
            }}
            onClick={handleCancel}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              backgroundColor: "#66bb6a",
              marginRight: 2,
              "&:hover": {
                backgroundColor: "#21930d",
              },
            }}
            onClick={handleConfirm}
            disabled={!isPasswordCorrect}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
