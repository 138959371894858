import { createContext, useEffect, useState } from "react";
import { authenticate } from "../services/TransactionsService";
import { decrypt } from "../utils/crypto.mjs";

const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function fetchUser() {
      if (sessionStorage.getItem("access_token")) {
        try {
          const response = await authenticate();
          if (response) {
            setUser(response);
          }
        } catch (error) {
          console.error("Failed to authenticate:", error);
          sessionStorage.clear();
          setUser(null);
        }
      } else {
        sessionStorage.clear();
        setUser(null);
      }
    }

    fetchUser();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
