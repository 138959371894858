import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  InputAdornment,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const FeeComponent = ({
  cashInFee,
  cashOutFee,
  cashInType,
  cashOutType,
  handleCashInChange,
  handleCashOutChange,
  handleCashInTypeChange,
  handleCashOutTypeChange,
}) => {
  const renderCashInAdornment = () => {
    return cashInType === "percentage" ? "%" : "R$";
  };

  const renderCashOutAdornment = () => {
    return cashOutType === "percentage" ? "%" : "R$";
  };

  return (
    <div className="flex">
      <div className="flex gap-3 w-6/12">
        <div className="flex">
          <div className="flex gap-2 items-center">
            <div>
              <span>Cash-In Fee</span>
              <ArrowUpwardIcon />
            </div>
            <div className=" mx-auto">
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="cash-in-radio-buttons-group-label"
                  defaultValue="value"
                  onChange={handleCashInTypeChange}
                  name="cash-in-radio-buttons-group"
                >
                  <FormControlLabel
                    value="value"
                    control={<Radio />}
                    label="Valor R$"
                  />
                  <FormControlLabel
                    value="percentage"
                    control={<Radio />}
                    label="Porcentagem %"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="w-2/12">
          <FormControl className="w-full">
            <TextField
              id="cash-in-slider"
              label="Fee"
              variant="outlined"
              value={cashInFee}
              onChange={handleCashInChange}
              InputProps={{
                ...(cashInType === "percentage"
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          {renderCashInAdornment()}
                        </InputAdornment>
                      ),
                    }
                  : {
                      startAdornment: (
                        <InputAdornment position="start">
                          {renderCashInAdornment()}
                        </InputAdornment>
                      ),
                    }),
              }}
            />
          </FormControl>
        </div>
      </div>
      <div className="flex gap-3 w-6/12">
        <div className="flex">
          <div className="flex gap-2 items-center">
            <div>
              <span>Cash-Out Fee</span>
              <ArrowUpwardIcon />
            </div>
            <div className=" mx-auto">
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="cash-out-radio-buttons-group-label"
                  defaultValue="value"
                  onChange={handleCashOutTypeChange}
                  name="cash-out-radio-buttons-group"
                >
                  <FormControlLabel
                    value="value"
                    control={<Radio />}
                    label="Valor R$"
                  />
                  <FormControlLabel
                    value="percentage"
                    control={<Radio />}
                    label="Porcentagem %"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="w-2/12">
          <FormControl className="w-full">
            <TextField
              id="cash-out-slider"
              label="Fee"
              variant="outlined"
              value={cashOutFee}
              onChange={handleCashOutChange}
              InputProps={{
                ...(cashOutType === "percentage"
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          {renderCashOutAdornment()}
                        </InputAdornment>
                      ),
                    }
                  : {
                      startAdornment: (
                        <InputAdornment position="start">
                          {renderCashOutAdornment()}
                        </InputAdornment>
                      ),
                    }),
              }}
            />
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default FeeComponent;
