import { CheckCircleRounded } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Alert, AlertTitle, InputAdornment, Slide } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import { cnpj, cpf } from "cpf-cnpj-validator";
import QRCode from "qrcode.react";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { FaArrowDown, FaArrowUp, FaCheck } from "react-icons/fa";
import { FaRegCopy } from "react-icons/fa6";
import { GrTransaction } from "react-icons/gr";
import { NumericFormat } from "react-number-format";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { object } from "yup";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { UserContext } from "../../context/loggedUserContext";
import {
  generateCashIn,
  getBalance,
  requestWithdraw,
  tef,
  withdraw,
} from "../../services/TransactionsService";
import {
  formatPhoneNumber,
  mask,
  maskOff,
  maskPhone,
} from "../../utils/validations";
import "./payments.scss";

const Payments = () => {
  const [qrCodeGenerated, setQrCodeGenerated] = useState("");
  const [pixKey, setPixKey] = useState("");
  const [virtualAccount, setVirtualAccount] = useState("");
  const [amount, setAmount] = useState("");
  const [cashoutAmount, setCashoutAmount] = useState("");
  const [tefAmount, setTefAmount] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [showModal, setShowModal] = useState(false); // State for showing modal
  const [showQRCodeModal, setShowQRCodeModal] = useState(false);
  const [receiverData, setReceiverData] = useState(null); // State for receiver data
  const [pinCode, setPinCode] = useState(""); // State for pin code
  const { t } = useTranslation();
  const [activeButton, setActiveButton] = useState(null);
  const [open, setOpen] = useState(false);
  const [cpfCashIn, setCpfCashIn] = useState("");
  const [nameCashIn, setNameCashIn] = useState("");
  const [emailCashIn, setEmailCashIn] = useState("");
  const [typePix, setTypePix] = useState("");
  const [maxLength, setMaxLength] = useState("");
  const [errorName, setErrorName] = useState(false);
  const [helperTextName, setHelperTextName] = useState("");
  const [errorAmount, setErrorAmount] = useState(false);
  const [helperTextAmount, setHelperTextAmount] = useState("");
  const [errorCpfCnpj, setErrorCpfCnpj] = useState(false);
  const [helperTextCpfCnpj, sethelperTextCpfCnpj] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [helperTextEmail, setHelperTextEmail] = useState("");
  const [errorPixKey, setErrorPixKey] = useState(false);
  const [helperTextPixKey, setHelperTextPixKey] = useState("");
  const { user } = useContext(UserContext);
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
  }));

  const theme = createTheme({
    typography: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
    },
    focusedTextField: {
      "&.Mui-focused": {
        borderColor: "yellow", // Change this to the desired color
      },
    },
  });

  // const navigate = useNavigate();
  // const isDev = import.meta.env.MODE === "development";
  // useEffect(() => {
  //   if (isDev) {
  //     navigate("/sim-payments", { replace: true });
  //   }
  //   const handleEscape = (event) => {
  //     if (event.key === "Escape") {
  //       setShowModal(false);
  //       setShowQRCodeModal(false);
  //     }
  //   };
  //   document.addEventListener("keydown", handleEscape);
  //   return () => {
  //     document.removeEventListener("keydown", handleEscape);
  //   };
  // }, []);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(qrCodeGenerated);
    setShowQRCodeModal(false);
    setQrCodeGenerated("");
    setAmount("");
  };

  const handleCashoutConfirm = async () => {
    setSuccess(false);
    setOpen(true);

    try {
      const amountValue = parseFloat(cashoutAmount);
      let pixKeyNoMask;
      if (typePix === "cpf" || typePix === "cnpj" || typePix === "phone") {
        pixKeyNoMask = maskOff(pixKey);
        if (typePix === "phone") {
          const phoneFormated = formatPhoneNumber(pixKeyNoMask);
          pixKeyNoMask = phoneFormated;
        }
      }
      if (typePix === "email" || typePix === "evp") {
        pixKeyNoMask = pixKey;
      }
      console.log(pinCode, amountValue, pixKeyNoMask, typePix);
      const response = await withdraw(
        pinCode,
        amountValue,
        pixKeyNoMask,
        typePix
      );
      setCashoutAmount("");
      setPixKey("");
      setSuccess(true);
      console.log("RETORNO APPROVAL", response);
      if (response.message === "Transaction sent for approval") {
        handleShowAlert(
          "warning",
          t("Saque Pendente"),
          t("Transação enviada para aprovação.")
        );
      } else {
        handleShowAlert(
          "success",
          t("Cash-out Completed"),
          t("Withdrawal successful!")
        );
      }
    } catch (error) {
      setError(true);
      console.error("An error occurred:", error);
      setTimeout(() => {
        handleShowAlert(
          "error",
          t("Error Cash-Out"),
          t("An error occurred during withdrawal.")
        );
        setOpen(false);
        setError(false);
      }, 1000);
    } finally {
      setTimeout(() => {
        setOpen(false);
      }, 1000);
    }
  };

  const handleTEFConfirm = async () => {
    setSuccess(false);
    setOpen(true);
    try {
      const amountValue = parseFloat(tefAmount);
      await tef(amountValue, virtualAccount);
      setTefAmount("");
      setVirtualAccount("");
      setSuccess(true);
      handleShowAlert("success", "TEF", t("TEF realizada!"));
    } catch (error) {
      setError(true);
      console.error("An error occurred:", error);
      setTimeout(() => {
        handleShowAlert("error", "TEF", t("An error occurred during TEF."));
        setOpen(false);
        setError(false);
      }, 1000);
    } finally {
      setTimeout(() => {
        setOpen(false);
      }, 1000);
    }
  };

  const validateEmailCashin = () => {
    const validationCashInSchema = object({
      nameCashIn: yup
        .string()
        .required(t("O campo nome é obrigatório"))
        .min(3, t("Mínimo de 3 caracteres para o campo Nome")),
      emailCashIn: yup
        .string()
        .required(t("O campo e-mail é obrigatório"))
        .email(t("É necessário um e-mail válido")),
    });
    const data = {
      nameCashIn: nameCashIn,
      emailCashIn: emailCashIn,
    };
    validationCashInSchema
      .validate(data)
      .then(
        (validData) => console.log("yup valid", validData),
        setErrorEmail(false),
        setHelperTextEmail("")
      )
      .catch((error) => {
        setErrorEmail(true);
        setHelperTextEmail(error.errors);
        console.error("An error occurred (yup):", error);
        handleShowAlert("error", t("Invalid Field"), error.errors);
      });
  };

  const handleCashinConfirm = async () => {
    setSuccess(false);
    setOpen(true);
    try {
      const amountValue = parseFloat(amount);

      if (!cpf.isValid(cpfCashIn) && !cnpj.isValid(cpfCashIn)) {
        throw new Error(t("O documento CPF/CNPJ não é valido"));
      }

      const cpfCashInNoMask = maskOff(cpfCashIn);

      const response = await generateCashIn(
        amountValue,
        cpfCashInNoMask,
        nameCashIn,
        emailCashIn
      );
      setQrCodeGenerated(response?.qr_code_value);
      setAmount("");
      setNameCashIn("");
      setCpfCashIn("");
      setEmailCashIn("");
      setSuccess(true);
      setTimeout(() => {
        handleShowAlert(
          "success",
          t("QR Code Gerado"),
          t("Efetue o pagamento para concluir o depósito.")
        );
        setShowQRCodeModal(true);
      }, 1000);
    } catch (error) {
      setError(true);
      console.error("An error occurred:", error);
      setTimeout(() => {
        handleShowAlert(
          "error",
          t("Error Cash-In"),
          t("Error generating QR Code.")
        );
        setOpen(false);
        setError(false);
      }, 1000);
    } finally {
      setTimeout(() => {
        setOpen(false);
      }, 1000);
    }
  };

  const { data: metrics, refetch } = useQuery({
    queryKey: ["balance"],
    queryFn: () => getBalance(),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const validateName = () => {
    if (nameCashIn.length < 3) {
      handleShowAlert(
        "error",
        t("Invalid Field"),
        t("O nome deve ter pelo menos 3 caracteres.")
      );
      setErrorName(true);
      setHelperTextName(t("O nome deve ter pelo menos 3 caracteres."));
      setNameCashIn("");
    } else {
      setErrorName(false);
      setHelperTextName("");
    }
  };
  const validateAmount = () => {
    if (amount < 1) {
      handleShowAlert(
        "error",
        t("Invalid Field"),
        t("Total amount lower than R$ 1.00 is not allowed.")
      );
      setErrorAmount(true);
      setHelperTextAmount(t("The total amount must not be less than R$ 1.00."));
      setAmount("");
    } else {
      setErrorAmount(false);
      setHelperTextAmount("");
    }
  };
  const validateCpfCnpj = () => {
    if (!cpf.isValid(cpfCashIn) && !cnpj.isValid(cpfCashIn)) {
      handleShowAlert("error", t("Invalid Field"), t("CPF/CNPJ inválido!"));
      setErrorCpfCnpj(true);
      sethelperTextCpfCnpj(t("CPF/CNPJ inválidos!"));
    } else {
      setErrorCpfCnpj(false);
      sethelperTextCpfCnpj("");
    }
  };

  const validatePixKey = () => {
    if (typePix === "cpf") {
      if (!cpf.isValid(pixKey)) {
        handleShowAlert("error", t("Invalid Field"), t("CPF inválido!"));
        setErrorPixKey(true);
        setHelperTextPixKey(t("CPF inválido!"));
      } else {
        setErrorPixKey(false);
        setHelperTextPixKey("");
      }
    }
    if (typePix === "cnpj") {
      if (!cnpj.isValid(pixKey)) {
        handleShowAlert("error", t("Invalid Field"), t("CNPJ inválido!"));
        setErrorPixKey(true);
        setHelperTextPixKey(t("CNPJ inválido!"));
      } else {
        setErrorPixKey(false);
        setHelperTextPixKey("");
      }
    }
    if (typePix === "email") {
      const validationCashOutSchema = object({
        pixKey: yup
          .string()
          .required(t("O campo e-mail é obrigatório"))
          .email(t("É necessário um e-mail válido")),
      });
      const data = { pixKey: pixKey };

      validationCashOutSchema
        .validate(data)
        .then(
          (validData) => {
            console.log("yup valid", validData), setPixKey(validData.pixKey);
          },
          setErrorPixKey(false),
          setHelperTextPixKey("")
        )
        .catch((error) => {
          setErrorPixKey(true);
          setHelperTextPixKey(error.errors);
          console.error("An error occurred (yup):", error);
          handleShowAlert("error", t("Invalid Field"), t(error.errors));
        });
    }
  };

  const handleCashoutClick = async () => {
    setSuccess(false);
    setOpen(true);
    refetch();
    try {
      const amountValue = parseFloat(cashoutAmount);

      let pixKeyNoMask;
      if (typePix === "cpf" || typePix === "cnpj" || typePix === "phone") {
        pixKeyNoMask = maskOff(pixKey);
        if (typePix === "phone") {
          const phoneFormated = formatPhoneNumber(pixKeyNoMask);
          pixKeyNoMask = phoneFormated;
        }
      }
      if (typePix === "email" || typePix === "evp") {
        pixKeyNoMask = pixKey;
      }

      if (parseFloat(metrics?.accounts[0]?.balance) - amountValue < 0) {
        handleShowAlert(
          "error",
          t("Error Cash-Out"),
          t("Insufficient balance.")
        );
        return;
      }
      const response = await requestWithdraw(
        amountValue,
        pixKeyNoMask,
        typePix
      );
      if (response) {
        setReceiverData({
          name: response.sender_name,
          accountNumber: response.sender_account_number,
          bankName: response.sender_account_bank_name,
          branch: response.sender_account_branch,
          documentNumber: response.sender_document_number,
          ispb: response.sender_account_bank_ispb,
        });
        /* setCashoutAmount("");
        setPixKey("");
        setTypePix(""); */
        setSuccess(true);
        setTimeout(() => {
          handleShowAlert(
            "success",
            t("Código PIN enviado com sucesso"),
            t("Verifique seu e-mail para continuar.")
          );
          setShowModal(true);
        }, 1000);
      }
    } catch (error) {
      setError(true);
      console.error("An error occurred:", error);
      setTimeout(() => {
        handleShowAlert(
          "error",
          t("Error Cash-Out"),
          t("An error occurred during withdrawal.")
        );
        setOpen(false);
        setError(false);
      }, 1000);
    } finally {
      setTimeout(() => {
        setOpen(false);
      }, 1000);
    }
  };

  const handleModalConfirm = async () => {
    // Implement your modal confirmation logic here
    await handleCashoutConfirm();
    setShowModal(false);
  };

  const divCashIn = useRef(null);
  const divCashOut = useRef(null);
  const divTef = useRef(null);

  const handleButtonClick = (buttonId, divRef) => {
    setActiveButton(buttonId);

    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowQRCodeModal(false);
  };

  const handleChangeTypePix = (event) => {
    setTypePix(event.target.value);
    setPixKey("");
    setErrorPixKey(false);
    setHelperTextPixKey("");
  };

  useEffect(() => {
    switch (typePix) {
      case "cpf":
        setMaxLength(14);
        break;
      case "cnpj":
        setMaxLength(18);
        break;
      case "email":
        setMaxLength(50);
        break;
      case "phone":
        setMaxLength(15);
        break;
      case "evp":
        setMaxLength(40);
        break;
      default:
        break;
    }
  }, [typePix]);

  const handleShowAlert = (type, title, text) => {
    setAlertContent({
      type: type,
      title: title,
      text: text,
    });
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="receipts">
        <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2">
          <Slide direction="up" in={showAlert} mountOnEnter unmountOnExit>
            <Alert variant="filled" severity={alertContent?.type}>
              <AlertTitle>{alertContent?.title}</AlertTitle>
              {alertContent?.text}
            </Alert>
          </Slide>
        </div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          {success ? (
            <CheckCircleRounded sx={{ fontSize: 60, color: "#4caf50" }} />
          ) : error ? (
            <CancelIcon sx={{ fontSize: 60, color: "#fb0000" }} />
          ) : (
            <CircularProgress color="inherit" />
          )}
        </Backdrop>
        <Sidebar />
        <div className="w-full">
          <Navbar />
          {/* <Widget /> */}
          <div className="flex flex-col h-[calc(100%-112px)]">
            <div className="w-11/12 mx-auto gap-5 flex">
              <div
                ref={divCashIn}
                className="flex mt-5 mb-5 gap-5 w-1/2 self-start"
              >
                <div className="w-1/3">
                  <Tooltip
                    title={<Typography fontSize={15}>Cash-In</Typography>}
                    placement="bottom"
                    arrow
                  >
                    <div
                      onClick={() => handleButtonClick(1, divCashIn)}
                      className={
                        activeButton === 1
                          ? "active w-full icon-containerdeposit cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired"
                          : "w-full icon-containerdeposit cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired"
                      }
                    >
                      <div className="flex gap-2 text-xl justify-center">
                        <span className="hidden sm:block">{t("Depósito")}</span>
                        <div className="self-center">
                          <FaArrowDown className="icondeposit" />
                        </div>
                      </div>
                    </div>
                  </Tooltip>
                </div>
                <div className={user?.access_level === 4 ? " hidden" : "w-1/3"}>
                  <Tooltip
                    title={<Typography fontSize={15}>Cash-Out</Typography>}
                    placement="bottom"
                    arrow
                  >
                    <div
                      onClick={() => handleButtonClick(2, divCashOut)}
                      className={
                        activeButton === 2
                          ? "active w-full icon-containerwithdraw cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired"
                          : "w-full icon-containerwithdraw cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired"
                      }
                    >
                      <div className="flex gap-2 text-xl justify-center">
                        <span className="hidden sm:block">{t("Saque")}</span>
                        <div className="self-center">
                          <FaArrowUp className="iconwithdraw" />
                        </div>
                      </div>
                    </div>
                  </Tooltip>
                </div>
                <div className={user?.access_level === 4 ? " hidden" : "w-1/3"}>
                  <Tooltip
                    title={
                      <Typography fontSize={15}>Transação Interna</Typography>
                    }
                    placement="bottom"
                    arrow
                  >
                    <div
                      onClick={() => handleButtonClick(3, divTef)}
                      className={
                        activeButton === 3
                          ? "active w-full icon-containertef cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired"
                          : "w-full icon-containertef cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired"
                      }
                    >
                      <div className="flex gap-1 text-xl justify-center">
                        <span className="hidden sm:block">{t("TEF")}</span>
                        <div className="self-center">
                          <GrTransaction className="icontef" />
                        </div>
                      </div>
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="flex mr-0 mt-5 mb-5 gap-5 w-1/2 self-center justify-end">
                <div className="w-48 cursor-pointer text-iziblue font-bold py-2 px-2 sm:px-4 rounded hover:text-izired">
                  {/* <div className="flex gap-2 text-xsm justify-center">
                    <a
                      className="w-full h-full"
                      href="https://www.bcb.gov.br/en/currencyconversion"
                      target="_blank"
                    >
                      <span className="flex justify-end">
                        {t("*Conversor de Moeda")}
                      </span>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="flex h-full">
              <div className="h-full w-full flex">
                <div className="flex h-4/6 w-full flex-col justify-between">
                  <div
                    className={
                      activeButton === 1 || activeButton === null
                        ? "ordertransition w-11/12 flex justify-around gap-10 p-5 mx-auto m-5 rounded border-2 border-iziblue"
                        : "hidden sm:block ordertransition w-11/12 justify-around gap-10 p-5 mx-auto m-5 rounded border-2 border-iziblue"
                    }
                  >
                    <Grid container spacing={2}>
                      <Grid item md={5} xs={12}>
                        <div className="flex items-center h-full gap-2 w-full">
                          <LightTooltip
                            title={t("Adicione fundos à sua conta.")}
                            TransitionComponent={Zoom}
                            placement="left"
                          >
                            <InfoOutlinedIcon
                              style={{
                                fontSize: "large",
                                verticalAlign: "top",
                              }}
                            />
                          </LightTooltip>
                          <div>
                            <span className="text-2xl font-semibold">
                              {t("Realizar Depósito")}
                            </span>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={5} xs={12}>
                        <div className="flex gap-5 w-full content-end">
                          <div
                            className={
                              activeButton === 1
                                ? "noblurred blurreddiv flex w-full p-2 justify-end"
                                : "blurredfull blurreddiv flex w-full p-2 justify-end"
                            }
                          >
                            <div className="flex flex-col gap-5 items-center w-full p-2">
                              <TextField
                                error={errorName}
                                helperText={helperTextName}
                                required
                                label={t("Nome do responsável")}
                                variant="outlined"
                                value={nameCashIn}
                                disabled={activeButton === 1 ? false : true}
                                onBlur={validateName}
                                onChange={(e) => setNameCashIn(e.target.value)}
                                className="paymentComponent w-full"
                              />
                              <TextField
                                error={errorCpfCnpj}
                                helperText={helperTextCpfCnpj}
                                required
                                label={t("CPF/CNPJ do responsável")}
                                variant="outlined"
                                value={cpfCashIn}
                                inputProps={{
                                  maxLength: 18,
                                }}
                                disabled={activeButton === 1 ? false : true}
                                onBlur={validateCpfCnpj}
                                onChange={(e) =>
                                  setCpfCashIn(mask(e.target.value.trim()))
                                }
                                className="paymentComponent w-full"
                              />
                              <TextField
                                error={errorEmail}
                                helperText={helperTextEmail}
                                required
                                label={t("E-mail do responsável")}
                                variant="outlined"
                                type="email"
                                onBlur={validateEmailCashin}
                                value={emailCashIn}
                                disabled={activeButton === 1 ? false : true}
                                onChange={(e) =>
                                  setEmailCashIn(e.target.value.trim())
                                }
                                className="paymentComponent w-full"
                              />
                              <NumericFormat
                                error={errorAmount}
                                helperText={helperTextAmount}
                                onBlur={validateAmount}
                                required
                                customInput={TextField}
                                label={t("Valor (R$)")}
                                variant="outlined"
                                value={amount}
                                disabled={activeButton === 1 ? false : true}
                                onValueChange={(values) => {
                                  const { floatValue } = values;
                                  setAmount(
                                    floatValue ? floatValue.toFixed(2) : ""
                                  );
                                }}
                                thousandSeparator="."
                                decimalSeparator=","
                                fixedDecimalScale={true}
                                decimalScale={2}
                                prefix={"R$ "}
                                className="paymentComponent w-full"
                              />
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <div className="w-full h-full flex justify-center p-2">
                          <div className="h-full flex items-end">
                            <Button
                              variant="contained"
                              color="success"
                              disabled={
                                activeButton === 1 &&
                                nameCashIn &&
                                cpfCashIn &&
                                emailCashIn &&
                                amount &&
                                !errorName &&
                                !errorCpfCnpj &&
                                !errorEmail
                                  ? false
                                  : true
                              }
                              onClick={handleCashinConfirm}
                              className="paymentComponent paymentButton h-14 bg-green-700 icon-containercheck"
                              style={{
                                fontSize: "25px",
                              }}
                            >
                              <FaCheck className="iconcheck" />
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>

                  <div className={user?.access_level === 4 ? " hidden" : ""}>
                    <div
                      ref={divCashOut}
                      className={
                        activeButton === 2 || activeButton === null
                          ? "ordertransition w-11/12 flex justify-around gap-10 p-5 mx-auto m-5 rounded border-2 border-iziblue"
                          : "hidden sm:block ordertransition w-11/12 justify-around gap-10 p-5 mx-auto m-5 rounded border-2 border-iziblue"
                      }
                    >
                      <Grid container spacing={2}>
                        <Grid item md={5} xs={12}>
                          <div className="flex items-center h-full gap-2 w-full">
                            <LightTooltip
                              title={t("Retire fundos da sua conta.")}
                              TransitionComponent={Zoom}
                              placement="left"
                            >
                              <InfoOutlinedIcon
                                style={{
                                  fontSize: "large",
                                  verticalAlign: "top",
                                }}
                              />
                            </LightTooltip>
                            <div>
                              <span className=" text-2xl font-semibold">
                                {t("Realizar Saque")}
                              </span>
                            </div>
                          </div>
                        </Grid>
                        <Grid item md={5} xs={12}>
                          <div className="flex gap-5 w-full content-end">
                            <div
                              className={
                                activeButton === 2
                                  ? "noblurred blurreddiv flex flex-col p-2 gap-3 w-full"
                                  : "blurredfull blurreddiv flex flex-col p-2 gap-3 w-full"
                              }
                            >
                              <div className="flex flex-col gap-5 items-center w-full p-2">
                                <FormControl fullWidth>
                                  <InputLabel
                                    className="hidden"
                                    id="demo-simple-select-label"
                                  >
                                    {t("Tipo Chave Pix")}
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={typePix}
                                    disabled={activeButton !== 2}
                                    label={t("Chave Pix")}
                                    onChange={handleChangeTypePix}
                                  >
                                    <MenuItem value={"cpf"}>CPF</MenuItem>
                                    <MenuItem value={"cnpj"}>CNPJ</MenuItem>
                                    <MenuItem value={"email"}>E-mail</MenuItem>
                                    <MenuItem value={"phone"}>
                                      {t("Celular")}
                                    </MenuItem>
                                    <MenuItem value={"evp"}>
                                      {t("Chave Aleatória")}
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                                <TextField
                                  error={errorPixKey}
                                  helperText={helperTextPixKey}
                                  label={t("Chave Pix")}
                                  variant="outlined"
                                  value={pixKey}
                                  inputProps={{
                                    maxLength: maxLength,
                                  }}
                                  disabled={activeButton === 2 ? false : true}
                                  onChange={(e) => {
                                    if (
                                      typePix === "cpf" ||
                                      typePix === "cnpj"
                                    ) {
                                      setPixKey(mask(e.target.value));
                                    } else if (typePix === "phone") {
                                      setPixKey(maskPhone(e.target.value));
                                    } else setPixKey(e.target.value.trim());
                                  }}
                                  onBlur={validatePixKey}
                                  className="paymentComponent w-full"
                                  placeholder={
                                    typePix === "phone"
                                      ? "DDD + número (sem espaços)"
                                      : ""
                                  }
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {typePix === "phone" ? "+55" : ""}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <NumericFormat
                                  customInput={TextField}
                                  label={t("Valor (R$)")}
                                  variant="outlined"
                                  value={cashoutAmount}
                                  disabled={activeButton === 2 ? false : true}
                                  onValueChange={(values) => {
                                    const { floatValue } = values;
                                    setCashoutAmount(
                                      floatValue ? floatValue.toFixed(2) : ""
                                    );
                                  }}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  fixedDecimalScale={true}
                                  decimalScale={2}
                                  prefix={"R$ "}
                                  className="paymentComponent w-full"
                                />
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <div className="w-full h-full flex justify-center p-2 pb-3">
                            <div className="h-full flex items-end">
                              <Button
                                variant="contained"
                                color="success"
                                onClick={handleCashoutClick}
                                disabled={
                                  activeButton === 2 &&
                                  typePix &&
                                  pixKey &&
                                  cashoutAmount &&
                                  !errorPixKey
                                    ? false
                                    : true
                                }
                                className="paymentComponent paymentButton h-14 bg-green-700 icon-containercheck"
                                style={{
                                  fontSize: "25px",
                                }}
                              >
                                <FaCheck className="iconcheck" />
                              </Button>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <div className={user?.access_level === 4 ? " hidden" : ""}>
                    <div
                      ref={divTef}
                      className={
                        activeButton === 3 || activeButton === null
                          ? "ordertransition w-11/12 flex justify-around gap-10 p-5 mx-auto m-5 rounded border-2 border-iziblue"
                          : "hidden sm:block ordertransition w-11/12 justify-around gap-10 p-5 mx-auto m-5 rounded border-2 border-iziblue"
                      }
                    >
                      <Grid container spacing={2}>
                        <Grid item md={5} xs={12}>
                          <div className="flex items-center h-full gap-2 w-full">
                            <LightTooltip
                              title={t(
                                "Transfira fundos entre contas internas."
                              )}
                              TransitionComponent={Zoom}
                              placement="left"
                            >
                              <InfoOutlinedIcon
                                style={{
                                  fontSize: "large",
                                  verticalAlign: "top",
                                }}
                              />
                            </LightTooltip>
                            <div>
                              <span className=" text-2xl font-semibold">
                                TEF
                              </span>
                            </div>
                          </div>
                        </Grid>
                        <Grid item md={5} xs={12}>
                          <div className="flex gap-5 w-full content-end">
                            <div
                              className={
                                activeButton === 3
                                  ? "noblurred blurreddiv flex p-2 gap-3 w-full"
                                  : "blurredfull blurreddiv flex p-2 gap-3 w-full"
                              }
                            >
                              <div className="flex flex-col gap-5 items-center w-full p-2">
                                <TextField
                                  label={t("Conta Virtual")}
                                  variant="outlined"
                                  value={virtualAccount}
                                  disabled={activeButton !== 3}
                                  onChange={(e) =>
                                    setVirtualAccount(e.target.value.trim())
                                  }
                                  className="paymentComponent w-full"
                                />
                                <NumericFormat
                                  customInput={TextField}
                                  label={t("Valor (R$)")}
                                  variant="outlined"
                                  value={tefAmount}
                                  disabled={activeButton !== 3}
                                  onValueChange={(values) => {
                                    const { floatValue } = values;
                                    setTefAmount(
                                      floatValue ? floatValue.toFixed(2) : ""
                                    );
                                  }}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  fixedDecimalScale={true}
                                  decimalScale={2}
                                  prefix={"R$ "}
                                  className="paymentComponent w-full"
                                />
                              </div>
                            </div>
                          </div>
                        </Grid>
                        <Grid item md={2} xs={12}>
                          <div className="w-full h-full flex justify-center p-2">
                            <div className="h-full flex items-end">
                              <Button
                                variant="contained"
                                color="success"
                                disabled={
                                  activeButton === 3 &&
                                  virtualAccount &&
                                  tefAmount
                                    ? false
                                    : true
                                }
                                onClick={handleTEFConfirm}
                                className="paymentComponent paymentButton h-14 bg-green-700 icon-containercheck"
                                style={{
                                  fontSize: "25px",
                                }}
                              >
                                <FaCheck className="iconcheck" />
                              </Button>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showModal && (
          <div className="modalContainer">
            <div className="modalContent">
              <div className="flex text-center justify-end">
                <div>
                  <IconButton
                    sx={{ padding: 0 }}
                    onClick={handleCloseModal}
                    aria-label="delete"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
              <div>
                <Typography variant="h5" component="h2" className="title">
                  {t("Beneficiário")}
                  <LightTooltip
                    title={t("Verifique o PIN no seu email cadastrado.")}
                    TransitionComponent={Zoom}
                    placement="bottom"
                    arrow
                  >
                    <InfoOutlinedIcon
                      style={{ fontSize: "large", verticalAlign: "top" }}
                    />
                  </LightTooltip>
                </Typography>
              </div>

              <Typography variant="body1" component="p" className="modalText">
                {t("Nome: ")}
                {receiverData.name}
              </Typography>
              <Typography variant="body1" component="p" className="modalText">
                {t("Número da Conta: ")}
                {receiverData.accountNumber}
              </Typography>
              <Typography variant="body1" component="p" className="modalText">
                {t("Banco: ")}
                {receiverData.bankName}
              </Typography>
              <Typography variant="body1" component="p" className="modalText">
                {t("Agência: ")}
                {receiverData.branch}
              </Typography>
              <Typography variant="body1" component="p" className="modalText">
                {t("Número do Documento: ")}
                {receiverData.documentNumber}
              </Typography>
              <TextField
                inputProps={{
                  style: {
                    padding: "16px 12px",
                    borderColor: "#283650",
                  },
                }}
                sx={{ borderColor: "green" }}
                label="Pin"
                variant="outlined"
                value={pinCode}
                onChange={(e) => setPinCode(e.target.value.trim())}
                className="w-full"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      sx={{ borderColor: "green" }}
                      position="end"
                    >
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#283650",
                        }}
                        onClick={handleModalConfirm}
                        className="modalButton"
                      >
                        {t("Confirmar")}
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        )}
        {showQRCodeModal && (
          <div className="modalContainer">
            <div className="modalContent">
              <div className="flex text-center justify-end">
                <div>
                  <IconButton
                    sx={{ padding: 0 }}
                    onClick={handleCloseModal}
                    aria-label="delete"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
              <div className="qr-border">
                <div className="flex m-2 justify-center">
                  <QRCode value={qrCodeGenerated} size={220} />
                </div>
              </div>
              <div className="text-center mt-4">
                <span className="title">{t("Pague para depositar")}</span>
              </div>
              <div className=" my-2 break-words">{qrCodeGenerated}</div>
              <div className="flex justify-end">
                <button
                  onClick={handleCopyToClipboard}
                  className="flex gap-2 items-center rounded-full px-3 py-1 border-iziblue text-zinc-400 hover:shadow-lg"
                >
                  <FaRegCopy />
                  <span>{t("Copiar código PIX")}</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};

export default Payments;
