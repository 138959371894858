import { Menu, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useContext, useState } from "react";
import { DarkModeContext } from "../../context/darkModeContext";
import i18n from "i18next";
import BRFlag from "../../../public/flags/br.svg";
import USFlag from "../../../public/flags/us.svg";
import WhiteFlag from "../../../public/flags/white-flag.svg";
import BlackFlag from "../../../public/flags/black-flag.svg";
import MoneyIcon from "@mui/icons-material/PaidOutlined";
import Flag from "../flags/Flags";
import { useTranslation } from "react-i18next";

const SidebarItemMenu = ({ title, icon, itemDisabled, classes }) => {
  const { darkMode, dispatch } = useContext(DarkModeContext);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [theme, setTheme] = useState("light");
  const [language, setLanguage] = useState("pt");
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTheme = (event, newTheme) => {
    setTheme(newTheme);
    console.log("tema", theme);
  };

  const handleLanguage = (event, newLanguage) => {
    setLanguage(newLanguage);
    console.log("idioma", language);
  };

  const changeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage, (err) => {
      if (err)
        return console.log("Something went wrong loading language:", err);
      console.log("Language changed to:", newLanguage);
    });
  };

  return (
    <>
      {itemDisabled ? (
        <button disabled style={{ textDecoration: "none" }}>
          <div className={`flex opacity-50 cursor-not-allowed`}>
            <div className=" self-center">{icon}</div>
            <span
              className={
                classes +
                " hidden ismobile:block self-center mdscreen:font-semibold ml-3 text-zinc-600"
              }
            >
              {title}
            </span>
          </div>
        </button>
      ) : (
        <>
          <button onClick={handleClick} title={title}>
            <div className="flex">
              <div className="self-center">{icon}</div>
            </div>
          </button>

          {/* Menu dropdown */}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              sx: {
                backgroundColor: darkMode ? "dimgrey" : "", // Condicional ternária
                color: darkMode ? "white" : "black",
              }, // Cor de fundo personalizada
            }}
          >
            <div
              className={
                darkMode
                  ? "text-white bg-gray-700 flex-col mx-2 mb-2 rounded-lg"
                  : "text-iziblue flex-col mx-2 mb-2 rounded-lg"
              }
            >
              <div className="flex justify-center text-sm">{t("Tema")}</div>
              <ToggleButtonGroup
                value={theme}
                exclusive
                onChange={handleTheme}
                className="w-full flex justify-center"
              >
                <ToggleButton
                  value="light"
                  onClick={() => {
                    dispatch({ type: "LIGHT" });
                    setTheme("light");
                  }}
                >
                  {Flag(WhiteFlag)}
                </ToggleButton>
                <ToggleButton
                  value="dark"
                  onClick={() => {
                    dispatch({ type: "DARK" });
                    setTheme("dark");
                  }}
                >
                  {Flag(BlackFlag)}
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div
              className={
                darkMode
                  ? "text-white bg-gray-700 flex-col mx-2 mb-2 rounded-lg"
                  : "text-iziblue flex-col mx-2 mb-2 rounded-lg"
              }
            >
              <div className="flex justify-center text-sm">{t("Idioma")}</div>
              <ToggleButtonGroup
                value={language}
                exclusive
                onChange={handleLanguage}
                className="w-full flex justify-center"
              >
                <ToggleButton
                  value="en"
                  onClick={() => {
                    changeLanguage("en");
                  }}
                >
                  {Flag(USFlag)}
                </ToggleButton>
                <ToggleButton
                  value="pt"
                  onClick={() => {
                    changeLanguage("pt-BR");
                  }}
                >
                  {Flag(BRFlag)}
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div
              className={
                darkMode
                  ? "text-white bg-gray-700 flex-col mx-2 mb-2 rounded-lg"
                  : "text-iziblue flex-col mx-2 mb-2 rounded-lg"
              }
            >
              <div className="flex justify-center text-sm">
                {t("Conversor de Moeda")}
              </div>
              <a
                className="w-full flex justify-center"
                href="https://www.bcb.gov.br/en/currencyconversion"
                target="_blank"
              >
                <ToggleButton className="flex justify-center">
                  <MoneyIcon
                    className={
                      darkMode
                        ? "text-izired text-2xl"
                        : "text-iziblue text-2xl "
                    }
                  />
                </ToggleButton>
              </a>
            </div>
          </Menu>
        </>
      )}
    </>
  );
};

export default SidebarItemMenu;
