import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { useTranslation } from "react-i18next";

function TooltipTranslation({ children, title, ...props }) {
  const { t } = useTranslation();

  const translatedTitle = t(title);

  return (
    <Tooltip {...props} title={translatedTitle}>
      {children}
    </Tooltip>
   
  );

  
}

export default TooltipTranslation;
