import * as Sentry from "@sentry/react";
import axios from "axios";
import { format } from "date-fns";
import dayjs from "dayjs";
import { encrypt } from "../utils/crypto.mjs";

const baseURL = import.meta.env.VITE_API_URL;
const CACHE_TIMER = 1000 * 59;
let getStatementCache = {};
let getStatementSubCache = {};
let getStatementFeeCache = {};
let getBalanceCache = {};
let getMetricsCache = {};
let getDocumentsCache = {};
let getUsersCache = {};

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.get["Content-Type"] = "application/json";

const checkIfShouldLogout = () => {
  const LOGOUT_TIMER = 1000 * 60 * 60 * 6;
  const currentTime = Date.now();
  const lastlog = sessionStorage.getItem("last_login");
  if (currentTime - lastlog > LOGOUT_TIMER) {
    sessionStorage.clear();
    window.location.reload();
  }
};
async function getStatement(
  page = 1,
  limit = 100,
  filters = {},
  token = sessionStorage.getItem("access_token")
) {
  try {
    const cacheKey = JSON.stringify({ page, limit, filters });
    const now = Date.now();

    if (
      getStatementCache[cacheKey] &&
      now - getStatementCache[cacheKey].time < CACHE_TIMER
    ) {
      return getStatementCache[cacheKey].data;
    }

    const params = {
      page,
      limit,
      ...filters,
    };

    const response = await axios.get(`${baseURL}/transactions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    });

    if (response.status !== 200) {
      throw new Error("Request failed");
    }
    // Store the result in cache only if response.data.length is not null
    if (response?.data?.transactions?.length) {
      getStatementCache[cacheKey] = {
        time: now,
        data: response.data,
      };
    }

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function getStatementSub(
  allFilters = {},
  token = sessionStorage.getItem("access_token")
) {
  try {
    const cacheKey = JSON.stringify({ allFilters });
    const now = Date.now();

    if (
      getStatementSubCache[cacheKey] &&
      now - getStatementSubCache[cacheKey].time < CACHE_TIMER
    ) {
      return getStatementSubCache[cacheKey].data;
    }
    const subToken = allFilters?.subToken;
    const filters = allFilters?.filter;
    const params = {
      subToken,
      filters,
    };

    const response = await axios.post(`${baseURL}/transactionssub`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status !== 200) {
      throw new Error("Request failed");
    }
    // Store the result in cache only if response.data.length is not null
    if (response?.data?.transactions?.length) {
      getStatementSubCache[cacheKey] = {
        time: now,
        data: response.data,
      };
    }

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function getStatementFees(
  page = 1,
  limit = 100,
  filters = {},
  token = sessionStorage.getItem("access_token")
) {
  try {
    const cacheKey = JSON.stringify({ page, limit, filters });
    const now = Date.now();

    if (
      getStatementFeeCache[cacheKey] &&
      now - getStatementFeeCache[cacheKey].time < CACHE_TIMER
    ) {
      return getStatementFeeCache[cacheKey].data;
    }

    const params = {
      page,
      limit,
      ...filters,
    };

    const response = await axios.get(`${baseURL}/transactions/fees`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    });

    if (response.status !== 200) {
      throw new Error("Request failed");
    }
    // Store the result in cache only if response.data.length is not null
    if (response?.data?.transactions?.length) {
      getStatementFeeCache[cacheKey] = {
        time: now,
        data: response.data,
      };
    }

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function getBalance(token = sessionStorage.getItem("access_token")) {
  try {
    const cacheKey = "balance";
    const now = Date.now();

    if (
      getBalanceCache[cacheKey] &&
      now - getBalanceCache[cacheKey].time < CACHE_TIMER
    ) {
      return getBalanceCache[cacheKey].data;
    }

    const response = await axios.get(`${baseURL}/balance`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status !== 200) {
      throw new Error("Request failed");
    }

    getBalanceCache[cacheKey] = {
      time: now,
      data: response.data,
    };

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function getUsers(
  page = 1,
  limit = 100,
  filters = {},
  token = sessionStorage.getItem("access_token")
) {
  try {
    const cacheKey = JSON.stringify({ page, limit, filters });
    const now = Date.now();
    if (
      getUsersCache[cacheKey] &&
      now - getUsersCache[cacheKey].time < CACHE_TIMER
    ) {
      return getUsersCache[cacheKey].data;
    }

    const params = {
      page,
      limit,
      ...filters,
    };

    const response = await axios.get(`${baseURL}/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    });
    if (response.status !== 200) {
      throw new Error("Request failed");
    }
    getUsersCache[cacheKey] = {
      time: now,
      data: response.data,
    };
    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function getAllDocuments(
  user_id,
  token = sessionStorage.getItem("access_token")
) {
  try {
    const cacheKey = "documents";
    const now = Date.now();
    /* if (
      getDocumentsCache[cacheKey] &&
      now - getDocumentsCache[cacheKey].time < CACHE_TIMER
    ) {
      return getDocumentsCache[cacheKey].data;
    } */
    const params = {
      user_id: user_id,
    };
    const response = await axios.get(
      `${baseURL}/documents`,
      /* data, */ {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      }
    );
    if (response.status !== 200) {
      throw new Error("Request failed");
    }
    getDocumentsCache[cacheKey] = {
      time: now,
      data: response.data,
    };
    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function downloadCSV(
  date,
  token = sessionStorage.getItem("access_token")
) {
  try {
    const response = await axios.get(
      `${baseURL}/transactions/csv?date=${date}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          // 'Accept-Encoding': 'gzip, deflate, br'
        },
        // responseType: 'arraybuffer',  // Expecting an array buffer instead of text
      }
    );

    if (response.status !== 200) {
      throw new Error("Request failed");
    }

    return response?.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function forceUpdateMetrics(
  token = sessionStorage.getItem("access_token")
) {
  try {
    const cacheKey = "metrics";
    const now = Date.now();

    if (
      getMetricsCache[cacheKey] &&
      now - getMetricsCache[cacheKey].time < CACHE_TIMER * 15
    ) {
      return getMetricsCache[cacheKey].data;
    }

    const response = await axios.post(
      `${baseURL}/metrics`,
      {
        date: `${format(now, "yyyy-MM-dd")}`,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status !== 200 && response.status !== 204) {
      throw new Error("Request failed");
    }

    getMetricsCache[cacheKey] = {
      time: now,
      data: response.data,
    };

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function createAccount(
  formData,
  token = sessionStorage.getItem("access_token")
) {
  try {
    console.log(formData);
    const nameKey =
      formData.person_type === "Pessoa Física" ? "name" : "company_name";
    const cpfKey = formData.person_type === "Pessoa Física" ? "cpf" : "cnpj";
    const personType =
      formData.person_type === "Default"
        ? "Pessoa Jurídica"
        : formData.person_type;
    const payload = {
      user: formData.user,
      password: formData.password,
      description: formData.description,
      integration: formData.baas,
      confirm_payment_webhook_url: formData.webhookUrl,
      user_info: {
        person_type: personType,
        address: formData.address,
        cep: formData.cep,
        district: formData.district,
        city: formData.city,
        state: formData.state,
        telephone: formData.telephone,
      },
      bank_info: {
        bank: formData.bank,
        bank_cc: formData.bank_cc,
        bank_ag: formData.bank_ag,
        account_type: formData.account_type,
      },
      fees: formData.fees,
    };
    payload.user_info[nameKey] = formData.name;
    payload.user_info[cpfKey] = formData.cpf_cnpj;
    console.log(payload);
    const response = await axios.post(`${baseURL}/account`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    if (response.status !== 200 && response.status !== 204) {
      throw new Error("Request failed");
    }
    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function getMetrics(
  status = null,
  movementType = null,
  date = null,
  fromDate = null,
  toDate = null,
  token = sessionStorage.getItem("access_token")
) {
  checkIfShouldLogout();
  try {
    const cacheKey = "metrics";
    const now = Date.now();
    const today = date || format(now, "yyyy-MM-dd");
    let url = `${baseURL}/metrics?`;

    if (fromDate && toDate) {
      url += `&fromdate=${fromDate}&todate=${toDate}`;
    } else {
      url += `date=${today}`;
    }

    if (status) {
      url += `&status=${status}`;
    }
    if (movementType) {
      url += `&movementType=${movementType}`;
    }
    if (
      getMetricsCache[cacheKey] &&
      now - getMetricsCache[cacheKey].time < CACHE_TIMER
    ) {
      return getMetricsCache[cacheKey].data;
    }

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status !== 200) {
      throw new Error("Request failed");
    }

    getMetricsCache[cacheKey] = {
      time: today,
      data: response.data,
    };

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function getSubMetrics(
  status = null,
  movementType = null,
  date = null,
  fromDate = null,
  toDate = null,
  subToken,
  token = sessionStorage.getItem("access_token")
) {
  try {
    if (!subToken) throw new Error("No sub account setted.");
    const cacheKey = "submetrics";
    const now = Date.now();
    // checkIfShouldLogout();
    if (
      getMetricsCache[cacheKey] &&
      now - getMetricsCache[cacheKey].time < CACHE_TIMER
    ) {
      return getMetricsCache[cacheKey].data;
    }
    const data = {
      status: status,
      movementType: movementType,
      date: date,
      fromdate: fromDate,
      todate: toDate,
      subToken: subToken,
    };
    const today = date || format(now, "yyyy-MM-dd");
    const response = await axios.post(`${baseURL}/submetrics`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      throw new Error("Request failed");
    }

    getMetricsCache[cacheKey] = {
      time: today,
      data: response.data,
    };

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function login(username, password, pinCode = "") {
  try {
    const body = {
      email: username,
      password: password,
      token: pinCode,
    };

    // Fazer a requisição de login e receber o token JWT
    const response = await axios.post(`${baseURL}/login`, body);
    if (response.status !== 200) {
      console.log("API Response:", response);
      throw new Error("Request failed");
    }

    // Salvar o token JWT no sessionStorage e setar o contexto
    sessionStorage.setItem("last_login", Date.now());
    if (response?.data?.username) {
      sessionStorage.setItem("username", encrypt(response?.data?.username));
    }
    return { ...response.data, statusCode: response.status };
  } catch (error) {
    console.error("Error during login request:", error);
    sessionStorage.clear();
    return {
      error: error.response?.data.error || "SOMETHING_WENT_WRONG",
      message: error.response?.data.message || "Something went wrong",
      statusCode: error.response?.status || 500,
    };
  }
}

async function authenticate(token = sessionStorage.getItem("access_token")) {
  try {
    const response = await axios.post(
      `${baseURL}/auth`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Request failed");
    }
    return response.data;
  } catch (error) {
    console.error(error);
    sessionStorage.clear();
    throw new Error("Something went wrong");
  }
}

async function updateUserInfo(
  dataToChange,
  token = sessionStorage.getItem("access_token")
) {
  try {
    const data = {
      ...dataToChange,
    };

    const response = await axios.put(`${baseURL}/user`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (response.status !== 200) {
      throw new Error("Request failed");
    }
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Something went wrong");
  }
}

async function updateClientInfo(
  dataToChange,
  token = sessionStorage.getItem("access_token")
) {
  try {
    const data = {
      ...dataToChange,
    };

    const response = await axios.put(`${baseURL}/updateclient`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (response.status !== 200) {
      throw new Error("Request failed");
    }
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Something went wrong");
  }
}

async function updateStatus(
  status,
  user_id,
  token = sessionStorage.getItem("access_token")
) {
  try {
    const data = {
      status: status,
      user_id: user_id,
    };

    const response = await axios.put(`${baseURL}/updatestatus`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      console.log(response);
      throw new Error("Request failed");
    }

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function simulateWithdraw(
  amount,
  pixKey,
  typePix,
  statusCashout,
  token = sessionStorage.getItem("access_token")
) {
  try {
    const data = {
      amount: amount * 100,
      key: pixKey,
      type: typePix,
      external_reference: "simulated withdraw through dashboard",
      status: statusCashout,
    };

    const response = await axios.post(`${baseURL}/webhook/cashoutsend`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      console.log(response);
      throw new Error("Request failed");
    }

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function withdraw(
  pinCode,
  amount,
  pixKey,
  typePix,
  token = sessionStorage.getItem("access_token")
) {
  try {
    const data = {
      pin_code: pinCode,
      amount: amount * 100,
      key: pixKey,
      type: typePix,
      external_reference: "manual withdraw through dashboard",
    };

    const response = await axios.post(`${baseURL}/withdraw`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      console.log(response);
      throw new Error("Request failed");
    }

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function requestWithdraw(
  amount,
  pixKey,
  typePix,
  token = sessionStorage.getItem("access_token")
) {
  try {
    const data = {
      amount: amount * 100, // convert to cents
      pix_key: pixKey,
      pix_type: typePix,
      order_id: "request withdraw through dashboard",
    };

    const response = await axios.post(`${baseURL}/request-withdraw`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      throw new Error("Request failed");
    }

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function tef(
  amount,
  virtualAccount,
  token = sessionStorage.getItem("access_token")
) {
  try {
    const data = {
      amount: amount * 100, // convert to cents
      virtual_account: virtualAccount,
      order_id: "manual TEF through dasboard",
    };

    const response = await axios.post(`${baseURL}/tef`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      throw new Error(response?.message || "Request failed");
    }

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error(error?.message || "Something went wrong");
  }
}

async function refund(
  transactionId,
  endToEndId,
  token = sessionStorage.getItem("access_token")
) {
  try {
    const data = {
      transaction_id: transactionId,
      end_to_end_id: endToEndId,
    };
    console.log("REFUND EM IMPLEMENTAÇÃO", data);

    // const response = await axios.post(`${baseURL}/refund`, data, {
    //   headers: {
    // Authorization: `Bearer ${token}`,
    //     "Content-Type": "application/json",
    //   },
    // });

    // if (response.status !== 200) {
    //   throw new Error(response?.message || "Refund request failed");
    // }

    // return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error(error?.message || "Something went wrong");
  }
}

async function generateCashIn(
  amount,
  cpf_cnpj,
  name,
  email,
  token = sessionStorage.getItem("access_token")
) {
  try {
    const dateNow = dayjs().format("YYYY-MM-DD");
    const data = {
      amount: amount * 100, // convert to cents
      due_date: dateNow,
      external_reference: "cash-in-dash",
      payer: {
        cpf_cnpj: cpf_cnpj,
        name: name,
        email: email,
      },
    };
    let response;
    response = await axios.post(`${baseURL}/qr-code/generate`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    // if (
    //   decrypt(sessionStorage.getItem("username")) === "fees@admin" ||
    //   decrypt(sessionStorage.getItem("username")) === "dashboard@xgood3"
    // ) {
    //   const responseToken = await axios.post(
    //     `https://api.izipaynow.com/v3/login`,
    //     {
    //       email: decrypt(sessionStorage.getItem("username")),
    //       password: decrypt(sessionStorage.getItem("password")),
    //     },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   );
    //   response = await axios.post(
    //     `https://api.izipaynow.com/v3/qr-code/generate`,
    //     data,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${responseToken.data.access_token}`,
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   );
    // } else if (
    //   decrypt(sessionStorage.getItem("username")) === "dashboard@realpix2"
    // ) {
    //   const responseToken = await axios.post(
    //     `https://api.izipaynow.com/formosa/login`,
    //     {
    //       email: decrypt(sessionStorage.getItem("username")),
    //       password: decrypt(sessionStorage.getItem("password")),
    //     },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   );
    //   response = await axios.post(
    //     `https://api.izipaynow.com/formosa/qr-code/generate`,
    //     data,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${responseToken.data.access_token}`,
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   );
    // } else {

    // }

    if (response.status !== 200) {
      throw new Error("Request failed");
    }

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function simulateCashIn(
  amount,
  cpf_cnpj,
  name,
  email,
  statusCashin,
  token = sessionStorage.getItem("access_token")
) {
  try {
    const dateNow = dayjs().format("YYYY-MM-DD");
    const data = {
      amount: amount * 100, // convert to cents
      due_date: dateNow,
      external_reference: "simulate cash-in-dash",
      status: statusCashin,
      payer: {
        cpf_cnpj: cpf_cnpj,
        name: name,
        email: email,
      },
    };
    const response = await axios.post(`${baseURL}/webhook/cashinsend`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status !== 200) {
      console.log(response);
      throw new Error("Request failed");
    }

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function getTransactionsApproval(
  page = 1,
  limit = 100,
  filters = {},
  token = sessionStorage.getItem("access_token")
) {
  try {
    const params = {
      page,
      limit,
      ...filters,
    };
    const response = await axios.get(`${baseURL}/transactions-approval`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    });

    if (response.status !== 200) {
      throw new Error("Request failed");
    }
    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

async function updateTransactionsApproval(
  user_id,
  id,
  amount,
  status,
  original_request,
  token = sessionStorage.getItem("access_token")
) {
  try {
    const data = {
      user_id,
      id,
      amount,
      status,
      original_request,
    };
    const response = await axios.post(
      `${baseURL}/transactions-approval`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status !== 200) {
      console.log(response);
      throw new Error("Request failed");
    }

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error("Something went wrong");
  }
}

export {
  authenticate,
  createAccount,
  downloadCSV,
  forceUpdateMetrics,
  generateCashIn,
  getAllDocuments,
  getBalance,
  getMetrics,
  getStatement,
  getStatementFees,
  getStatementSub,
  getSubMetrics,
  getTransactionsApproval,
  getUsers,
  login,
  refund,
  requestWithdraw,
  simulateCashIn,
  simulateWithdraw,
  tef,
  updateClientInfo,
  updateStatus,
  updateTransactionsApproval,
  updateUserInfo,
  withdraw,
};
