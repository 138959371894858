import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Slide,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { CheckCircleOutlineTwoTone } from "@mui/icons-material";
import { refund } from "../../services/TransactionsService";
import { formatDate } from "../../utils/format";

const formatCurrency = (value) => {
  const formattedValue = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);

  return formattedValue;
};

export const RefundModal = ({ row, onClose }) => {
  const [openLoader, setOpenLoader] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [password, setPassword] = useState("");
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const { t } = useTranslation();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
  };
  const styleMobile = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    padding: 2,
  };

  const handleShowAlert = (type, title, text) => {
    setAlertContent({
      type: type,
      title: title,
      text: text,
    });
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  };

  const transactionId = row.transaction_id;
  const endToEndId = row.end_to_end_id;

  const handleRefundConfirm = async () => {
    setSuccess(false);
    setOpenLoader(true);
    try {
      await refund(transactionId, endToEndId);
      setSuccess(true);
      handleShowAlert(
        "success",
        t("Solicitar Reembolso"),
        t("Solicitação de reembolso realizada!")
      );
    } catch (error) {
      setError(true);
      console.error("An error occurred:", error);
      handleShowAlert(
        "error",
        t("Solicitar Reembolso"),
        t(error.message) || t("An error occurred during refund.")
      );
      setTimeout(() => {
        setOpenLoader(false);
        setError(false);
      }, 4000);
    } finally {
      setTimeout(() => {
        setOpenLoader(false);
        onClose();
      }, 4000);
    }
  };

  return (
    <>
      <Box
        className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-10"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
      >
        <Slide direction="up" in={showAlert} mountOnEnter unmountOnExit>
          <Alert variant="filled" severity={alertContent?.type}>
            <AlertTitle>{alertContent?.title}</AlertTitle>
            {alertContent?.text}
          </Alert>
        </Slide>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        {success ? (
          <CheckCircleOutlineTwoTone sx={{ fontSize: 60, color: "#4caf50" }} />
        ) : error ? (
          <CancelIcon sx={{ fontSize: 60, color: "#fb0000" }} />
        ) : (
          <CircularProgress color="inherit" />
        )}
      </Backdrop>

      <Box sx={isMobile ? styleMobile : style}>
        <div
          className={
            isMobile
              ? "flex-col-reverse gap flex items-center"
              : "flex-row flex gap-3 border-2 w-full p-0"
          }
        >
          <div className={isMobile ? "w-full pb-2 border-b" : "w-full p-4"}>
            <div
              className={
                isMobile
                  ? "flex h-full mb-2 w-full flex-col justify-center items-center"
                  : "flex h-full w-full flex-col justify-center items-center"
              }
            >
              <div className="flex justify-end w-full">
                <div>
                  <IconButton
                    sx={{ padding: 0 }}
                    onClick={onClose}
                    aria-label="delete"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
              <Grid container justifyContent={"center"} alignItems={"center"}>
                <Grid item>
                  <div className="mb-5 text-[#283650] text-2xl font-bold">
                    <Typography
                      variant="h5"
                      className="mb-5 text-[#283650] text-2xl font-bold"
                    >
                      {t("Solicitar Reembolso")}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Box className="flex gap-1 justify-center items-center">
                    <span className="text-sm">{t("Valor total: ")}</span>
                    <span
                      className={
                        isMobile
                          ? "text-sm font-semibold"
                          : "text-medium font-semibold"
                      }
                    >
                      {formatCurrency(row.amount)}
                    </span>
                  </Box>
                  <Box className="flex gap-1 justify-center items-center">
                    <span className="text-sm">{t("Data: ")}</span>
                    <span
                      className={
                        isMobile
                          ? "text-sm font-semibold"
                          : "text-medium font-semibold"
                      }
                    >
                      {formatDate(row.last_updated_at, "complete-at")}
                    </span>
                  </Box>
                  <Box className="flex gap-1 justify-center items-center">
                    <span className="text-sm">{t("ID Ordem")}:</span>
                    <span className={"text-sm"}>{row.order_id}</span>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="flex gap-1 justify-center items-center">
                    <Typography
                      variant="body1"
                      component="p"
                      className="modalText py-4"
                    >
                      {t("Confirme sua senha para solicitar o reembolso:")}
                    </Typography>
                  </Box>
                  <Box className="w-full flex justify-center items-center border-2">
                    <TextField
                      inputProps={{
                        style: {
                          padding: "16px 12px",
                          borderColor: "#283650",
                        },
                      }}
                      sx={{ borderColor: "green" }}
                      label={t("Confirme sua senha")}
                      type="password"
                      variant="outlined"
                      value={password}
                      onChange={(e) => setPassword(e.target.value.trim())}
                      className="w-full"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            sx={{ borderColor: "green" }}
                            position="end"
                          >
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: "#283650",
                              }}
                              className="modalButton"
                              onClick={handleRefundConfirm}
                              isDisabled={!password}
                            >
                              {t("Confirmar")}
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};
