import dayjs from "dayjs";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { QueryClient, QueryClientProvider } from "react-query";
import { useMediaQuery } from "react-responsive";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./home.scss";

const queryClient = new QueryClient();

const Home = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [maxDate, setMaxDate] = useState(dayjs());
  const [filterData, setFilterData] = useState({
    startDate: dayjs().format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    if (
      startDate &&
      endDate &&
      startDate.isBefore(dayjs()) &&
      endDate.isBefore(dayjs())
    ) {
      setFilterData({
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
      });
    }
  }, [startDate, endDate]);

  const handleMaxDate = (date) => {
    const daysAfter = date.add(7, "day");

    if (daysAfter.isAfter(dayjs())) {
      return dayjs();
    }

    return daysAfter;
  };

  if (isMobile) {
    import("./mobileHome.scss");
  } else {
    import("./home.scss");
  }

  // Dynamically import the table based on the device type
  const MetricCharts = lazy(() =>
    isMobile
      ? import("../../components/metricCharts/MetricCharts")
      : import("../../components/metricCharts/MetricCharts")
  );

  const Widget = lazy(() =>
    isMobile
      ? import("../../components/widget/WidgetMobile")
      : import("../../components/widget/Widget")
  );

  const { t, i18n } = useTranslation();

  return (
    <div className="home">
      <Sidebar />
      <div className=" w-10/12 sm:w-full">
        <QueryClientProvider client={queryClient}>
          <Navbar onFilterData />
        </QueryClientProvider>
        <div className="w-full">
          <div className="h-[calc(100%-112px)]">
            <div className="h-full">
              <div className=" h-2/3">
                <Suspense fallback={<div>Loading...</div>}>
                  <Widget />
                </Suspense>
              </div>
              <div className="flex justify-between sm:p-0 sm:m-0 h-1/3">
                <div className="w-1/2 h-full border-r-2 border-b-2 border-zinc-300 pt-5">
                  <div className=" font-semibold mb-1 sm:mb-4 flex justify-center items-center text-center text-xl">
                    {t("Transações por hora")}
                  </div>
                  <QueryClientProvider client={queryClient}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <MetricCharts graphicToDisplay={"transactionsPerHour"} />
                    </Suspense>
                  </QueryClientProvider>
                </div>
                <div className="w-1/2 h-full border-r-2 border-b-2 border-zinc-300 pt-5">
                  <div className=" font-semibold mb-1 sm:mb-4 flex justify-center items-center text-center text-xl">
                    {t("Volume semanal transacionado")}
                  </div>
                  <QueryClientProvider client={queryClient}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <MetricCharts graphicToDisplay={"weekMetrics"} />
                    </Suspense>
                  </QueryClientProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
