import {
    Alert,
    Box,
    Button,
    Grid,
    Modal,
    TextField,
    Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/loggedUserContext";
import { login } from "../../services/TransactionsService";
const QrCodeModal = ({
  open,
  onClose,
  qrCodeValue = "",
  username,
  password,
  openModalFirstLogin,
  modalActive,
}) => {
  const [pinCode, setPinCode] = useState(""); // Código PIN estático
  const { setUser } = useContext(UserContext);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  // Funções de manipulação estáticas
  const handlePinChange = (e) => {
    setPinCode(e.target.value);
  };
  const handleTokenVerification = async () => {
    const response = await login(username.trim(), password.trim(), pinCode);
    console.log("LOG DO RESPONSE", response);
    if (response.statusCode !== 200) {
      setError(response.message);
      return;
    }
    sessionStorage.setItem("access_token", response.access_token);

    if (response.first_login) {
      setUser(response);
      modalActive(false);
      openModalFirstLogin(true);
    } else {
      setUser(response);
      switch (response.access_level) {
        case 2:
          navigate("/transactions", { replace: true });
          break;
        case 3:
        case 4:
          navigate("/payments", { replace: true });
          break;
        default:
          navigate("/", { replace: true });
          break;
      }
    }
    console.log("Pin Code Digitado", pinCode);
  };

  if (!open) return null;
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bgcolor="white"
        p={{ xs: 2, sm: 4, md: 6, lg: 10 }}
        boxShadow={1}
        borderRadius={4}
        width={{ xs: "90%", sm: "70%", md: "60%", lg: "800px" }}
        height={{ xs: "auto", sm: "auto", md: "600px", lg: "650px" }}
        position="absolute"
        top="50%"
        left="50%"
        sx={{
          transform: "translate(-50%, -50%)",
        }}
      >
        {error && (
          <Alert severity="error" onClose={() => setError(null)} 
          sx={{
            position: "fixed",
            top: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1300,
            width: "40%",
            maxWidth: "600px",
          }}>
            {error}
          </Alert>
        )}
        {qrCodeValue && (
          <>
            <QRCode value={qrCodeValue} size={256} />
            <Typography variant="body1" align="center" my={2}>
              Aponte a câmera do aplicativo autenticador para o QR Code e digite
              o código que aparecer no campo de texto abaixo do QR Code.
            </Typography>
          </>
        )}
        {!qrCodeValue && (
          <Grid container rowGap={2}>
            <Grid item md={12}>
              <Typography
                variant="h5"
                align="center"
                className="font-bold"
                noWrap={true}
              >
                Dupla Autenticação
              </Typography>
            </Grid>
            <Grid item md={12} mb={"16px"}>
              <Typography variant="body1" align="center" noWrap={false}>
                Digite o token de autenticação gerado pelo aplicativo cadastrado
                na plataforma.
              </Typography>
            </Grid>
          </Grid>
        )}
        <TextField
          variant="outlined"
          placeholder="000000"
          value={pinCode}
          onChange={handlePinChange}
          inputProps={{
            maxLength: 6,
            inputMode: "numeric",
            pattern: "[0-9]*",
            style: {
              textAlign: "center",
              letterSpacing: "0.5em",
              border: "none",
              outline: "none",
              fontSize: "2em",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
            },
          }}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={pinCode.length !== 6}
          sx={{ mt: 4 }}
          onClick={handleTokenVerification}
        >
          Confirmar
        </Button>
      </Box>
    </Modal>
  );
};

export default QrCodeModal;
