import { Alert, Slide } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import i18n from "i18next";
import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import DrawSVG from "react-svg-drawing";
import ReactTyped from "react-typed";
import BRFlag from "../../../public/flags/br.svg";
import USFlag from "../../../public/flags/us.svg";
import { UserContext } from "../../context/loggedUserContext";
import { login } from "../../services/TransactionsService";
import { httpDecrypt } from "../../utils/httpEncrypt";
import FirstLoginModal from "../firstLoginModal/FirstLoginModal";
import Flag from "../flags/Flags";
import QrCodeModal from "../qrCodeModal/QrCodeModal";
import "./loginexb.scss";
const LoginScreen = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const userref = useRef(null);
  const passwordref = useRef(null);
  const [type, setType] = useState("password");
  const [isActive, setIsActive] = useState(false);
  const [open, setOpen] = useState(false);
  const { setUser } = useContext(UserContext);
  const { t } = useTranslation();
  const [openModalQrCode, setOpenModalQrCode] = useState(false);
  const [qrCodeValue, setQrCodeValue] = useState("");
  const [changePassword, setChangePassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState(null);
  const [openModalFirstLogin, setOpenModalFirstLogin] = useState(false);
  const changeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage, (err) => {
      if (err)
        return console.log("Something went wrong loading language:", err);
      console.log("Language changed to:", newLanguage);
    });
  };

  //Password Visibility
  const handleToggle = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };
  const handleShowAlert = (type, text) => {
    setAlertContent({ type, text });
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  };
  const clearPasswordInput = () => {
    setChangePassword("");
    if (passwordref.current) {
      passwordref.current.value = "";
    }
    handleShowAlert("success", "Senha alterada com sucesso!");
  };
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      if (username.trim().length === 0) {
        if (userref.current) {
          userref.current.focus();
          return;
        }
      }

      if (password.trim().length === 0) {
        if (passwordref.current) {
          passwordref.current.focus();
          return;
        }
      }

      setOpen(true);
      const response = await login(username.trim(), password.trim());
      if (response && !response?.error && response?.statusCode === 200) {
        if (response.authentication) {
          if (response?.qrCode) {
            const generateQrCode = httpDecrypt(response.qrCode);
            setQrCodeValue(generateQrCode);
          }
          
          setOpenModalQrCode(true);
          // setar o contexto depois de receber o access token
          // setUser(response);
          setOpen(false);
        } else {
          localStorage.clear();
          setError("Invalid username or password");
        }
      } else if (response.error || response.statusCode !== 200) {
        // If there's an error with the request, show an error
        setError(t("Login Inválido."));
        setTimeout(() => {
          setError("");
        }, 4000);
        setOpen(false);
        // If login failed, show an error
        localStorage.clear();
        sessionStorage.clear();
        /* window.location.reload(); */ // Refresh the page if needed
      }
    } catch (err) {
      // If there's an error with the request, show an error
      setError(t("Login Inválido."));
      setTimeout(() => {
        setError("");
      }, 4000);
      setOpen(false);
      // If login failed, show an error
      localStorage.clear();
      sessionStorage.clear();
      /* window.location.reload(); */ // Refresh the page if needed
    }
  };

  // Input Border Anmiation
  const [isFocuseduser, setIsFocuseduser] = useState(false);
  const [isFocusedpassword, setIsFocusedpassword] = useState(false);
  const handleFocususer = () => {
    setTimeout(() => {
      setIsFocuseduser(true);
    }, 301);
  };
  const handleBluruser = (event) => {
    event.preventDefault();

    if (username.trim().length !== 0) {
      // console.log('input value is NOT empty');
    } else {
      setIsFocuseduser(false);
    }
  };
  const handleFocuspassword = () => {
    setTimeout(() => {
      setIsFocusedpassword(true);
    }, 301);
  };
  const handleBlurpassword = (event) => {
    event.preventDefault();

    if (password.trim().length !== 0) {
      // console.log('input value is NOT empty');
    } else {
      setIsFocusedpassword(false);
    }
  };

  return (
    <div className="h-screen flex content-center">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="w-8/12 hidden sm:flex self-center bgimage h-full ">
        <div className="flex flex-center flex-col self-center mx-auto w-full h-full pt-16 pl-16 pb-16 relative">
          <div className="h-full flex flex-col justify-center -mt-4">
            <ReactTyped
              className="text-6xl font-bold text-white"
              loop
              typeSpeed={100}
              backSpeed={60}
              strings={[
                t("Inovadora"),
                t("Eficiente"),
                t("Segura"),
                t("Integrada"),
              ]}
              smartBackspace
              shuffle={false}
              backDelay={1500}
              fadeOut={false}
              fadeOutDelay={100}
              loopCount={0}
              showCursor
              cursorChar="|"
            />
            <span className="flex w-8/12 mt-6 font-semibold text-2xl leading-normal text-white">
              {t(
                "Com segurança de ponta e uma abordagem centrada no usuário, capacitamos você a conduzir seus negócios de forma ágil e confiável. Descubra a evolução dos pagamentos conosco, onde a tecnologia encontra a praticidade."
              )}
            </span>
          </div>
          <div className="absolute bottom-5">
            {/* <a href="https://www.izipaynow.com" target="_blank" className="flex w-8/12 font-semibold leading-normal text-white text-nowrap">
                            www.izipaynow.com
                        </a> */}
            <a
              href="https://www.instagram.com/izipay_now/"
              target="_blank"
              className="flex w-8/12 font-semibold leading-normal text-white text-nowrap"
            >
              @Izipay_now
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5551981899939&text=Ol%C3%A1,%20vim%20atrav%C3%A9s%20do%20site%20e%20quero%20saber%20mais%20sobre%20a%20Izipay%20Now"
              className="flex w-8/12 font-semibold leading-normal text-white text-nowrap"
              target="_blank"
            >
              +55 51 98189-9939
            </a>
          </div>

          {/* <div>
                        <ReactTyped
                            strings={["Uma fintech inovadora", "Uma fintech eficiente", "Uma fintech segura", "Uma fintech integrada"]}
                            typeSpeed={50}
                            backSpeed={50}
                            backDelay={1}
                            loop
                            smartBackspace
                        />
                    </div> */}
        </div>
      </div>
      <div className="w-full sm:w-4/12 flex flex-col self-center h-full my-8">
        {/* {error && } */}
        <div className="flex flex-row xl:flex-row ml-auto">
          <div className="mr-2">
            <Button
              endIcon={Flag(USFlag)}
              onClick={() => changeLanguage("en")}
              className="language-button"
            />
          </div>
          <div className="mr-2">
            <Button
              endIcon={Flag(BRFlag)}
              onClick={() => changeLanguage("pt-BR")}
              className="language-button"
            />
          </div>
        </div>

        <div className="w-10/12 sm:w-7/12 mx-auto mt-24 sm:mt-40">
          <div className="flex justify-center mb-20">
            <div className="flex relative items-center mirrored">
              <div className="redz">
                <DrawSVG
                  width="100px"
                  strokeWidth="75"
                  stroke="#e94633"
                  duration="3000"
                  easing="easeInQuad"
                >
                  <svg viewBox="363 1015 2246 1840">
                    <linearGradient id="animationred">
                      <stop offset="0" stopColor="#e94633">
                        <animate
                          dur="1.5s"
                          attributeName="offset"
                          fill="freeze"
                          begin="2s"
                          from="0"
                          to="1"
                        />
                      </stop>
                      <stop offset="0" stopColor="#fff">
                        <animate
                          dur="1.5s"
                          attributeName="offset"
                          fill="freeze"
                          begin="2s"
                          from="0"
                          to="1"
                        />
                      </stop>
                    </linearGradient>
                    <path
                      fill="url(#animationred)"
                      d="M867 2817 c42 -68 227 -316 239 -321 9 -3 153 -6 320 -6 l305 0 -14 -20 c-8 -12 -66 -76 -129 -143 -63 -67 -213 -228 -333 -357 -120 -129 -252 -271 -294 -315 -67 -70 -223 -236 -442 -470 -36 -38 -81 -87 -99 -107 l-32 -38 234 0 234 0 144 148 c79 81 194 199 255 262 196 203 725 751 840 870 182 188 329 342 340 355 6 6 41 44 80 83 l69 72 -862 0 c-705 0 -862 -2 -855 -13z"
                    />
                  </svg>
                </DrawSVG>
              </div>
              <div className="bluez">
                <DrawSVG
                  width="100px"
                  strokeWidth="75"
                  stroke="#183350"
                  duration="3000"
                  easing="easeInQuad"
                >
                  <svg viewBox="985 1008 2225 1848">
                    <linearGradient id="animationblue">
                      <stop offset="0" stopColor="#183350">
                        <animate
                          dur="1.5s"
                          attributeName="offset"
                          fill="freeze"
                          begin="2s"
                          from="0"
                          to="1"
                        />
                      </stop>
                      <stop offset="0" stopColor="#fff">
                        <animate
                          dur="1.5s"
                          attributeName="offset"
                          fill="freeze"
                          begin="2s"
                          from="0"
                          to="1"
                        />
                      </stop>
                    </linearGradient>
                    <path
                      fill="url(#animationblue)"
                      d="M2604 2687 c-76 -79 -231 -240 -344 -357 -113 -118 -230 -239 -260 -270 -30 -31 -122 -126 -205 -211 -82 -85 -233 -240 -335 -344 -253 -260 -412 -426 -433 -451 l-17 -21 830 1 c456 1 830 4 830 8 0 8 -79 148 -95 168 -7 8 -25 40 -41 70 -16 30 -31 57 -35 60 -3 3 -8 13 -12 22 -6 16 -31 17 -289 17 -156 0 -300 0 -320 1 -22 0 -38 5 -38 11 0 18 145 176 366 400 74 75 134 138 134 140 0 3 74 81 165 174 91 94 228 237 303 318 75 82 192 203 259 269 67 67 120 124 118 128 -3 4 -103 8 -223 9 l-218 2 -140 -144z"
                    />
                  </svg>
                </DrawSVG>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <h1 className="text-left text-3xl flex justify-center font-semibold">
              {" "}
              {t("Fazer login")}
            </h1>
          </div>
          {/* <div className="flex gap-8 border-b border-gray-light">
                        <a className="font-semibold py-2 border-b-2 border-transparent hover:border-gray-main" href="">Produção</a>
                        <a className="font-semibold py-2 border-b-2 border-transparent hover:border-gray-main" href="">Sandbox</a>
                    </div> */}
          <div className="error font-semibold text-base">{error}</div>
          <form
            className="flex flex-col text-center mt-5"
            onSubmit={handleLogin}
          >
            <div className="w-full relative ">
              <input
                className={
                  isFocuseduser
                    ? "animatekeep cursor-text animatetest pt-6 pb-2 pr-2 pl-3 w-full border border-gray-main rounded-md outline-none"
                    : "cursor-text animatetest pt-6 pb-2 pr-2 pl-3 w-full border border-gray-main rounded-md outline-none"
                }
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onFocus={handleFocususer}
                onBlur={handleBluruser}
                ref={userref}
              />
              <span className="focus-border">
                <i></i>
              </span>
              <div className="top-0.5 left-3 absolute bg-transparent">
                <span className="text-xs text-gray-300">User</span>
              </div>
            </div>
            <div className="w-full mt-2 relative">
              <input
                className={
                  isFocusedpassword
                    ? "cursor-text animatekeep animatetest pt-6 pb-2 pr-2 pl-3 w-full border border-gray-main rounded-md outline-none"
                    : "cursor-text animatetest pt-6 pb-2 pr-2 pl-3 w-full border border-gray-main rounded-md outline-none"
                }
                type={type}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={handleFocuspassword}
                onBlur={handleBlurpassword}
                ref={passwordref}
              />
              <span
                className="bottom-4 right-2.5 absolute"
                onClick={handleToggle}
              >
                <div onClick={() => setIsActive(!isActive)}>
                  {isActive ? (
                    <MdVisibility
                      color="#183350"
                      className="cursor-pointer"
                      size={20}
                    />
                  ) : (
                    <MdVisibilityOff
                      color="#183350"
                      className="cursor-pointer"
                      size={20}
                    />
                  )}
                </div>
              </span>
              <div className="top-0.5 left-3 absolute bg-transparent">
                <span className="text-xs text-gray-300">Password</span>
              </div>
            </div>

            <div className="text-center mt-6">
              <button
                className="btn-primary-large bg-blue-950 border-transparent text-white bg-primary-600 active:bg-primary-700 hover:bg-primary-700 focus:bg-primary-700 active:bg-primary-800 focus:shadow-outline-primary text-sm leading-5 px-4 py-2 font-medium rounded-md inline-flex items-center justify-center border focus:outline-none transition duration-150 ease-in-out"
                type="submit"
              >
                {t("Login")}
              </button>
            </div>
          </form>
        </div>
      </div>
      <QrCodeModal
        open={openModalQrCode}
        modalActive={setOpenModalQrCode}
        onClose={() => setOpenModalQrCode(false)}
        openModalFirstLogin={setOpenModalFirstLogin}
        qrCodeValue={qrCodeValue}
        username={username}
        password={password}
      />
      <FirstLoginModal
        openLoginModal={openModalFirstLogin}
        setModalState={setOpenModalFirstLogin}
        clearPasswordInput={clearPasswordInput}
      />
      <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2">
        <Slide direction="up" in={showAlert} mountOnEnter unmountOnExit>
          <Alert variant="filled" severity={alertContent?.type} sx={{ position: 'fixed', top: 20, right: 20, zIndex: 1300 }}>
            {alertContent?.text}
          </Alert>
        </Slide>
      </div>
    </div>
  );
};

export default LoginScreen;
