/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { ThemeProvider, createTheme } from "@mui/material/styles";
// eslint-disable-next-line no-unused-vars
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import Navbar from "../../components/navbar/Navbar.jsx";
import Sidebar from "../../components/sidebar/Sidebar.jsx";
import { UserContext } from "../../context/loggedUserContext";
import {
  getTransactionsApproval,
  updateTransactionsApproval,
} from "../../services/TransactionsService.jsx";
import { formatDate } from "../../utils/format";
import AuthModal from "./AuthModal.jsx";
import "./transactionAuthorizationPage.scss";

const queryClient = new QueryClient();
const labelFontSize = "1.05rem";
const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: labelFontSize,
          fontWeight: 700,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& > fieldset > legend": {
            fontSize: `calc(0.75 * ${labelFontSize})`,
          },
        },
      },
    },
  },
});

const formatCurrency = (value) => {
  const formattedValue = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value / 100);

  return formattedValue;
};


const TransactionAuthorizationPage = () => {
  const [statementRows, setStatementRows] = useState([]);
  // const [order, setOrder] = React.useState("asc");
  // const [orderBy, setOrderBy] = React.useState("updated_at");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [totalElements, setTotalElements] = useState(1);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState(null);
  const [transactionData, setTransactionData] = useState(null);
  const { user } = useContext(UserContext);

  const isDisabled = (status) => {
    return status === "APPROVED" || status === "BLOCKED";
  };

  const handleStatusChange = async (row, status) => {
    const { user_id, id, amount, original_request } = row;
    setModalOpen(false);
    try {
      const updateData = await updateTransactionsApproval(
        user_id,
        id,
        amount,
        status,
        original_request
      );
      if (updateData) {
        handleShowAlert(
          "success",
          t("Transação Aprovada."),
          t("A transação foi aprovada com sucesso.")
        );
      }

      fetchStatement();
    } catch (error) {
      console.error("Failed to update transaction:", error);
      handleShowAlert(
        "error",
        t("Erro ao atualizar transação."),
        t("A transação não foi atualizada.")
      );
    }
  };

  const openModal = (row, status) => {
    setStatus(status);
    setTransactionData(row);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setStatus("");
  };

  const fetchStatement = async () => {
    try {
      setLoading(true);
      const response = await getTransactionsApproval(page + 1, rowsPerPage);
      setTotalElements(response.totals.transactions_qty);

      const transactionsApproval = response?.transactionsApproval?.map((t) => ({
        ...t,
        key: t.id,
        updated_at: t?.updated_at,
        created_at: t?.created_at,
        status: t?.status,
        type: t?.type,
        amount: t?.amount,
        user_id: t?.user_id,
        user_description: t?.user_description,
        approved_by: t?.approved_by,
        original_request: t?.original_request,
      }));
      setStatementRows(transactionsApproval);
      setLoading(false);
      return;
    } catch (error) {
      setLoading(false);
      setStatementRows([]);
      console.error(error);
      return;
    }
  };

  useEffect(() => {
    fetchStatement();
  }, [page, rowsPerPage]);

  const handleShowAlert = (type, title, text) => {
    setAlertContent({ type, title, text });
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <div className="profile">
          <Sidebar />
          <div className="w-10/12 sm:w-full">
            <Navbar />
            <div className="flex flex-col">
              <div className="overflow-x-auto flex justify-center">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <table className="tableAuth">
                    <thead>
                      <tr>
                        <th>Data e Horário</th>
                        <th>Usuário</th>
                        <th>Valor da Transação</th>
                        <th>Tipo da Transação</th>
                        <th>Status</th>
                        <th>Atualizado Em</th>
                        <th>Aprovação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {statementRows.map((row, index) => (
                        <tr key={index}>
                          <td
                            className="tableCellAuth"
                            data-label="Data e Horário"
                          >
                            {formatDate(row.created_at, "complete")}
                          </td>
                          <td className="tableCellAuth" data-label="Usuário">
                            {row?.user_description
                              ? row.user_description.length > 20
                                ? `${row.user_description.substring(0, 20)}...`
                                : row.user_description
                              : "-"}
                          </td>
                          <td
                            className="tableCellAuth"
                            data-label="Valor da Transação"
                          >
                            {formatCurrency(row.amount || 0)}
                          </td>
                          <td
                            className="tableCellAuth"
                            data-label="Tipo da Transação"
                          >
                            {row.type}
                          </td>
                          <td
                            className={`tableCellAuth ${row.status}`}
                            data-label="Status"
                          >
                            {row.status}
                          </td>
                          <td
                            className="tableCellAuth"
                            data-label="Atualizado Em"
                          >
                            {row.status === "PENDING"
                              ? "-"
                              : formatDate(row.updated_at, "complete")}
                          </td>
                          <td className="tableCellAuth" data-label="Aprovação">
                            <IconButton
                              sx={{ color: "green" }}
                              onClick={() => {
                                openModal(row, "APPROVED");
                              }}
                              disabled={isDisabled(row.status)}
                            >
                              <CheckIcon />
                            </IconButton>
                            <IconButton
                              sx={{ color: "red" }}
                              onClick={() => {
                                openModal(row, "BLOCKED");
                              }}
                              disabled={isDisabled(row.status)}
                            >
                              <CloseIcon />
                            </IconButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}

                {modalOpen && (
                  <AuthModal
                    open={modalOpen}
                    onClose={closeModal}
                    status={status}
                    handleShowAlert={handleShowAlert}
                    transactionData={transactionData}
                    handleConfirm={() =>
                      handleStatusChange(transactionData, status)
                    }
                  />
                )}
                {showAlert && (
                  <Slide
                    direction="up"
                    in={showAlert}
                    mountOnEnter
                    unmountOnExit
                  >
                    <Alert
                      severity={alertContent.type}
                      style={{
                        position: "fixed",
                        bottom: "10px",
                        right: "10px",
                      }}
                    >
                      <AlertTitle>{alertContent.title}</AlertTitle>
                      {alertContent.text}
                    </Alert>
                  </Slide>
                )}
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default TransactionAuthorizationPage;
