import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "../context/loggedUserContext";
import { authenticate } from "../services/TransactionsService";
import { decrypt } from "../utils/crypto.mjs";

const PrivateRoute = () => {
  const { user } = useContext(UserContext);
  if (!user) {
    (async function () {
      try {
        const response = await authenticate();
        const isFees = response?.user?.is_fees_account;
        const isTrueSet = isFees === 1;

        return isTrueSet ? <Outlet /> : <Navigate to="/" />;
      } catch (err) {
        console.log(err);
      }
    })();
  } else {
    const isFees = user?.is_fees_account;
    const isTrueSet = isFees === 1;

    return isTrueSet ? <Outlet /> : <Navigate to="/" />;
  }
};

export default PrivateRoute;
