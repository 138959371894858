import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import logo from "../../../public/izipaylogo.png";
import robotoRegular from "../../../public/fonts/Roboto/Roboto-Regular.ttf";
import robotoBold from "../../../public/fonts/Roboto/Roboto-Bold.ttf";
import robotoLight from "../../../public/fonts/Roboto/Roboto-Light.ttf";
import robotoMedium from "../../../public/fonts/Roboto/Roboto-Medium.ttf";

Font.register({
  family: "Roboto",
  fonts: [
    { src: robotoRegular }, // Regular
    { src: robotoBold, fontWeight: "bold" }, // Bold
    { src: robotoLight, fontWeight: "light" }, // Light
    { src: robotoMedium, fontWeight: "medium" }, // Light
  ],
});

const formatCurrency = (value) => {
  const formattedValue = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);

  return formattedValue;
};

const formatDate = (value) => {
  const date = new Date(value);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return date.toLocaleDateString("pt-BR", options);
};

function getStatusMessage(status) {
  const statusMap = {
    PAID: "Aprovado",
    FAILED: "Reprovado",
  };

  return statusMap[status];
}

export function maskDocument(document) {
  if (document.length === 11) {
    // Mascarar CPF
    return `***.${document.substring(3, 6)}.${document.substring(6, 9)}-**`;
  } else if (document.length === 14) {
    // Mascarar CNPJ
    return `**.${document.substring(2, 5)}.${document.substring(5, 8)}/0001-**`;
  } else {
    throw new Error("Documento deve conter 11 (CPF) ou 14 (CNPJ) dígitos");
  }
}

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    padding: "2cm",
  },
  header: {
    marginBottom: "1cm",
    marginTop: "8mm",
    textAlign: "left",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    borderBottomStyle: "solid",
  },
  headerTitle: {
    fontFamily: "Roboto",
    fontSize: 19,
    fontWeight: 800,
    paddingLeft: "1mm",
  },
  headerText: {
    fontSize: 12,
    marginTop: "2mm",
    color: "#777",
    paddingLeft: "2mm",
    paddingBottom: "2mm",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 15,
    fontWeight: 800,
    marginTop: "9mm",
    marginBottom: "3mm",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between", // Align children to the start and end of the row
    alignItems: "center",
    borderBottomWidth: 0,
    borderBottomColor: "#ccc",
    borderBottomStyle: "solid",
    paddingTop: "3mm",
  },

  rowWithBorder: {
    flexDirection: "row",
    justifyContent: "space-between", // Align children to the start and end of the row
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    borderBottomStyle: "solid",
    padding: "0.5cm 0",
  },
  label: {
    color: "#777",
    flex: 1, // Take 1/3 of the row's width
    fontWeight: "light",
    textAlign: "justify", // Align text to the start of the label (left)
    fontSize: 13,
    paddingLeft: "1mm",
  },
  value: {
    flex: 2, // Take 2/3 of the row's width
    fontWeight: "normal", // Adjust font weight as needed
    textAlign: "justify", // Align text to the end of the value (right)
    fontSize: 14,
  },
  table: {
    flexDirection: "row",
    justifyContent: "space-around",
    paddingBottom: "1cm",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    borderBottomStyle: "solid",
  },
  rowSpecial1: {
    flex: 1,
    flexDirection: "column",
    alignItems: "left",
  },
  rowSpecial2: {
    flex: 2,
    flexDirection: "column",
    alignItems: "left",
  },
  labelSpecial: {
    color: "#777",
    fontSize: 13,
    marginBottom: "3mm",
  },
  valueSpecial: {
    fontFamily: "Roboto",
    fontWeight: "Bold",
    letterSpacing: "0.7",
    fontSize: 17,
  },
  footer: {
    position: "absolute",
    bottom: "1cm",
    left: "1cm",
    right: "1cm",
    textAlign: "center",
    color: "#777",
    fontSize: 10,
  },
  logo: {
    width: 170,
    height: 40,
    marginTop: "1cm",
    marginBottom: "1cm",
    alignSelf: "flex-start", // Align the logo to the start of the row (left)
  },
  textTop: {
    marginTop: 5,
    width: "70%", // Adjust the width as needed to fit the content
    height: 100, // Adjust the height as needed
    marginBottom: "1cm",
    alignSelf: "flex-end",
    textAlign: "right",
    fontSize: 12,
    fontWeight: "bold",
    flexWrap: "nowrap", // Prevent text from wrapping to a new line
  },
});

// account_bank_ispb: ""
// account_bank_name: ""
// account_branch: ""
// account_number: ""
// account_owner_name: ""
// amount: 5
// document_number: ""
// end_to_end_id: "99fabe69-8f5b-4886-89ed-e26eb63d586d"
// fee_charged: 0
// fees_status: ""
// key: "b91dbd99-eb69-4ee6-8625-1abca11df72e"
// last_updated_at: "2024-05-29T19:29:48.000Z"
// movement_type: "CASH-OUT"
// order_id: "89BF9D6DE40C4E12A0AFEDEE57EF0D71"
// payload_account_owner_name: ""
// payload_document_number: ""
// qr_code_value: ""
// status: "PAID"
// statusMessage: "Transação confirmada com sucesso."
// transaction_id: "b91dbd99-eb69-4ee6-8625-1abca11df72e"
// user_description: "Yuri Dev"
// user_id: 463

const movementType = "CASH-IN";
const transactionDate = "2024-05-29T19:29:48.000Z";
const amount = 5;
const status = "PAID";
const user_description = "Usuário Teste";
const user_document_number = "12345678901";
const user_bank_name = "IziPay";
const payload_account_owner_name = "Cliente Payload";
const payload_document_number = "98765432109";
const payload_bank_name = "Banco Payload";
const pixKey = "chavepix@email.com";
const endToEndId = "99fabe69-8f5b-4886-89ed-e26eb63d586d";
const orderId = "89BF9D6DE40C4E12A0AFEDEE57EF0D71";

export const ReceiptExample = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <Image src={logo} style={styles.logo} />
      </View>
      <View style={styles.header}>
        <Text style={styles.headerTitle}>
          Comprovante
          {movementType === "CASH-IN"
            ? " Pix - Depósito"
            : movementType === "CASH-OUT"
            ? " Pix - Saque"
            : movementType === "TEF-IN" || movementType === "TEF-OUT"
            ? " TEF"
            : ""}
        </Text>
        <Text style={styles.headerText}>{formatDate(transactionDate)}</Text>
      </View>
      <View style={styles.table}>
        <View key={amount} style={styles.rowSpecial1}>
          <Text style={styles.labelSpecial}>Valor</Text>
          <Text style={styles.valueSpecial}>{formatCurrency(amount)}</Text>
        </View>
        <View key={status} style={styles.rowSpecial2}>
          <Text style={styles.labelSpecial}>Status</Text>
          <Text style={styles.valueSpecial}>{getStatusMessage(status)}</Text>
        </View>
      </View>

      <View style={styles.row}>
        <Text style={styles.title}>Quem pagou</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>Nome</Text>
        <Text style={styles.value}>
          {movementType === "CASH-IN" || movementType === "TEF-IN"
            ? payload_account_owner_name
            : movementType === "CASH-OUT" || movementType === "TEF-OUT"
            ? user_description
            : ""}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>Documento</Text>
        <Text style={styles.value}>
          {movementType === "CASH-IN" || movementType === "TEF-IN"
            ? payload_document_number
            : movementType === "CASH-OUT" || movementType === "TEF-OUT"
            ? user_document_number
            : ""}
        </Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>Instituição</Text>
        <Text style={styles.value}>
          {movementType === "CASH-IN" || movementType === "TEF-IN"
            ? payload_bank_name
            : movementType === "CASH-OUT" || movementType === "TEF-OUT"
            ? user_bank_name
            : ""}
        </Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.title}>Quem recebeu</Text>
      </View>

      <View style={styles.row}>
        <Text style={styles.label}>Nome</Text>
        <Text style={styles.value}>
          {movementType === "CASH-IN" || movementType === "TEF-IN"
            ? user_description
            : movementType === "CASH-OUT" || movementType === "TEF-OUT"
            ? payload_account_owner_name
            : ""}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>Documento</Text>
        <Text style={styles.value}>
          {movementType === "CASH-IN" || movementType === "TEF-IN"
            ? user_document_number
            : movementType === "CASH-OUT" || movementType === "TEF-OUT"
            ? payload_document_number
            : ""}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>Instituição</Text>
        <Text style={styles.value}>
          {movementType === "CASH-IN" || movementType === "TEF-IN"
            ? user_bank_name
            : movementType === "CASH-OUT" || movementType === "TEF-OUT"
            ? payload_bank_name
            : ""}
        </Text>
      </View>
      {pixKey && (
        <View key={pixKey} style={styles.row}>
          <Text style={styles.label}>Chave Pix</Text>
          <Text style={styles.value}>{pixKey}</Text>
        </View>
      )}

      <View style={styles.row}>
        <Text style={styles.title}>Informações da operação</Text>
      </View>

      <View key={endToEndId} style={styles.row}>
        <Text style={styles.label}>E2E</Text>
        <Text style={styles.value}>{endToEndId}</Text>
      </View>

      <View key={orderId} style={styles.row}>
        <Text style={styles.label}>Identificador</Text>
        <Text style={styles.value}>{orderId}</Text>
      </View>

      <View style={styles.footer}>
        <Text>Gerado em {formatDate(new Date())}</Text>
      </View>
    </Page>
  </Document>
);

const PDFViewerComponent = () => (
  <div style={{ width: "100vw", height: "100vh" }}>
    <PDFViewer style={{ width: "100%", height: "100%" }}>
      <ReceiptExample />
    </PDFViewer>
  </div>
);

export { PDFViewerComponent };
