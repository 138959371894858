import { Box, IconButton } from "@mui/material";

import { useTranslation } from "react-i18next";
import "./transactionModal.scss";

import { t } from "i18next";
import {
  formatCurrency,
  formatDate,
  handleDataTranslate,
} from "../../utils/format";
import { useMediaQuery } from "react-responsive";
import DownloadIcon from "@mui/icons-material/Download";
import { generateNewReceipt } from "../receipt/NewReceipt";

const formatStatus = (type, key) => {
  switch (key) {
    case "generated_at":
      if (type === "CASH-IN") {
        return t("Depósito gerado");
      }
      if (type === "CASH-OUT") {
        return t("Saque gerado");
      }
      break;
    case "generated_pincode_at":
      return t("Código PIN enviado para e-mail");
    case "generated_qrcode_at":
      return t("QR Code gerado");
    case "completed_at":
      if (type === "CASH-IN") {
        return t("Depósito realizado");
      }
      if (type === "CASH-OUT") {
        return t("Saque realizado");
      }
      if (type === "TEF-IN" || type === "TEF-OUT") {
        return t("Transferência realizada");
      }
      break;
    case "requested_refund_at":
      if (type === "CASH-IN") {
        return t("Reembolso solicitado");
      }
      break;
    case "refunded_at":
      if (type === "CASH-IN") {
        return t("Reembolso realizado");
      }
      break;
    case "failed_at":
      return t("Falha na transação");
    case "expired_at":
      if (type === "CASH-IN") {
        return t("QR Code expirado");
      }
      if (type === "CASH-OUT") {
        return t("Código PIN expirado");
      }
      break;
    default:
      break;
  }
};

const StatusHistory = ({ row }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const objectStatusHistory = row.status_history;
  const statusEntries = Object.entries(objectStatusHistory);
  const lastEntry = statusEntries[statusEntries.length - 1];
  const type = row.movement_type;
  const status = row.status;

  const renderStatusItem = (status, type) => {
    if (status === "EXPIRED" || status === "FAILED" || status === "REFUNDED") {
      return (
        <div className="status-item w-full">
          <div className="status-info w-full">
            <div
              className={
                isMobile
                  ? "flex w-4/12 text-center text-sm mt-0.5"
                  : "flex w-4/12 items-center justify-end text-sm mt-0.5"
              }
            >
              {formatDate(row.last_updated_at, "complete")}
            </div>
            <div className="w-1/12 flex items-center justify-center">
              <div className="status-indicator red"></div>
            </div>
            <div
              className={
                isMobile
                  ? "status-title w-7/12 flex justify-center items-center text-center text-sm font-semibold"
                  : "status-title w-7/12 flex justify-start items-center font-semibold"
              }
            >
              {status === "EXPIRED"
                ? formatStatus(type, "expired_at")
                : status === "FAILED"
                ? formatStatus(type, "failed_at")
                : status === "REFUNDED"
                ? formatStatus(type, "refunded_at")
                : null}
            </div>
          </div>
        </div>
      );
    }
    return null;
  };
  const renderConditionalStatusItem = (lastEntry, status, type) => {
    if (
      lastEntry &&
      lastEntry[0] !== "failed_at" &&
      lastEntry[0] !== "expired_at" &&
      status !== "EXPIRED" &&
      status !== "FAILED"
    ) {
      return (
        <div className="status-item w-full">
          <div className="status-info w-full">
            <div
              className={
                isMobile
                  ? "flex w-4/12 text-center text-sm mt-0.5"
                  : "flex w-4/12 items-center justify-end text-sm mt-0.5"
              }
            >
              {formatDate(lastEntry[1], "complete")}
            </div>
            <div className="w-1/12 flex items-center justify-center">
              {lastEntry[0] === "completed_at" ||
              lastEntry[0] === "refunded_at" ? (
                <div className="status-indicator green"></div>
              ) : (
                <div className="status-indicator orange"></div>
              )}
            </div>
            <div
              className={
                isMobile
                  ? "status-title w-7/12 flex justify-center items-center text-center text-sm font-semibold"
                  : "status-title w-7/12 flex justify-start items-center font-semibold"
              }
            >
              {lastEntry[0] === "completed_at"
                ? t("Transação concluída")
                : lastEntry[0] === "refunded_at"
                ? t("Transação reembolsada")
                : type === "CASH-OUT" && lastEntry[0] === "generated_pincode_at"
                ? t("Aguardando confirmação do código PIN")
                : t("Aguardando pagamento")}
            </div>
          </div>
        </div>
      );
    }
    return null; // Retorna null se a condição não for atendida
  };

  return (
    <div className="status-history">
      <div className="status-item w-full">
        <div className="status-info w-full">
          <div
            className={
              isMobile
                ? "flex w-4/12 text-center text-sm mt-0.5"
                : "flex w-4/12 items-center justify-end text-sm mt-0.5"
            }
          >
            {formatDate(statusEntries[0][1], "complete")}
          </div>
          <div className="w-1/12 flex items-center justify-center">
            <div className="status-indicator green"></div>
          </div>
          <div
            className={
              isMobile
                ? "status-title w-7/12 flex justify-center items-center text-center text-sm font-semibold"
                : "status-title w-7/12 flex justify-start items-center font-semibold"
            }
          >
            {type === "CASH-IN"
              ? t("Depósito solicitado")
              : type === "CASH-OUT"
              ? t("Saque solicitado")
              : t("Transferência solicitada")}
          </div>
        </div>
      </div>
      {statusEntries.map(([key, value], index) => (
        <div className="status-item w-full" key={index}>
          <div className="status-info w-full">
            <div
              className={
                isMobile
                  ? "flex w-4/12 text-center text-sm mt-0.5"
                  : "flex w-4/12 items-center justify-end text-sm mt-0.5"
              }
            >
              {formatDate(value, "complete")}
            </div>
            <div className="w-1/12 flex items-center justify-center">
              <div
                className={`status-indicator ${
                  key === "failed_at" || key === "expired_at" ? "red" : "green"
                }`}
              ></div>
            </div>
            <div
              className={
                isMobile
                  ? "status-title w-7/12 flex justify-center items-center text-center text-sm font-semibold"
                  : "status-title w-7/12 flex justify-start items-center font-semibold"
              }
            >
              {formatStatus(type, key)}
            </div>
          </div>
        </div>
      ))}
      {renderStatusItem(status, type)}
      {renderConditionalStatusItem(lastEntry, status, type)}
    </div>
  );
};

export const TransactionModal = ({ row }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const { t } = useTranslation();
  // const widthStyle = row.status_history ? "70%" : "30%";
  // const paddingStyle = row.status_history ? 4 : 3;
  const widthStyle = "30%";
  const paddingStyle = 3;
  const downloadPDF = (transaction) => {
    generateNewReceipt(transaction);
  };
  console.log(row);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: widthStyle,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: paddingStyle,
    paddingLeft: 2,
  };
  const styleMobile = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    padding: 2,
  };

  return (
    <Box sx={isMobile ? styleMobile : style}>
      <div
        className={
          isMobile
            ? "flex-col-reverse gap flex items-center"
            : "flex-row flex gap-3"
        }
      >
        {/* AJUSTAR HORÁRIO E completed_at NA API */}
        {/* {row.status_history && (
          <div
            className={
              isMobile
                ? "flex flex-col w-full items-center mt-3"
                : "flex flex-col w-1/2 border-r gap-3"
            }
          >
            <div className="w-full flex justify-center items-center mb-2">
              <span className="font-bold text-center text-xl">
                {t("Histórico da Transação")}
              </span>
            </div>
            <div>
              <StatusHistory row={row} />
            </div>
          </div>
        )} */}
        <div
          className={
            isMobile
              ? "w-full pb-2 border-b"
              : // : row.status_history
                // ? "w-1/2"
                "w-full"
          }
        >
          <div
            className={
              isMobile
                ? "flex h-full mb-2 w-full flex-col justify-center items-center"
                : "flex h-full w-full flex-col justify-center items-center"
            }
          >
            <div className="flex flex-col">
              <div className="w-full flex justify-center text-xl">
                <span className="font-bold">{row.user_description}</span>
              </div>
              <div className="w-full flex justify-center text-xl">
                <span className="font-bold">
                  {row.account_owner_name
                    ? row.account_owner_name
                    : row.payload_account_owner_name}
                </span>
              </div>
              <div className="flex text-sm justify-center">
                <span>
                  {`${formatDate(row.last_updated_at, "complete-at")}`}
                </span>
              </div>
            </div>
            <div className="flex flex-col h-full mt-3">
              <div>
                <span className="text-sm">{t("Descrição: ")}</span>
                <span
                  className={
                    isMobile
                      ? "text-sm font-semibold"
                      : "text-medium font-semibold"
                  }
                >
                  {t(handleDataTranslate(row.movement_type))}
                </span>
              </div>
              <div>
                <span className="text-sm">Status: </span>
                <span
                  className={
                    isMobile
                      ? "text-sm font-semibold"
                      : "text-medium font-semibold"
                  }
                >
                  {t(handleDataTranslate(row.status))}
                </span>
              </div>
              <div>
                <span className="text-sm">{t("ID Conta Virtual Iugu: ")}</span>
                <span
                  className={
                    isMobile
                      ? "text-sm font-semibold"
                      : "text-medium font-semibold"
                  }
                >
                  {row.virtual_account}
                </span>
              </div>
              <div>
                <span className="text-sm">{t("ID interno: ")}</span>
                <span
                  className={
                    isMobile
                      ? "text-sm font-semibold"
                      : "text-medium font-semibold"
                  }
                >
                  {row.transaction_id}
                </span>
              </div>
              <div>
                <span className="text-sm">{t("Número Documento: ")}</span>
                <span
                  className={
                    isMobile
                      ? "text-sm font-semibold"
                      : "text-medium font-semibold"
                  }
                >
                  {row.document_number
                    ? row.document_number
                    : row.payload_document_number}
                </span>
              </div>
              <div>
                <span className="text-sm">{t("Valor total: ")}</span>
                <span
                  className={
                    isMobile
                      ? "text-sm font-semibold"
                      : "text-medium font-semibold"
                  }
                >
                  {formatCurrency(row.amount)}
                </span>
              </div>
              <div>
                <span className="text-sm">{t("Valor da taxa: ")}</span>
                <span
                  className={
                    isMobile
                      ? "text-sm font-semibold"
                      : "text-medium font-semibold"
                  }
                >
                  {formatCurrency(row.fee_charged)}
                </span>
              </div>
              {row.fees_status ? (
                <div>
                  <span className="text-sm">{t("Status da taxa: ")}</span>
                  <span
                    className={
                      isMobile
                        ? "text-sm font-semibold"
                        : "text-medium font-semibold"
                    }
                  >
                    {t(handleDataTranslate(row.fees_status))}
                  </span>
                </div>
              ) : (
                ""
              )}
              {row.end_to_end_id ? (
                <div>
                  <span className="text-sm">{t("ID End to End: ")}</span>
                  <span
                    className={
                      isMobile
                        ? "text-sm font-semibold"
                        : "text-medium font-semibold"
                    }
                  >
                    {row.end_to_end_id}
                  </span>
                </div>
              ) : (
                ""
              )}
              {isMobile && (
                <div className=" mt-2 mb-2 text-center">
                  <IconButton
                    sx={{
                      ":hover": {
                        bgcolor: "rgba(0, 0, 0, 0.15)", // theme.palette.primary.main
                      },
                      padding: "0px",
                    }}
                    size="large"
                    disabled={row?.status === "PAID" ? false : true}
                    onClick={() => downloadPDF(row)}
                    className={
                      row?.status === "PAID"
                        ? "txgreen"
                        : "cursor-default text-zinc-400"
                    }
                  >
                    <DownloadIcon />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};
