import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MoreVert } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useContext, useState } from "react";
import { DarkModeContext } from "../../context/darkModeContext";
import { useTranslation } from "react-i18next";
import { ListItemIcon, ListItemText } from "@mui/material";

export default function BasicMenu({
  type,
  status,
  onOpenDetails,
  onOpenRefund,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { darkMode } = useContext(DarkModeContext);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVert
          className={
            darkMode ? "text-izired text-2xl " : "text-iziblue text-2xl "
          }
        />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            onOpenDetails(), handleClose();
          }}
        >
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText>{t("Exibir Detalhes")}</ListItemText>
        </MenuItem>
        {/* AJUSTAR REFUND (ERROR NETWORK) */}
        {/* {type === "CASH-IN" && status === "PAID" && (
          <MenuItem
            onClick={() => {
              onOpenRefund(), handleClose();
            }}
          >
            <ListItemIcon>
              <AttachMoneyIcon />
            </ListItemIcon>
            <ListItemText>{t("Solicitar Reembolso")}</ListItemText>
          </MenuItem>
        )} */}
      </Menu>
    </div>
  );
}
