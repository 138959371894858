/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./auth/PrivateRoute";
import PrivateRouteLogin from "./auth/PrivateRouteLogin";
import Loginexb from "./components/login/Loginexb";
import { DarkModeContext } from "./context/darkModeContext";
import { FilterDataProvider } from "./context/filterDataContext";
import { UserContextProvider } from "./context/loggedUserContext";
import { SubTokenProvider } from "./context/subTokenContext";
import CreateUser from "./pages/admin/CreateUser";
import UserList from "./pages/admin/UserList";
import UserProfile from "./pages/admin/UserProfile";
import Home from "./pages/home/Home";
import FeesTransactions from "./pages/list/FeesList";
import Transactions from "./pages/list/List";
import Payments from "./pages/payments/Payments";
import Profile from "./pages/profile/Profile";
import Receipts from "./pages/receipts/Receipts";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import PrivateRouteApproval from "./auth/PrivateRouteApproval";
import PrivteRouteFeesTransactions from "./auth/PrivateRouteFeesTransaction";
import PrivateRouteHome from "./auth/PrivateRouteHome";
import PrivateRoutePayments from "./auth/PrivateRoutePayments";
import PrivateRouteProfile from "./auth/PrivateRouteProfile";
import PrivateRouteTransactions from "./auth/PrivateRouteTransactions";

import "./style/dark.scss";

import PrivateRouteSimPayments from "./auth/PrivateRouteSimPayments";
import { PDFViewerComponent } from "./components/receipt/Receipt-example";
import TransactionAuthorizationPage from "./pages/admin/TransactionAuthorizationPage";
import SimPayments from "./pages/sim-payments/SimPayments";

const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: "1px solid #283650",
          "&:hover": {
            border: "1px solid #283650", // Change border color on hover
          },
          "&.Mui-focused": {
            border: "none",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #283650",
            },
          }, // Set the border color to red (or any other color you prefer)
        },
        root: {
          "&.Mui-focused": {
            border: "none",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #283650",
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          // Default label styles
          color: "gray", // Default label color
          transition: "color 0.3s ease", // Transition for smooth color change

          // Change label color when input is focused
          "&.Mui-focused": {
            color: "#283650", // Change label color to blue when input is focused
          },
        },
      },
    },
  },
});
const queryClient = new QueryClient();

function App() {
  const { darkMode } = useContext(DarkModeContext);
  return (
    <ThemeProvider theme={theme}>
      <FilterDataProvider>
        <SubTokenProvider>
          <UserContextProvider>
            <div className={darkMode ? "app dark" : "app"}>
              <BrowserRouter>
                <Routes>
                  <Route element={<PrivateRouteLogin />}>
                    <Route exact path="/" element={<PrivateRouteHome />}>
                      <Route path="/" element={<Home />} />
                    </Route>
                    <Route
                      exact
                      path="/transactions"
                      element={<PrivateRouteTransactions />}
                    >
                      <Route path="/transactions" element={<Transactions />} />
                    </Route>
                    <Route
                      exact
                      path="/transactions/fees"
                      element={<PrivteRouteFeesTransactions />}
                    >
                      <Route
                        path="/transactions/fees"
                        element={<FeesTransactions />}
                      />
                    </Route>
                    <Route
                      exact
                      path="/admin/createuser"
                      element={<PrivateRoute />}
                    >
                      <Route
                        path="/admin/createuser"
                        element={<CreateUser />}
                      />
                    </Route>
                    <Route
                      exact
                      path="/sim-payments"
                      element={<PrivateRouteSimPayments />}
                    >
                      <Route
                        path="/sim-payments"
                        element={
                          <QueryClientProvider client={queryClient}>
                            <SimPayments />
                          </QueryClientProvider>
                        }
                      />
                    </Route>
                    <Route
                      exact
                      path="/payments"
                      element={<PrivateRoutePayments />}
                    >
                      <Route
                        path="/payments"
                        element={
                          <QueryClientProvider client={queryClient}>
                            <Payments />
                          </QueryClientProvider>
                        }
                      />
                    </Route>
                    <Route path="/receipts" element={<Receipts />} />
                    <Route
                      exact
                      path="/profile"
                      element={<PrivateRouteProfile />}
                    >
                      <Route path="/profile" element={<Profile />} />
                    </Route>
                    {/* <Route path="/admin" element={<Admin/>} /> */}
                    {/* Routes bellow are private, check PrivateRoute.jsx */}
                    <Route exact path="/userlist" element={<PrivateRoute />}>
                      <Route path="/userlist" element={<UserList />} />
                    </Route>
                    <Route
                      exact
                      path="/authorization"
                      element={<PrivateRouteApproval />}
                    >
                      <Route
                        path="/authorization"
                        element={<TransactionAuthorizationPage />}
                      />
                    </Route>
                    <Route exact path="/userprofile" element={<PrivateRoute />}>
                      <Route
                        path="/userprofile/:user_id/:external_token"
                        element={<UserProfile />}
                      />
                    </Route>
                    <Route exact path="/pdf" element={<PDFViewerComponent />} />
                  </Route>
                  <Route path="/login" element={<Loginexb />} />
                  <Route path="*" element={<PrivateRouteLogin />} />
                </Routes>
              </BrowserRouter>
            </div>
          </UserContextProvider>
        </SubTokenProvider>
      </FilterDataProvider>
    </ThemeProvider>
  );
}

export default App;
