/* eslint-disable no-prototype-builtins */
import { QueryClient, QueryClientProvider } from "react-query";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  InputAdornment,
  Alert,
  AlertTitle,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import FeeComponent from "../../components/fee/FeeComponent";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import "./admin.scss";
import * as yup from "yup";
import { cpf, cnpj } from "cpf-cnpj-validator";
import { mask, maskPhone } from "../../utils/validations";
import { createAccount } from "../../services/TransactionsService";
import { useNavigate } from "react-router-dom";
const queryClient = new QueryClient();
const defaultUserInfo = {
  person_type: "Pessoa Jurídica",
  cnpj: "48206461000141",
  company_name: "Moneta Transfer LTDA",
  address: "Av Doutor Jose Bonifacio Coutinho Nogueira, 150",
  cep: "13091611",
  district: "Jardim Madalena",
  city: "Campinas",
  state: "SP",
  telephone: "19992553122",
};
const defaultBankInfo = {
  bank: "Inter",
  bank_cc: "30613275-3",
  bank_ag: "0001",
  account_type: "Corrente",
};
const Home = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [baas, setBaas] = useState("");
  const [nameLabel, setNameLabel] = useState("Nome da Empresa");
  const [cpfLabel, setCpfLabel] = useState("CNPJ");
  const [userType, setUserType] = useState("Default");
  const [bankInfo, setBankInfo] = useState("Default");
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [description, setDescription] = useState("");
  const [webhookUrl, setWebhook] = useState("");
  const [cpf_cnpj, setCpfCnpj] = useState(mask(defaultUserInfo.cnpj.trim()));
  const [nameValue, setNameValue] = useState(defaultUserInfo.company_name);
  const [address, setAddress] = useState(defaultUserInfo.address);
  const [cep, setCep] = useState(defaultUserInfo.cep);
  const [district, setDistrict] = useState(defaultUserInfo.district);
  const [city, setCity] = useState(defaultUserInfo.city);
  const [state, setState] = useState(defaultUserInfo.state);
  const [telephone, setTelephone] = useState(
    maskPhone(defaultUserInfo.telephone)
  );
  const [bank, setBank] = useState(defaultBankInfo.bank);
  const [bank_cc, setBankCc] = useState(defaultBankInfo.bank_cc);
  const [bank_ag, setBankAg] = useState(defaultBankInfo.bank_ag);
  const [account_type, setAccountType] = useState(defaultBankInfo.account_type);
  const [adornmentPositionUser, setAdornmentPositionUser] = useState("start");
  const [valuePrefixUser, setValuePrefixUser] = useState("dashboard@");
  const [valuePrefixDescription, setValuePrefixDescription] =
    useState("Izipay Now - ");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorFields, setErrorFields] = useState({});
  const [open, setOpen] = useState(false);
  const [idFees, setIdFees] = useState(1);
  const navigate = useNavigate();

  const schema = yup.object().shape({
    // User and Authentication Information
    account_type: yup.string().required("Tipo de conta é obrigatório"),
    bank_ag: yup.string().required("Agência é obrigatório"),
    bank_cc: yup.string().required("Conta é obrigatório"),
    bank: yup.string().required("Banco é obrigatório"),
    address: yup.string().required("Endereço é obrigatório"),
    district: yup.string().required("Bairro é obrigatório"),
    state: yup.string().required("Estado é obrigatório"),
    city: yup.string().required("Cidade é obrigatório"),
    cep: yup.string().required("CEP é obrigatório"),
    telephone: yup.string().required("Telefone é obrigatório"),
    cpf_cnpj: yup.string().required(`${cpfLabel} é obrigatório`),
    name: yup.string().required(`${nameLabel} é obrigatório`),
    baas: yup.string().oneOf(["IUGU"], "Selecione um BaaS válido"),
    webhookUrl: yup
      .string()
      .url("Webhook Url deve ser uma URL válida")
      .required("Webhook Url é obrigatório"),
    description: yup.string().required("Descrição é obrigatória"),
    password: yup.string().required("Senha é obrigatória"),
    user: yup.string().required("Usuário é obrigatório"),
  });
  const handleChangePrefix = (event) => {
    setValuePrefixUser(event.target.value);
    if (event.target.value != "@dashboard") {
      setAdornmentPositionUser("start");
    } else {
      setAdornmentPositionUser("end");
    }
  };
  const handleChangePrefixDescription = (event) => {
    setValuePrefixDescription(event.target.value);
  };
  const handleChange = (event) => {
    setBaas(event.target.value);
  };
  const handleRadioChangePerson = (event) => {
    setUserType(event.target.value);
    const selectedValue = event.target.value;
    // Update label texts based on the selected value
    if (selectedValue === "Pessoa Física") {
      setNameLabel("Nome");
      setCpfLabel("CPF");
      setCpfCnpj("");
      setNameValue("");
      setAddress("");
      setCep("");
      setDistrict("");
      setCity("");
      setState("");
      setTelephone("");
    } else if (selectedValue === "Pessoa Jurídica") {
      setNameLabel("Nome da Empresa");
      setCpfLabel("CNPJ");
      setCpfCnpj("");
      setNameValue("");
      setAddress("");
      setCep("");
      setDistrict("");
      setCity("");
      setState("");
      setTelephone("");
    } else {
      setNameLabel("Nome da Empresa");
      setCpfLabel("CNPJ");
      setCpfCnpj(defaultUserInfo.cnpj);
      setNameValue(defaultUserInfo.company_name);
      setAddress(defaultUserInfo.address);
      setCep(defaultUserInfo.cep);
      setDistrict(defaultUserInfo.district);
      setCity(defaultUserInfo.city);
      setState(defaultUserInfo.state);
      setTelephone(defaultUserInfo.telephone);
    }
  };
  const handleRadioChangeBank = (event) => {
    setBankInfo(event.target.value);
    const selectedValue = event.target.value;
    // Update label texts based on the selected value
    if (selectedValue === "manual") {
      setBank("");
      setBankCc("");
      setBankAg("");
      setAccountType("");
    } else {
      setBank(defaultBankInfo.bank);
      setBankCc(defaultBankInfo.bank_cc);
      setBankAg(defaultBankInfo.bank_ag);
      setAccountType(defaultBankInfo.account_type);
    }
  };
  if (isMobile) {
    import("./adminMobile.scss");
  } else {
    import("./admin.scss");
  }

  const handleClick = async () => {
    setErrorMessage("");
    setErrorFields({});
    const formData = {
      person_type: userType,
      user: user,
      password: pass,
      description: description,
      webhookUrl: webhookUrl,
      baas: baas,
      name: nameValue,
      cpf_cnpj: cpf_cnpj,
      telephone: telephone,
      cep: cep,
      city: city,
      state: state,
      district: district,
      address: address,
      bank: bank,
      bank_cc: bank_cc,
      bank_ag: bank_ag,
      account_type: account_type,
      fees: removeIdFromObjects(fees),
    };
    await schema
      .validate(formData)
      .then(async (valid) => {
        console.log(valid);
        if (!cpf.isValid(cpf_cnpj) && !cnpj.isValid(cpf_cnpj)) {
          throw new Error("O documento CPF/CNPJ não é valido");
        }
        formData.user = `${valuePrefixUser}${user}`;
        formData.description = `${valuePrefixDescription}${description}`;
        console.log(formData);
        setOpen(true);
        const response = await createAccount(formData);
        if (response?.error) {
          setOpen(false);
          console.log("if", response);
        } else {
          setOpen(false);
          console.log("else", response);
          navigate(
            `/userprofile/${response.account_details.user_id}/${response.api_token}`
          );
        }
        console.log(response);
      })
      .catch((error) => {
        // Validation failed, errors contains an array of validation errors
        console.error("Validation error name:", error.name);
        console.error("Path to the field:", error.path);
        console.error("Type of validation error:", error.type);
        console.error("Error messages:", error.errors);
        console.error("Inner errors (if any):", error.inner);
        console.error("Original value:", error.value);
        console.error("Additional parameters:", error.params);
        const message = error.errors.map((err) => err).join("\n");
        const fieldError = {};
        fieldError[error.path] = true;
        setErrorMessage(message);
        setErrorFields(fieldError);
        throw new Error(error);
      });
  };
  const [fees, setFees] = useState([
    {
      id: idFees,
      receiver_id: "0DBDAC8D6FA344A59E75C8F9BAC36C3C",
      cash_in_fee: "",
      cash_out_fee: "",
      cash_in_fee_type: "value",
      cash_out_fee_type: "value",
    },
  ]);
  const addFee = () => {
    if (fees.length > 2) {
      console.log("Máximo de 3 taxas permitidas");
      setErrorMessage("Máximo de 3 taxas permitidas");
      setTimeout(() => {
        setErrorMessage("");
      }, 2500);
      return;
    }
    setIdFees(idFees + 1);
    const newFee = {
      id: idFees + 1,
      receiver_id: "0DBDAC8D6FA344A59E75C8F9BAC36C3C",
      cash_in_fee: "",
      cash_out_fee: "",
      cash_in_fee_type: "value",
      cash_out_fee_type: "value",
    };
    setFees([newFee, ...fees]);
    window.scrollTo(0, document.body.scrollHeight);
  };
  const removeFee = (id) => {
    const updatedFees = fees.filter((fee) => fee.id !== id);
    setFees(updatedFees);
  };
  const handleCashInChange = (id, event) => {
    const value = event.target.value.replace(/[^\d,]/g, "");
    const updatedFees = fees.map((fee) => {
      if (fee.id === id) {
        return { ...fee, cash_in_fee: value };
      }
      return fee;
    });
    setFees(updatedFees);
  };
  const handleCashOutChange = (id, event) => {
    const value = event.target.value;
    const updatedFees = fees.map((fee) => {
      if (fee.id === id) {
        return { ...fee, cash_out_fee: value };
      }
      return fee;
    });
    setFees(updatedFees);
  };
  const handleCashInTypeChange = (id, type) => {
    const updatedFees = fees.map((fee) => {
      if (fee.id === id) {
        return { ...fee, cash_in_fee_type: type.target.value };
      }
      return fee;
    });
    setFees(updatedFees);
  };
  const handleCashOutTypeChange = (id, type) => {
    const updatedFees = fees.map((fee) => {
      if (fee.id === id) {
        return { ...fee, cash_out_fee_type: type.target.value };
      }
      return fee;
    });
    setFees(updatedFees);
  };
  function removeIdFromObjects(array) {
    return array.map((obj) => {
      const newObj = { ...obj };
      // Check if the 'cash_in_fee' key exists and transform its value to float with maximum of two decimal places
      if (newObj.hasOwnProperty("cash_in_fee")) {
        newObj.cash_in_fee = parseFloat(
          newObj.cash_in_fee.replace(",", ".")
        ).toFixed(2);
      }
      // Check if the 'cash_out_fee' key exists and transform its value to float with maximum of two decimal places
      if (newObj.hasOwnProperty("cash_out_fee")) {
        newObj.cash_out_fee = parseFloat(
          newObj.cash_out_fee.replace(",", ".")
        ).toFixed(2);
      }
      // Delete the 'id' property from the object
      delete newObj.id;
      return newObj;
    });
  }
  return (
    <div className="admin flex">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Sidebar />
      <div className=" w-10/12 sm:w-full">
        <QueryClientProvider client={queryClient}>
          <Navbar onFilterData />
        </QueryClientProvider>
        <div className="w-full">
          <div className="h-[calc(100%-112px)]">
            {errorMessage && (
              <Alert
                className={
                  errorMessage
                    ? "alertanimateError absolute alertbox right-0 p-2 z-50"
                    : " absolute alertbox right-0 p-2 z-50"
                }
                severity="error"
              >
                <AlertTitle>Error</AlertTitle>
                {errorMessage}
              </Alert>
            )}
            <div className="flex flex-col gap-3 mx-auto w-9/12 m-6">
              <div className=" text-3xl  pb-3 my-3 border-b-2">Nova Conta</div>
              <div className="flex flex-col gap-3 justify-center">
                <div className="flex gap-3 justify-center">
                  <div className=" w-1/2">
                    <FormControl className=" w-full">
                      <TextField
                        label="Usuário"
                        variant="outlined"
                        onChange={(e) => setUser(e.target.value)}
                        error={errorFields?.user}
                        helperText={errorFields?.user && errorMessage}
                        InputProps={{
                          [adornmentPositionUser + "Adornment"]: (
                            <InputAdornment position="start">
                              <Select
                                value={valuePrefixUser}
                                onChange={handleChangePrefix}
                                className={
                                  valuePrefixUser != "@dashboard"
                                    ? `noborder borderright ${
                                        valuePrefixUser === ""
                                          ? "paddingvalue"
                                          : ""
                                      }`
                                    : `noborder borderleft`
                                }
                                sx={
                                  valuePrefixUser != "@dashboard" && {
                                    "& .MuiSvgIcon-root": {
                                      left: "-7px",
                                    },
                                  }
                                }
                              >
                                <MenuItem value="dashboard@">
                                  dashboard@
                                </MenuItem>
                                <MenuItem value="@dashboard">
                                  @dashboard
                                </MenuItem>
                                <MenuItem value="">Sem Prefixos</MenuItem>
                              </Select>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </div>
                  <div className=" w-1/2">
                    <FormControl className=" w-full">
                      <TextField
                        label="Senha"
                        variant="outlined"
                        onChange={(e) => setPass(e.target.value)}
                        error={errorFields?.password}
                        helperText={errorFields?.password && errorMessage}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="flex gap-3 justify-center">
                  <div className=" w-5/12">
                    <FormControl className=" w-full">
                      <TextField
                        label="Descrição"
                        variant="outlined"
                        onChange={(e) => setDescription(e.target.value)}
                        error={errorFields?.description}
                        helperText={errorFields?.description && errorMessage}
                        InputProps={{
                          [adornmentPositionUser + "Adornment"]: (
                            <InputAdornment position="start">
                              <Select
                                sx={{ "& .MuiSvgIcon-root": { left: "-7px" } }}
                                value={valuePrefixDescription}
                                onChange={handleChangePrefixDescription}
                                className={`noborder borderright ${
                                  valuePrefixDescription === ""
                                    ? "paddingvalue"
                                    : ""
                                }`}
                              >
                                <MenuItem value="Izipay Now - ">
                                  Izipay Now -
                                </MenuItem>
                                <MenuItem value="IZI - ">IZI -</MenuItem>
                                <MenuItem value="">Sem Prefixos</MenuItem>
                              </Select>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </div>
                  <div className=" w-5/12">
                    <FormControl className=" w-full">
                      <TextField
                        label="Webhook Url"
                        variant="outlined"
                        onChange={(e) => setWebhook(e.target.value)}
                        error={errorFields?.webhookUrl}
                        helperText={errorFields?.webhookUrl && errorMessage}
                      />
                    </FormControl>
                  </div>
                  <div className="w-2/12">
                    <FormControl
                      variant="outlined"
                      className=" w-full"
                      error={errorFields?.baas}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        BaaS
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={baas}
                        onChange={handleChange}
                        label="Age"
                        helperText={errorFields?.baas && errorMessage}
                      >
                        <MenuItem value="IUGU">IUGU</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-3 justify-center">
                <div className=" mx-auto">
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      defaultValue="Default"
                      onChange={handleRadioChangePerson}
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Default"
                        control={<Radio />}
                        label="Padrão"
                      />
                      <FormControlLabel
                        value="Pessoa Física"
                        control={<Radio />}
                        label="Pessoa Física"
                      />
                      <FormControlLabel
                        value="Pessoa Jurídica"
                        control={<Radio />}
                        label="Pessoa Jurídica"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="flex flex-col gap-3">
                  <div className="flex gap-2 justify-center">
                    <div className=" w-4/12">
                      <FormControl className=" w-full">
                        <TextField
                          disabled={userType === "Default"}
                          label={nameLabel}
                          value={nameValue}
                          onChange={(e) => setNameValue(e.target.value)}
                          variant="outlined"
                          error={errorFields?.name}
                          helperText={errorFields?.name && errorMessage}
                        />
                      </FormControl>
                    </div>
                    <div className="w-4/12">
                      <FormControl className=" w-full">
                        <TextField
                          disabled={userType === "Default"}
                          label={cpfLabel}
                          variant="outlined"
                          value={cpf_cnpj}
                          inputProps={{
                            maxLength: 18,
                          }}
                          onChange={(e) =>
                            setCpfCnpj(mask(e.target.value.trim()))
                          }
                          error={errorFields?.cpf_cnpj}
                          helperText={errorFields?.cpf_cnpj && errorMessage}
                        />
                      </FormControl>
                    </div>
                    <div className="w-4/12">
                      <FormControl className=" w-full">
                        <TextField
                          disabled={userType === "Default"}
                          label="Telefone"
                          variant="outlined"
                          value={telephone}
                          onChange={(e) =>
                            setTelephone(maskPhone(e.target.value.trim()))
                          }
                          error={errorFields?.telephone}
                          helperText={errorFields?.telephone && errorMessage}
                        />
                      </FormControl>
                    </div>
                  </div>
                  <div className="flex gap-2 justify-center">
                    <div className="w-4/12">
                      <FormControl className=" w-full">
                        <TextField
                          disabled={userType === "Default"}
                          label="CEP"
                          variant="outlined"
                          value={cep}
                          onChange={(e) => setCep(e.target.value)}
                          error={errorFields?.cep}
                          helperText={errorFields?.cep && errorMessage}
                        />
                      </FormControl>
                    </div>
                    <div className="w-4/12">
                      <FormControl className=" w-full">
                        <TextField
                          disabled={userType === "Default"}
                          label="Cidade"
                          variant="outlined"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          error={errorFields?.city}
                          helperText={errorFields?.city && errorMessage}
                        />
                      </FormControl>
                    </div>
                    <div className="w-4/12">
                      <FormControl className=" w-full">
                        <TextField
                          disabled={userType === "Default"}
                          label="Estado"
                          variant="outlined"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          error={errorFields?.state}
                          helperText={errorFields?.state && errorMessage}
                        />
                      </FormControl>
                    </div>
                  </div>
                  <div className="flex gap-2 justify-center">
                    <div className="w-2/12">
                      <FormControl className=" w-full">
                        <TextField
                          disabled={userType === "Default"}
                          label="Bairro"
                          variant="outlined"
                          value={district}
                          onChange={(e) => setDistrict(e.target.value)}
                          error={errorFields?.district}
                          helperText={errorFields?.district && errorMessage}
                        />
                      </FormControl>
                    </div>
                    <div className="w-10/12">
                      <FormControl className=" w-full">
                        <TextField
                          disabled={userType === "Default"}
                          label="Endereço"
                          variant="outlined"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          error={errorFields?.address}
                          helperText={errorFields?.address && errorMessage}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-3 justify-center">
                <div className=" mx-auto">
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      defaultValue="Default"
                      onChange={handleRadioChangeBank}
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Default"
                        control={<Radio />}
                        label="Padrão"
                      />
                      <FormControlLabel
                        value="manual"
                        control={<Radio />}
                        label="Manual"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="flex flex-col gap-3">
                  <div className="flex gap-2 justify-center">
                    <div className="w-3/12">
                      <FormControl className=" w-full">
                        <TextField
                          disabled={bankInfo === "Default"}
                          label="Banco"
                          variant="outlined"
                          value={bank}
                          onChange={(e) => setBank(e.target.value)}
                          error={errorFields?.bank}
                          helperText={errorFields?.bank && errorMessage}
                        />
                      </FormControl>
                    </div>
                    {/* cc ag type */}
                    <div className="w-3/12">
                      <FormControl className=" w-full">
                        <TextField
                          disabled={bankInfo === "Default"}
                          label="Conta"
                          variant="outlined"
                          value={bank_cc}
                          onChange={(e) => setBankCc(e.target.value)}
                          error={errorFields?.bank_cc}
                          helperText={errorFields?.bank_cc && errorMessage}
                        />
                      </FormControl>
                    </div>
                    <div className="w-3/12">
                      <FormControl className=" w-full">
                        <TextField
                          disabled={bankInfo === "Default"}
                          label="Agência"
                          variant="outlined"
                          value={bank_ag}
                          onChange={(e) => setBankAg(e.target.value)}
                          error={errorFields?.bank_ag}
                          helperText={errorFields?.bank_ag && errorMessage}
                        />
                      </FormControl>
                    </div>
                    <div className="w-3/12">
                      <FormControl className=" w-full">
                        <TextField
                          disabled={bankInfo === "Default"}
                          label="Tipo da Conta"
                          variant="outlined"
                          value={account_type}
                          onChange={(e) => setAccountType(e.target.value)}
                          error={errorFields?.account_type}
                          helperText={errorFields?.account_type && errorMessage}
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-3 justify-center mt-4">
                <div className="flex justify-end mb-3">
                  <Button
                    variant="outlined"
                    color="success"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={addFee}
                  >
                    Adicionar Taxa
                  </Button>
                </div>
                <div>
                  {fees.map((fee) => (
                    <div key={fee.id}>
                      <FeeComponent
                        cashInFee={fee.cash_in_fee}
                        cashOutFee={fee.cash_out_fee}
                        cashInType={fee.cash_in_fee_type}
                        cashOutType={fee.cash_out_fee_type}
                        handleCashInChange={(value) =>
                          handleCashInChange(fee.id, value)
                        }
                        handleCashOutChange={(value) =>
                          handleCashOutChange(fee.id, value)
                        }
                        handleCashInTypeChange={(type) =>
                          handleCashInTypeChange(fee.id, type)
                        }
                        handleCashOutTypeChange={(type) =>
                          handleCashOutTypeChange(fee.id, type)
                        }
                      />
                      {fee.id !== 1 && (
                        <div className="flex justify-start mb-3">
                          <Button
                            variant="outlined"
                            color="error"
                            startIcon={<RemoveCircleOutlineIcon />}
                            onClick={() => removeFee(fee.id)}
                          >
                            Remover Taxa
                          </Button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="mx-auto mt-10">
                <Button
                  variant="contained"
                  color="success"
                  size="large"
                  onClick={handleClick}
                >
                  Salvar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
